<template>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-calendar-blank me-2"></i>Years of Service Report
        </legend>
        <FormInput
            type="number"
            v-model="minimumYears"
            min="1"
            label="Minimum Years"
            id-prefix="minimum-years"
            class="mb-3"
            input-class="form-control-sm"
        />
        <div class="text-end">
            <button type="button"
                class="btn btn-primary"
                :disabled="!minimumYears"
                @click="generateReport">
                <i class="icon ph-bold ph-gear me-2"></i>Generate
            </button>
        </div>
    </fieldset>
</template>

<script setup>
import { ref } from 'vue';
import FormInput from '@/components/utils/FormInput.vue';

const minimumYears = ref(null);

function generateReport() {
    window.open(route('api.employees.accounting-years-of-service-report.show', {
        minimum_years: minimumYears.value
    }));
}
</script>
