<template>
    <Modal :id="id">
        <template #header>
            <h5 class="modal-title">
                <i class="icon ph-bold ph-printer me-2"></i>{{ title }}
            </h5>
        </template>
        <FormSelect v-model="selectedOption"
            label="Format"
            class="mb-5"
            :options="options"
        />
        <template #footer>
            <button type="button"
                class="btn btn-primary"
                @click="printButtonOnClick">
                <i class="icon ph-bold ph-printer me-2"></i>Print
            </button>
        </template>
    </Modal>
</template>

<script setup>
import { ref } from "vue";
import Modal from "./Modal.vue";
import FormSelect from "./FormSelect.vue";

const options = ["PDF", "Spreadsheet"];

const props = defineProps({
    pdfLink: String,
    spreadsheetLink: String,
    title: String,
    id: String,
});

const selectedOption = ref(options[0]);

function printButtonOnClick() {
    if (selectedOption.value == options[0]) {
        window.open(props.pdfLink, "_blank");
    } else if (selectedOption.value == options[1]) {
        window.open(props.spreadsheetLink, "_blank");
    }
}
</script>
