<template>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-barcode me-2"></i> SKUs to Pullout
        </legend>
        <DataTable :value="skusToPullout"
            paginator
            :rows="10"
            :pt="{ table: { class: ' table table-bordered table-hover' } }">
            <Column field="style" header="Style" />
            <Column field="color" header="Color" />
            <Column field="sku" header="SKU" />
            <Column field="quantity" header="Qty." />
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No styles.
                </div>
            </template>
        </DataTable>
        <hr class="my-4" />
        <button type="button"
            class="btn btn-outline-danger"
            @click="emit('clear')">
            <i class="icon ph-bold ph-x me-2"></i>Clear
        </button>
    </fieldset>
</template>

<script setup>
import Column from "primevue/column";
import DataTable from "primevue/datatable";

const props = defineProps({
    skusToPullout: Array,
    class: String,
});

const emit = defineEmits(['clear']);

const skusToPullout = defineModel();
</script>
