<template>
    <div class="row g-3">
        <div class="col-xl-8">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-truck me-2"></i>Reports
                </legend>
                <div class="row">
                    <FormSelect
                        label="Start Date:"
                        v-model="startDate"
                        :options="timePeriods"
                        :option-to-string="(timePeriod) => timePeriod.START_DATE"
                        :option-value="(timePeriod) => timePeriod.START_DATE"
                        class="col-md-6 mb-3"
                        select-class="form-select-sm"
                        id-prefix="startDate"
                    />
                    <FormSelect
                        label="End Date:"
                        v-model="endDate"
                        :options="timePeriods"
                        :option-to-string="(timePeriod) => timePeriod.END_DATE"
                        :option-value="(timePeriod) => timePeriod.END_DATE"
                        class="col-md-6 mb-3"
                        select-class="form-select-sm"
                        id-prefix="endDate"
                    />
                    <div class="col-12 mb-3 d-flex align-items-center">
                        <input type="checkbox"
                            id="enableReceipt"
                            v-model="withReceipt"
                        />
                        <label for="enableReceipt" class="ms-1">Enable receipt no.</label>
                    </div>
                    <div class="col-lg-6">
                        <FormInput
                            label="Start Receipt No:"
                            v-model="startReceiptNumber"
                            :disabled="!withReceipt"
                            class="mb-md-1 mb-2"
                            input-class="form-control-sm"
                            id-prefix="endDate"
                            is-horizontal
                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-md-7 col-12"
                        />
                    </div>
                    <div class="col-lg-6">
                        <FormInput
                            label="End Receipt No:"
                            v-model="endReceiptNumber"
                            :disabled="!withReceipt"
                            class="mb-md-1 mb-2"
                            input-class="form-control-sm"
                            id-prefix="endDate"
                            is-horizontal
                            label-class="col-xl-4 col-md-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-md-7 col-12"
                        />
                    </div>
                    <div class="text-end mt-3">
                        <button class="btn btn-primary ms-3 mb-1"
                            :disabled="!startDate || !endDate"
                            @click="generateDRSpreadsheet('sold')">
                            <i class="icon ph-bold ph-shopping-cart me-2"></i>Sold Report
                        </button>
                        <button class="btn btn-primary ms-3 mb-1"
                            :disabled="!startDate || !endDate"
                            @click="generateDRSpreadsheet('gift')">
                            <i class="icon ph-bold ph-gift me-2"></i>Gift Report
                        </button>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import { useTimePeriods } from "@/composables/data/timePeriods";
import FormInput from "@/components/utils/FormInput.vue";

const startDate = ref(null);
const endDate = ref(null);
const withReceipt = ref(false);
const startReceiptNumber = ref(null);
const endReceiptNumber = ref(null);

const { timePeriods, getTimePeriodsLatest } = useTimePeriods();

watch(withReceipt, () => {
    if (!withReceipt.value) {
        startReceiptNumber.value = null;
        endReceiptNumber.value = null;
    }
});

onMounted(async () => {
    await getTimePeriodsLatest({
        PAYTYPE: "MONTHLY",
    });
});

function generateDRSpreadsheet(type) {
    window.open(
        route(`api.inventory.monthly-${type}-dr-report-spreadsheet.show`, {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            START_RECEIPT_NO: startReceiptNumber.value,
            END_RECEIPT_NO: endReceiptNumber.value,
        })
    );
}
</script>
