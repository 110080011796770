<template>
    <div class="row g-3 my-3">
        <div class="col-lg-6">
            <AddStyleDetailsCard @add="addSkuToPullout" />
            <SKUsToPulloutCard v-model="skusToPullout" />
        </div>
        <div class="col-lg-6">
            <ExportStylesToPulloutCard
                @export="exportPulloutLetter"
                :disable-export="skusToPullout.length == 0"
            />
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";

import AddStyleDetailsCard from './AddStyleDetailsCard.vue';
import SKUsToPulloutCard from './SKUsToPulloutCard.vue';
import ExportStylesToPulloutCard from './ExportStylesToPulloutCard.vue';

const skusToPullout = ref([]);

function addSkuToPullout(skuToPullout) {
    const existingSameStyleColor = skusToPullout.value.filter(toPullout => toPullout.sku == skuToPullout.sku)[0] ?? null;

    if (existingSameStyleColor) {
        existingSameStyleColor.quantity += skuToPullout.quantity;
    } else {
        skusToPullout.value.push(skuToPullout);
    }
}

function exportPulloutLetter(details) {
    window.open(
        route("api.sm-pullout-letter.show", {
            ...details,
            skus: skusToPullout.value.map((skuToPullout) => ({
                sku: skuToPullout.sku,
                quantity: skuToPullout.quantity,
                box: skuToPullout.box,
            })),
        }),
        "_blank"
    );
}
</script>
