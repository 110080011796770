<template>
    <fieldset class="h-100 d-flex flex-column justify-content-between">
        <legend>
            <i class="icon ph-bold ph-x-circle me-2"></i>
            No Barcode Items
        </legend>
        <DataTable
            :value="inventoryNoBarcodes"
            v-model:selection="selectedNoBarcodeItem"
            selection-mode="single"
            data-key="id"
            :pt="{ table: { class: 'table table-bordered table-hover' } }"
            :row-class="
                (data) => ({
                    'bg-primary text-white' : data.id == selectedNoBarcodeItem?.id,
                })">
            <Column field="COMMENT_ID" header="Comment" />
            <Column field="STYLE" header="Style" />
            <Column field="ROWNUM" header="Rownum" />
            <Column field="PREV_LOC" header="Prev. Loc." />
            <Column field="PRICE" header="Price" />
            <Column field="SOLD_DT" header="Sold Date" />
            <Column field="COLOR" header="Color" />
            <Column field="SALE_TYPE" header="Sale Type" />
            <Column field="RECEIPT_NO" header="Receipt" />
            <Column field="DISCOUNT" header="Discount" />
            <template #empty>
                <div class="text-center">No items w/o barcodes.</div>
            </template>
        </DataTable>
        <div>
            <hr class="my-4" />
            <div class="text-end">
                <button type="button"
                    class="btn btn-primary me-2"
                    :disabled="!previousLocation || !comment"
                    @click="
                        (_) => {
                            getInventoryNoBarcodes({
                                no_paginate: true,
                                filters: {
                                    COMMENT_ID: { value: props.comment.COMMENT_ID, matchMode: 'equals' },
                                    PREV_LOC: { value: props.previousLocation.LOCATION, matchMode: 'equals' },
                                },
                            });
                        }">
                    <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Refresh
                </button>
                <button type="button"
                    class="btn btn-outline-danger"
                    :disabled="!selectedNoBarcodeItem"
                    @click="deleteSelectedNoBarcodeItem">
                    <i class="icon ph-bold ph-trash me-2"></i>Delete Selected
                </button>
            </div>
        </div>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";
import { useInventoryNoBarcodes } from "@/composables/data/inventoryNoBarcodes";
import { useFlashMessages } from "@/composables/flashmessages";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import scrollToTop from "@/utils/scrollToTop";

const { inventoryNoBarcodes, getInventoryNoBarcodes } =
    useInventoryNoBarcodes();

const props = defineProps({
    previousLocation: Object,
    comment: Object,
});

const { addFlashMessage } = useFlashMessages();

const selectedNoBarcodeItem = ref(null); // For deleting

watch(
    () => [props.previousLocation, props.comment],
    () => {
        inventoryNoBarcodes.value = null;
    }
);

async function deleteSelectedNoBarcodeItem() {
    try {
        await axios.delete(
            route("api.inventory-no-barcodes.destroy", {
                inventory_no_barcode: selectedNoBarcodeItem.value.id,
            })
        );
        await getInventoryNoBarcodes({
            no_paginate: true,
            filters: {
                COMMENT_ID: {
                    value: props.comment.COMMENT_ID,
                    matchMode: "equals",
                },
                PREV_LOC: {
                    value: props.previousLocation.LOCATION,
                    matchMode: "equals",
                },
            },
        });
        addFlashMessage("SUCCESS", "Successfully removed no barcode item.");
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
    scrollToTop();
}
</script>
