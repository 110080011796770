<template>
    <fieldset :class="class">
        <legend>
            <i class="icon ph-bold ph-barcode me-2"></i>Style SM
        </legend>
        <form>
            <FormInput
                type="text"
                v-model="form.SM_SKU"
                label="Generic SKU"
                id-prefix="genericSMSku"
                is-horizontal
                class="mb-2"
                input-class="form-control-sm"
                :errors="errors.SM_SKU"
                @keydown-enter="focusnext('saveButton')"
            />
            <div class="text-end">
                <hr class="my-3" />
                <button type="button"
                    class="btn btn-primary me-1"
                    id="saveButton"
                    :disabled="!style"
                    @click="saveSmGenericSku">
                    <i class="icon ph-bold ph-floppy-disk me-2"></i>Save
                </button>
            </div>
        </form>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useStyles } from "@/composables/data/styles";
import { useSMGenericSkus } from "@/composables/data/smGenericSkus";
import FormInput from "@/components/utils/FormInput.vue";
import focusnext from "@/utils/focusnext";

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const props = defineProps({
    style: String,
    class: String,
});

const { putSMGenericSku } = useSMGenericSkus();

const { style, getStyle } = useStyles();

const form = ref({
    SM_SKU: null,
});

const errors = ref({});

watch(
    () => props.style,
    async () => {
        errors.value = {};
        form.value = {
            SM_SKU: null,
        };

        if (!props.style) {
            return;
        }

        loadingFlags.add("fetchStyle");
        try {
            await getStyle(props.style);
            form.value = {
                SM_SKU: style.value.SM_SKU,
            };
        } catch (e) {
            toasts.add('ERROR', 'Error', e.message);
        }
        loadingFlags.delete("fetchStyle");
    }
);

async function saveSmGenericSku() {
    errors.value = {};

    loadingFlags.add('saveSmGenericSku');
    try {
        await putSMGenericSku(props.style, form.value);
        toasts.add(
            'SUCCESS',
            'Success',
            `Successfully saved Generic SM SKU ${form.value.SM_SKU}.`
        );
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('saveSmGenericSku');
}
</script>
