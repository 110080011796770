<template>
    <fieldset :class="class">
        <legend class="mb-0">C. INVENTORY_CHECK</legend>
        <small class="text-primary">**(same style, diff. months, no barcode)</small>
        <DataTable
            class="pt-3"
            :loading="isTableLoading"
            lazy
            :value="inventoryChecksSameStyleDiffMonthNoBarcode"
            :total-records="inventoryChecksSameStyleDiffMonthNoBarcodeTotalRecords"
            paginator
            :rows="10"
            @page="(e) => { onPage(e); fetchSameStyleDiffMonthNoBarcode(); }"
            v-model:filters="filters"
            filter-display="row"
            @filter="tableOnFilter"
            sort-mode="multiple"
            @sort="(e) => { onSort(e); fetchSameStyleDiffMonthNoBarcode(); }"
            :pt="{ table: { class: 'table table-sm table-bordered table-hover recon-table' } }">
            <Column
                field="STYLE"
                header="Style"
                sortable
                :pt="{
                    bodyCell: { class: 'barcode' },
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="INVENTORY_DONE"
                header="Inventory Done"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="COLOR"
                header="Color"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data.
                </div>
            </template>
        </DataTable>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useInventoryChecks } from "@/composables/data/inventoryChecks";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import scrollToTop from "@/utils/scrollToTop";

const props = defineProps({
    location: String,
    style: String,
    refreshFlag: Boolean,
    class: String,
});

const { addFlashMessage } = useFlashMessages();

const { params, filters, onPage, onFilter, onSort } = useDataTableParams([
    "STYLE",
    "INVENTORY_DONE",
    "COLOR",
]);

const {
    inventoryChecksSameStyleDiffMonthNoBarcode,
    inventoryChecksSameStyleDiffMonthNoBarcodeTotalRecords,
    getInventoryChecksSameStyleDiffMonthNoBarcode,
} = useInventoryChecks();

const isTableLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchSameStyleDiffMonthNoBarcode() {
    inventoryChecksSameStyleDiffMonthNoBarcode.value = null;

    if (!props.location || !props.style) {
        return;
    }

    isTableLoading.value = true;
    try {
        await getInventoryChecksSameStyleDiffMonthNoBarcode(
            props.location,
            props.style,
            params.value
        );
    } catch (e) {
        addFlashMessage("ERROR", e.message);
        scrollToTop();
    }
    isTableLoading.value = false;
}

async function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchSameStyleDiffMonthNoBarcode, 300);
}

watch(() => props.style, fetchSameStyleDiffMonthNoBarcode);

watch(() => props.refreshFlag, fetchSameStyleDiffMonthNoBarcode);
</script>
