<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-hand-pointing me-2"></i>
                    Select Account
                </h4>
                <button
                    type="button"
                    class="btn-close"
                    @click="close()">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <h5 class="fw-bold mb-3">
                        <i class="icon ph-bold ph-magnifying-glass me-2"></i>SEARCH
                    </h5>
                    <div class="row px-lg-4">
                        <div class="col-md-9 mb-md-0 mb-3">
                            <div class="row">
                                <div class="col-md-6 mb-1">
                                    <FormInput
                                        label="Name:"
                                        v-model="accountName"
                                        type="text"
                                        class="align-items-center"
                                        input-class="form-control"
                                        id-prefix="acctName"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <FormSelect
                                        label="Type:"
                                        v-model="accountType"
                                        id-prefix="acctType"
                                        class="align-items-center"
                                        :options="accountTypes"
                                        :option-to-string="
                                            (accountType) => accountType.ACCOUNT_TYPE
                                        "
                                        :option-value="
                                            (accountType) => accountType.ACCOUNT_TYPE
                                        "
                                        select-class="form-select"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 d-flex mt-auto mb-1 align-items-center">
                            <button type="button"
                                class="btn btn-primary w-100 h-100"
                                @click="searchAccount">
                                <i class="icon ph-bold ph-magnifying-glass me-2"></i>
                                SEARCH
                            </button>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="px-lg-5 modal-padding-container">
                    <div class="table-container">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>ACCOUNT NAME</th>
                                    <th>ACCOUNT DESCRIPTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    class="cursor-pointer"
                                    v-for="account in accounts"
                                    :key="account.ACCOUNT_ID"
                                    @click="selectAccount(account)"
                                    :class="{
                                        'bg-primary text-white':
                                            account.ACCOUNT_ID ===
                                            selectedRow.ACCOUNT_ID,
                                    }">
                                    <td>{{ account.ACCOUNT_ID }}</td>
                                    <td>{{ account.ACCOUNT_NAME }}</td>
                                    <td>{{ account.ACCOUNT_DESCRIPTION }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-padding-container pb-4">
                    <button type="button"
                        class="btn btn-primary mx-lg-4 mt-3"
                        @click="handleSubmit"
                        :disabled="!selectedRow">
                        <i class="icon ph-bold ph-hand-pointing me-2"></i>SELECT ACCOUNT
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import axios from "axios";

import FormSelect from "./FormSelect.vue";
import FormInput from "./FormInput.vue";
import Card from "./Card.vue";

export default {
    components: {
        FormSelect,
        FormInput,
        Card,
    },
    props: ["isOpen", "onClose", "onSelectAccount", "voucherType"],
    setup(props) {
        const accounts = ref([]);
        const accountName = ref("");
        const accountType = ref("");
        const selectedRow = ref("");
        const accountTypes = ref([]);

        onMounted(async () => {
            getAccountTypes();
        });

        async function getAccountTypes() {
            try {
                const response = await axios.get(
                    route("api.account-types.index")
                );
                accountTypes.value = response.data.data;
            } catch (e) {
                console.log(e);
            }
        }

        async function searchAccount() {
            try {
                const result = await axios.get(route("api.accounts.index"), {
                    params: {
                        name: accountName.value.trim(),
                    },
                });
                accounts.value = result.data.data;
            } catch (e) {
                console.log(e);
            }
        }

        watch([accountType], async () => {
            try {
                const result = await axios.get(route("api.accounts.index"), {
                    params: {
                        accountType: accountType.value,
                    },
                });
                accounts.value = result.data.data;
            } catch (e) {
                console.log(e);
            }
        });

        function selectAccount(row) {
            selectedRow.value = row;
        }

        function handleSubmit() {
            props.onSelectAccount(selectedRow.value, props.voucherType);
            close();
        }

        function close() {
            props.onClose();
        }

        return {
            accountName,
            accountType,
            accountTypes,
            accounts,
            searchAccount,
            selectAccount,
            handleSubmit,
            selectedRow,
            close,
        };
    },
};
</script>
