<template>
    <div class="row my-3">
        <div class="col-xl-8 mb-xl-0 mb-3">
            <fieldset class="h-100">
                <legend>
                    <i class="icon ph-bold ph-tea-bag me-2"></i>Styles Table
                </legend>
                <div class="row">
                    <div class="col-md-6">
                        <FormSelect
                            label="Style Type"
                            v-model="filters.STYLE_TYPE.value"
                            :options="styleTypes"
                            :option-value="(styleType) => styleType.STYLE_TYPE"
                            :option-to-string="(styleType) => styleType.STYLE_TYPE"
                            is-horizontal
                            id-prefix="styleTypeMaster"
                            class="mb-3"
                            @change="onFilter()"
                            label-class="col-xl-4 col-md-5"
                            select-container-class="col-xl-8 col-md-7 col-12"
                        />
                    </div>
                </div>
                <DataTable
                    lazy
                    :loading="loadingFlags.has('fetchStyles')"
                    :value="styles"
                    paginator
                    :rows="10"
                    :total-records="totalRecords"
                    @page="(e) => { onPage(e); fetchStyles(); }"
                    filter-display="row"
                    v-model:filters="filters"
                    @filter="tableOnFilter"
                    selection-mode="single"
                    v-model:selection="selectedStyle"
                    data-key="STYLE"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }">
                    <Column
                        field="STYLE"
                        header="Style"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="STYLE_TYPE"
                        header="Style Type"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="CATEGORY"
                        header="Category"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <Column
                        field="supplier.ACCOUNT_NAME"
                        header="Supplier"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <template #footer>
                        <div class="mb-3">
                            Total Records: <b>{{ totalRecords }}</b> styles.
                        </div>
                    </template>
                </DataTable>
            </fieldset>
        </div>
        <div class="col-xl-4 d-flex flex-column">
            <StyleFormCard
                v-model="selectedStyle"
                :style-types="styleTypes"
                @success="fetchStyles"
                class="mb-3"
            />
            <ImageUploadCard
                :style="selectedStyle?.STYLE != null ? selectedStyle : null"
                class="flex-grow-1"
            />
        </div>
    </div>
    <div class="row g-3">
        <div class="col-xl-4">
            <SMGenericSkuCard
                :style="selectedStyle?.STYLE"
                class="mb-3"
            />
            <SMSkuCard
                :style="selectedStyle?.STYLE"
                :colors="colors" />
        </div>
        <div class="col-xl-4">
            <RobinsonsGenericSkuCard
                :style="selectedStyle?.STYLE"
                :colors="colors"
                class="mb-3"
            />
            <RobinsonsSkuCard
                :style="selectedStyle?.STYLE"
                :colors="colors"
            />
        </div>
        <div class="col-xl-4">
            <LandmarkSkuCard
                :style="selectedStyle?.STYLE"
                class="mb-3"
            />
            <KCCSkuCard 
                :style="selectedStyle?.STYLE"
                class="mb-3"
            />
            <MgSkuCard
                :style="selectedStyle?.STYLE"
            />
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useStyles } from "@/composables/data/styles";
import { useColors } from "@/composables/data/colors";
import FormSelect from "@/components/utils/FormSelect.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import ImageUploadCard from "./ImageUploadCard.vue";
import KCCSkuCard from "./KCCSkuCard.vue";
import LandmarkSkuCard from "./LandmarkSkuCard.vue";
import RobinsonsGenericSkuCard from "./RobinsonsGenericSkuCard.vue";
import RobinsonsSkuCard from "./RobinsonsSkuCard.vue";
import SMGenericSkuCard from "./SMGenericSkuCard.vue";
import SMSkuCard from "./SMSkuCard.vue";
import MgSkuCard from "./MgSkuCard.vue";
import StyleFormCard from "./StyleFormCard.vue";
import axios from "axios";
import handleFetchErrors from "@/utils/handleFetchErrors";
import scrollToTop from "@/utils/scrollToTop";

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const styleTypes = ref();
const { colors, getActiveColors } = useColors();

const { params, filters, onPage, onFilter } = useDataTableParams([
    "STYLE",
    "STYLE_TYPE",
    "CATEGORY",
    "supplier.ACCOUNT_NAME"
]);

const { styles, totalRecords, getStyles } = useStyles();

const selectedStyle = ref(null);

const fetchTimeout = ref(null);

onMounted(async () => {
    try {
        const styleTypesResponse = await axios.get(
            route("api.styles.types.index")
        );
        styleTypes.value = styleTypesResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching style types.");
        scrollToTop();
    }
});

onMounted(async () => {
    try {
        await getActiveColors();
        colors.value = colors.value.map((color) => color.COLOR);
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching colors.");
        scrollToTop();
    }

    fetchStyles();
});


async function fetchStyles() {
    styles.value = null;

    loadingFlags.add("fetchStyles");
    try {
        await getStyles(params.value);
    } catch (e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete("fetchStyles");
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchStyles, 300);
}
</script>
