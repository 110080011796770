<template>
    <div class="toast-container position-fixed bottom-0 end-0 p-3">
        <div
            v-for="toastMessage in toasts.messages"
            class="toast show"
            :class="{
                'bg-success text-white': toastMessage.type == 'SUCCESS',
                'bg-danger text-white': toastMessage.type == 'ERROR',
            }"
            role="alert"
            aria-live="assertive"
            aria-atomic="true">
            <div class="toast-header">
                <strong class="me-auto">{{ toastMessage.header }}</strong>
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div class="toast-body" v-html="toastMessage.message" />
        </div>
    </div>
</template>

<script setup>
import { useToastsStore } from '@/stores/toasts';

const toasts = useToastsStore();
</script>

<style scoped>
.toast-container {
    z-index: 11;
}
</style>
