import { ref } from 'vue';

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useCompanyEvents() {
    const companyEvents = ref(null);
    const totalRecords = ref(null);
    const companyEvent = ref(null);

    const getCompanyEvents = async (params) => {
        try {
            const getCompanyEventsResponse = await axios.get(route('api.company-events.index', params));
            companyEvents.value = getCompanyEventsResponse.data.data;
            totalRecords.value = getCompanyEventsResponse.data.meta?.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching events.');
        }
    };

    const getCompanyEvent = async (companyEventId) => {
        try {
            const getCompanyEventResponse = await axios.get(route('api.company-events.show', {
                company_event: companyEventId
            }));
            companyEvent.value = getCompanyEventResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching event.');
        }
    };
    
    const postCompanyEvent = async (companyEvent) => {
        try {
            const postCompanyEventResponse = await axios.post(route('api.company-events.store'), companyEvent);
            return postCompanyEventResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while inserting event.');
        }
    };
    
    const putCompanyEvent = async (companyEventId, companyEvent) => {
        try {
            const putCompanyEventResponse = await axios.put(route('api.company-events.update', {
                company_event: companyEventId,
            }), companyEvent);
            return putCompanyEventResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating event.');
        }
    };

    return {
        companyEvents,
        totalRecords,
        companyEvent,
        getCompanyEvents,
        getCompanyEvent,
        postCompanyEvent,
        putCompanyEvent,
    };
}
