<template>
    
    <DataTable
        :loading="loadingFlags.has('fetchInventoryCheckDescriptions')"
        lazy
        :value="inventoryCheckDescriptions"
        :total-records="totalRecords"
        paginator
        :rows="10"
        @page="e => { onPage(e); fetchInventoryCheckDescriptions(); }"
        filter-display="row"
        v-model:filters="filters"
        @filter="tableOnFilter"
        sort-mode="multiple"
        @sort="e => { onSort(e); fetchInventoryCheckDescriptions(); }"
        selection-mode="single"
        v-model:selection="selectedInventoryCheckDescription"
        :pt="{ table: { class: 'table table-bordered table-hover' }}">
        <Column field="id" header="ID" sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="_ => {
                        filterModel.matchMode = 'contains';
                        filterCallback();
                    }"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column field="LOCATION" header="Location" sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="_ => {
                        filterModel.matchMode = 'contains';
                        filterCallback();
                    }"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column field="INVENTORY_DONE" header="Inventory Done" sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <Column field="DESCRIPTION" header="Description" sortable :pt="{
            filterInput: { class: 'input-group input-group-sm' },
            filterMenuButton: { class: 'd-none' },
            headerFilterClearButton: { class: 'd-none' },
            bodyCell: { class: 'barcode' } }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search" />
            </template>
        </Column>
        <template #empty>
            <div class="text-center">
                No data.
            </div>
        </template>
    </DataTable>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useInventoryCheckDescriptions } from '@/composables/data/inventoryCheckDescriptions';
import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    location: String,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const {
    params,
    filters,
    onPage,
    onFilter,
    onSort
} = useDataTableParams([
    'id',
    'LOCATION',
    'INVENTORY_DONE',
    'DESCRIPTION'
]);

const {
    inventoryCheckDescriptions,
    totalRecords,
    getInventoryCheckDescriptions
} = useInventoryCheckDescriptions();

const fetchTimeout = ref(null);

const selectedInventoryCheckDescription = defineModel();

async function fetchInventoryCheckDescriptions() {
    inventoryCheckDescriptions.value = null;

    loadingFlags.add('fetchInventoryCheckDescriptions');
    try {
        await getInventoryCheckDescriptions({
            ...params.value,
            ...(props.location != null ? {
                hardFilters: {
                    LOCATION: {
                        matchMode: 'equals',
                        value: props.location,
                    },
                },
            } : {}),
        });
    } catch(e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('fetchInventoryCheckDescriptions');
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchInventoryCheckDescriptions, 300);
}

onMounted(fetchInventoryCheckDescriptions);
</script>
