<template>
    <div class="row g-3 my-3">
        <div class="col-xl-9">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-list-checks me-2"></i>
                    Approval List
                </legend>
                <div class="row mb-3">
                    <div class="col-md-4">
                        <FormSelect
                            v-model="filters.STATUS.value"
                            label="Filter by Status"
                            id-prefix="statusFilter"
                            :options="['WAITING', 'APPRV', 'DISAPPRV']"
                            @change="fetchSampleApprovals"
                            class="mb-3"
                        />
                    </div>
                </div>
                <DataTable
                    lazy
                    :total-records="totalRecords"
                    :value="sampleApprovals"
                    paginator
                    :rows="10"
                    v-model:filters="filters"
                    filter-display="row"
                    v-model:selection="selectedSampleApproval"
                    selection-mode="single"
                    data-key="id"
                    @page="
                        (e) => {
                            onPage(e);
                            fetchSampleApprovals();
                        }
                    "
                    @filter="
                        (e) => {
                            onFilter(e);
                            fetchSampleApprovals();
                        }
                    "
                    :row-class="
                        (data) => [{ 'bg-primary text-white': data.id == selectedSampleApproval?.id }]
                    "
                    :pt="{
                        table: { class: 'table table-bordered table-hover' }
                    }">
                    <Column
                        field="SAMPLE_ID"
                        header="Sample ID"
                        :pt="{
                            filterInput: {
                                class: 'input-group input-group-sm',
                            },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input.enter="filterCallback()"
                                class="form-control"
                                placeholder="Search by ID"
                            />
                        </template>
                    </Column>
                    <Column
                        field="CLIENT"
                        header="Client"
                        :pt="{
                            filterInput: {
                                class: 'input-group input-group-sm',
                            },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input.enter="filterCallback()"
                                class="form-control"
                                placeholder="Search by Client"
                            />
                        </template>
                    </Column>
                    <Column
                        field="QTY"
                        header="Qty"
                        :pt="{
                            filterInput: {
                                class: 'input-group input-group-sm',
                            },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText type="text" v-model="filterModel.value"
                            @input.leftfilterCallback()" class="form-control"
                            placeholder="Search by Qty" />
                        </template>
                    </Column>
                    <Column
                        field="UNIT_PRICE"
                        header="Unit Price"
                        :pt="{
                            filterInput: {
                                class: 'input-group input-group-sm',
                            },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input.enter="filterCallback()"
                                class="form-control"
                                placeholder="Search by Unit Price"
                            />
                        </template>
                    </Column>
                    <Column
                        field="DATE"
                        header="Date"
                        :pt="{
                            filterInput: {
                                class: 'input-group input-group-sm',
                            },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input.enter="filterCallback()"
                                class="form-control"
                                placeholder="Search by Date"
                            />
                        </template>
                    </Column>
                    <Column
                        field="CREATED_BY"
                        header="Created By"
                        :pt="{
                            filterInput: {
                                class: 'input-group input-group-sm',
                            },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input.enter="filterCallback()"
                                class="form-control"
                                placeholder="Search by Created By"
                            />
                        </template>
                    </Column>
                    <Column
                        field="STATUS"
                        header="Status"
                        :pt="{
                            filterInput: {
                                class: 'input-group input-group-sm',
                            },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input.enter="filterCallback()"
                                class="form-control"
                                placeholder="Search by Status"
                            />
                        </template>
                    </Column>
                    <Column
                        field="EVALUATED_BY"
                        header="Evaluated By"
                        :pt="{
                            filterInput: {
                                class: 'input-group input-group-sm',
                            },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input.enter="filterCallback()"
                                class="form-control"
                                placeholder="Search by Evaluated By"
                            />
                        </template>
                    </Column>
                    <Column
                        field="DATE_EVALUATED"
                        header="Date Evaluated"
                        :pt="{
                            filterInput: {
                                class: 'input-group input-group-sm',
                            },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input.enter="filterCallback()"
                                class="form-control"
                                placeholder="Search by Date Evaluated"
                            />
                        </template>
                    </Column>
                    <template #empty>
                        <div class="text-center py-3">
                            <i class="icon ph-bold ph-database me-2"></i>No approvals.
                        </div>
                    </template>
                </DataTable>
                <div class="text-end">
                    <hr class="my-4" />
                    <button type="button"
                        class="btn btn-success me-2"
                        :disabled="
                            !selectedSampleApproval ||
                            selectedSampleApproval.STATUS != 'WAITING'
                        "
                        @click="approveSelected">
                        <i class="icon ph-bold ph-check me-2"></i>Approve
                    </button>
                    <button type="button"
                        class="btn btn-danger"
                        :disabled="
                            !selectedSampleApproval ||
                            selectedSampleApproval.STATUS != 'WAITING'
                        "
                        @click="disapproveSelected">
                        <i class="icon ph-bold ph-x me-2"></i>Disapprove
                    </button>
                </div>
            </fieldset>
        </div>
        <div class="col-xl-3">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-vector-two me-2"></i>Selected
                    Sample Dimensions
                </legend>
                <FormInput
                    type="text"
                    v-model="sampleDimensions.length"
                    label="Length"
                    id-prefix="lengthDisplay"
                    disabled
                    class="mb-md-1 mb-2 align-items-center"
                    is-horizontal
                    label-class="col-xl-4 col-lg-3 col-md-4"
                    input-container-class="col col-xl-8 col-lg-9 col-md-8 col-12"
                />
                <FormInput
                    type="text"
                    v-model="sampleDimensions.width"
                    label="Width"
                    id-prefix="widthDisplay"
                    disabled
                    class="mb-md-1 mb-2 align-items-center"
                    is-horizontal
                    label-class="col-xl-4 col-lg-3 col-md-4"
                    input-container-class="col col-xl-8 col-lg-9 col-md-8 col-12"
                />
                <FormInput
                    type="text"
                    v-model="sampleDimensions.height"
                    label="Height"
                    id-prefix="heightDisplay"
                    disabled
                    class="mb-md-1 mb-2 align-items-center"
                    is-horizontal
                    label-class="col-xl-4 col-lg-3 col-md-4"
                    input-container-class="col col-xl-8 col-lg-9 col-md-8 col-12"
                />
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useSamples } from "@/composables/data/samples";
import { useSampleApprovals } from "@/composables/data/sampleApprovals";
import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const { addFlashMessage } = useFlashMessages();

const { sample, getSample } = useSamples();
const {
    sampleApprovals,
    totalRecords,
    getSampleApprovals,
    postApproval,
    postDisapproval,
} = useSampleApprovals();

const sampleDimensions = ref({
    length: null,
    width: null,
    height: null,
});

const { params, filters, onPage, onFilter } = useDataTableParams([
    "SAMPLE_ID",
    "CLIENT",
    "QTY",
    "UNIT_PRICE",
    "DATE",
    "CREATED_BY",
    "STATUS",
    "EVALUATED_BY",
    "DATE_EVALUATED",
]);

const selectedSampleApproval = ref(null);

async function fetchSampleApprovals() {
    try {
        await getSampleApprovals(params.value);
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
}

onMounted(() => {
    const sampleId = Number(
        new URLSearchParams(window.location.search).get("SAMPLE_ID")
    );
    if (sampleId) {
        filters.value.SAMPLE_ID.matchMode = "equals";
        filters.value.SAMPLE_ID.value = sampleId;
    }
});

onMounted(fetchSampleApprovals);

watch(selectedSampleApproval, async () => {
    sampleDimensions.value = {
        length: null,
        width: null,
        height: null,
    };

    if (!selectedSampleApproval.value) return;

    try {
        await getSample(selectedSampleApproval.value.SAMPLE_ID);
        sampleDimensions.value.length = sample.value.LENGTH;
        sampleDimensions.value.width = sample.value.WIDTH;
        sampleDimensions.value.height = sample.value.HEIGHT;
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
});

async function approveSelected() {
    if (!confirm("Are you sure to approve the selected item?")) return;

    try {
        const approvalResponse = await postApproval(
            selectedSampleApproval.value.id
        );
        selectedSampleApproval.value = approvalResponse.data.data;
        addFlashMessage("SUCCESS", "Successfully approved.");
        fetchSampleApprovals();
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
}

async function disapproveSelected() {
    if (!confirm("Are you sure to disapprove the selected item?")) return;

    try {
        const disapprovalResponse = await postDisapproval(
            selectedSampleApproval.value.id
        );
        selectedSampleApproval.value = disapprovalResponse.data.data;
        addFlashMessage("SUCCESS", "Successfully disapproved.");
        fetchSampleApprovals();
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
}
</script>
