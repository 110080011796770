<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Create Leave</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <FormSelect v-model="form.TYPE"
                :errors="errors.TYPE"
                :options="LEAVE_TYPES"
                label="Leave Type"
                id-prefix="type"
                class="mb-3" />
            <FormInput type="date"
                v-model="form.START_DATE"
                :errors="errors.START_DATE"
                label="Start Date"
                id-prefix="startDate"
                class="mb-3" />
            <FormInput type="date"
                v-model="form.END_DATE"
                :errors="errors.END_DATE"
                label="End Date"
                id-prefix="endDate" />
            <template #footer>
                <div class="d-grid">
                    <button type="button" class="btn btn-primary"
                        @click="insertLeaveHistory">
                        Create
                    </button>
                </div>
            </template>
        </Card>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useLeaveHistories } from '@/composables/data/leaveHistories';

import Card from '@/components/utils/Card.vue';

import FormInput from '@/components/utils/FormInput.vue';
import FormSelect from '@/components/utils/FormSelect.vue';

const props = defineProps({
    isOpen: Boolean,
    employeeId: Number,
});

const emit = defineEmits(['done', 'close']);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const LEAVE_TYPES = ['MATERNITY', 'SICK', 'VACATION'];

const { postEmployeeLeaveHistory } = useLeaveHistories();

const form = ref({
    TYPE: null,
    START_DATE: null,
    END_DATE: null,
});

const errors = ref({});

async function insertLeaveHistory() {
    loadingFlags.add('insertLeaveHistory');
    try {
        await postEmployeeLeaveHistory(props.employeeId, form.value);
        toasts.add('SUCCESS', 'Success', 'Successfully created leave history.');
        emit('done');
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('insertLeaveHistory');
}
</script>
