<template>
    <fieldset :class="class">
        <legend class="mb-0">3. RECON_RAW_FULL</legend>
        <small class="text-primary">**(in store, not in INV_CHECK, investigate)</small>
        <DataTable
            class="pt-3"
            :loading="isTableLoading"
            lazy
            :value="reconRawFullsInStoreNotInInvCheck"
            :total-records="reconRawFullsInStoreNotInInvCheckTotalRecords"
            paginator
            :rows="10"
            @page="(e) => { onPage(e); fetchInStoreNotInInvCheck(); }"
            v-model:filters="filters"
            filter-display="row"
            @filter="tableOnFilter"
            sort-mode="multiple"
            @sort="(e) => { onSort(e); fetchInStoreNotInInvCheck(); }"
            v-model:selection="selectedRecord"
            selection-mode="single"
            :pt="{ table: { class: 'table table-sm table-bordered table-hover recon-table' } }">
            <Column
                field="STYLE"
                header="Style"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="DATE"
                header="Date"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="SERIAL"
                header="Serial"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="COLOR"
                header="Color"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="SEQ_NO"
                header="Seq"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="EVENT_FLAG"
                header="EVNT"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="ADD_FLAG"
                header="ADDF"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="TYPE_FLAG"
                header="TYPE"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data.
                </div>
            </template>
        </DataTable>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useReconRawFulls } from "@/composables/data/reconRawFulls";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import scrollToTop from "@/utils/scrollToTop";

const props = defineProps({
    location: String,
    timePeriod: Number,
    inventoryDone: String,
    style: String,
    refreshFlag: Boolean,
    class: String,
});

const { addFlashMessage } = useFlashMessages();

const { params, filters, onPage, onFilter, onSort } = useDataTableParams([
    "STYLE",
    "DATE",
    "SERIAL",
    "COLOR",
    "SEQ_NO",
    "EVENT_FLAG",
    "ADD_FLAG",
    "TYPE_FLAG",
]);

const {
    reconRawFullsInStoreNotInInvCheck,
    reconRawFullsInStoreNotInInvCheckTotalRecords,
    getReconRawFullsInStoreNotInInvCheck,
} = useReconRawFulls();

const isTableLoading = ref(false);

const fetchTimeout = ref(null);

const selectedRecord = defineModel();
const selectedBagIdentifier = defineModel("selectedBagIdentifier");

async function fetchInStoreNotInInvCheck() {
    reconRawFullsInStoreNotInInvCheck.value = null;

    if (
        !props.location ||
        !props.timePeriod ||
        !props.inventoryDone ||
        !props.style
    ) {
        return;
    }

    isTableLoading.value = true;
    try {
        await getReconRawFullsInStoreNotInInvCheck(
            props.timePeriod,
            props.location,
            props.inventoryDone,
            props.style,
            params.value
        );
    } catch (e) {
        addFlashMessage("ERROR", e.message);
        scrollToTop();
    }
    isTableLoading.value = false;
}

async function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchInStoreNotInInvCheck, 300);
}

watch(selectedRecord, () => {
    if (!selectedRecord.value) {
        return;
    }

    selectedBagIdentifier.value = {
        STYLE: selectedRecord.value.STYLE,
        DATE: selectedRecord.value.DATE,
        SERIAL: selectedRecord.value.SERIAL,
        SEQ_NO: selectedRecord.value.SEQ_NO,
        NOBC_SOURCE: "LOCATION",
    };
});

watch(() => props.style, fetchInStoreNotInInvCheck);

watch(() => props.refreshFlag, fetchInStoreNotInInvCheck);
</script>
