<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="ph ph-bell me-2"></i>{{ personalNote == null ? "Create" : "Update" }} Personal Note
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')" />
            </div>
            <hr />
            <div class="modal-container px-4">
                <FormInput type="text"
                    v-model="form.name"
                    :errors="errors.name"
                    label="Name"
                    id-prefix="name"
                    class="mb-3" />
                <FormInput type="date"
                    v-model="form.note_date"
                    :errors="errors.note_date"
                    label="Note Date"
                    id-prefix="noteDate"
                    class="mb-3"
                    disabled />
                <FormTextarea
                    v-model="form.description"
                    :errors="errors.description"
                    label="Description"
                    id-prefix="description"
                    class="mb-3" />
                <FormSelect
                    v-model="form.recurrence_type"
                    :errors="errors.recurrence_type"
                    :options="RECURRENCE_TYPE_OPTIONS"
                    :option-to-string="option => option.label"
                    :option-value="option => option.value"
                    label="Recurring by"
                    id-prefix="recurrence_type" />
                <div class="modal-padding-container text-end">
                    <button type="button" class="btn btn-primary mt-3"
                        v-if="!personalNote"
                        @click="createPersonalNote">
                        <i class="icon ph-bold ph-file-plus me-2"></i>Create Note
                    </button>
                    <template v-else>
                        <button type="button" class="btn btn-danger mt-3 me-2"
                            @click="destroyPersonalNote">
                            <i class="icon ph-bold ph-trash me-2"></i>Delete Note
                        </button>
                        <button type="button" class="btn btn-primary mt-3"
                            @click="updatePersonalNote">
                            <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Update Note
                        </button>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { usePersonalNotes } from '@/composables/data/personalNotes';

import FormInput from '../FormInput.vue';
import FormSelect from '../FormSelect.vue';
import FormTextarea from '../FormTextarea.vue';

const props = defineProps({
    date: String,
    isOpen: Boolean,
});

const emit = defineEmits(['close']);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { postLoggedInUserPersonalNote, putLoggedInUserPersonalNote, deleteLoggedInUserPersonalNote } = usePersonalNotes();

const RECURRENCE_TYPE_OPTIONS = [
    {
        value: 'YEARLY',
        label: 'Yearly',
    },
    {
        value: 'MONTHLY',
        label: 'Monthly',
    },
];

const personalNote = defineModel();

const form = ref({
    name: null,
    description: null,
    note_date: null,
    recurrence_type: null,
});

const errors = ref({});

watch(personalNote, () => {
    form.value = Object.assign({
        name: null,
        description: null,
        note_date: props.date,
        recurrence_type: null,
    }, personalNote.value);
    errors.value = {};
});

async function createPersonalNote() {
    errors.value = {};

    loadingFlags.add("createPersonalNote");
    try {
        await postLoggedInUserPersonalNote(form.value);
        toasts.add("SUCCESS", "Success", `Successfully created personal note on ${form.value.note_date}.`);
        personalNote.value = null;
        emit('success');
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("createPersonalNote");
}

async function updatePersonalNote() {
    errors.value = {};

    loadingFlags.add("updatePersonalNote");
    try {
        await putLoggedInUserPersonalNote(personalNote.value.id, form.value);
        toasts.add("SUCCESS", "Success", `Successfully updated personal note on ${form.value.note_date}.`);
        personalNote.value = null;
        emit('success');
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("updatePersonalNote");
}

async function destroyPersonalNote() {
    errors.value = {};

    loadingFlags.add("destroyPersonalNote");
    try {
        await deleteLoggedInUserPersonalNote(personalNote.value.id);
        toasts.add("SUCCESS", "Success", `Successfully deleted personal note on ${form.value.note_date}.`);
        personalNote.value = null;
        emit('success');
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("destroyPersonalNote");
}

watch(() => props.date, () => {
    form.value.note_date = props.date;
});
</script>
