<template>
    <div class="row mb-3">
        <div class="col-xl-4 col-lg-6">
            <form @submit.prevent="search">
                <fieldset>
                    <legend>
                        <i class="icon ph-bold ph-arrows-clockwise me-2"></i>Update Inventory Check
                    </legend>
                    <FormInput
                        type="text"
                        v-model="barcodeInput"
                        label="Raw"
                        id-prefix="raw"
                        input-class="mb-2"
                        @keydown-enter="search"
                    />
                    <div class="text-end">
                        <button type="submit" class="btn btn-primary me-2">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Search
                        </button>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
    <BarcodeInventoryChecksTableCard
        :barcode="barcodeToCheck"
        @success="
            (message) => {
                addFlashMessage('SUCCESS', message);
                scrollToTop();
            }
        "
        @error="
            (message) => {
                addFlashMessage('ERROR', message);
                scrollToTop();
            }
        "
    />
</template>

<script setup>
import { ref } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import BarcodeInventoryChecksTableCard from "./BarcodeInventoryChecksTableCard.vue";
import FormInput from "@/components/utils/FormInput.vue";
import scrollToTop from "@/utils/scrollToTop";

const { addFlashMessage } = useFlashMessages();

const barcodeInput = ref(null);

const barcodeToCheck = ref(null);

function search() {
    barcodeToCheck.value = barcodeInput.value;
}
</script>
