<style scoped>
#totalHoursSectionCard {
    height: 350px;
    overflow-y: auto;
}
</style>

<template>
    <Card :class="class" id="totalHoursSectionCard">
        <template #header>
            Total Hours
        </template>
        <FormInput type="number" v-model="totalHours" disabled
            label="Total Hours" id-prefix="totalHours"
            is-horizontal class="mb-3" />
        <DataTable :loading="loadingFlags.has('fetchEmployeeHoursOnTimePeriod')" :value="hoursSummary"
            paginator :rows="10"
            :pt="{ table: { class: 'table table-bordered table-hover table-sm' }}">
            <Column field="rate" header="Rate" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="hours" header="Hours" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No saved hours.
                </div>
            </template>
        </DataTable>
    </Card>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';

import { useEmployeeHours } from '@/composables/data/employeeHours';

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    timePeriod: Object,
    employee: Object,
    refreshFlag: null,
    class: String
});

const emit = defineEmits(['error']);

const { employeeHours, getEmployeeHoursOnTimePeriod } = useEmployeeHours();

const hoursSummary = ref(null);

const loadingFlags = useLoadingFlagsStore();

const totalHours = computed(() => hoursSummary.value?.reduce((total, hoursOnRate) => total + hoursOnRate.hours, 0) ?? 0);

async function fetchEmployeeHoursOnTimePeriod() {
    employeeHours.value = null;
    hoursSummary.value = null;

    if(!props.employee || !props.timePeriod)
        return;

    loadingFlags.add('fetchEmployeeHoursOnTimePeriod');
    try {
        await getEmployeeHoursOnTimePeriod(props.employee.EMPID, props.timePeriod.TIME_PERIOD);
        hoursSummary.value = Object.values(employeeHours.value.reduce((hoursSummary, employeeHoursOnDate) => {
            const newHoursSummary = Object.assign({}, hoursSummary);
            if(employeeHoursOnDate.RATE in newHoursSummary)
                newHoursSummary[employeeHoursOnDate.RATE].hours += employeeHoursOnDate.HOURS;
            else
                newHoursSummary[employeeHoursOnDate.RATE] = {
                    rate: employeeHoursOnDate.RATE,
                    hours: employeeHoursOnDate.HOURS,
                };
            return newHoursSummary;
        }, {}));
    } catch(e) {
        console.log(e);
        emit('error', e.message);
    }
    loadingFlags.delete('fetchEmployeeHoursOnTimePeriod');
}

watch(() => [props.employee, props.timePeriod], fetchEmployeeHoursOnTimePeriod);
watch(() => props.refreshFlag, fetchEmployeeHoursOnTimePeriod);
</script>
