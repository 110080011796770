<template>
    <div class="is-dashboard py-2">
        <div class="row">
            <div class="col-12 mb-3">
                <div class="card qoute-container rounded bg-white p-3">
                    <div class="row">
                        <div class="col-9 py-3 px-5">
                            <p>
                                “ There is no hope. There is only what is. And
                                it is fine. ”
                            </p>
                            <i>
                                – random refrigerator door sticker, near the
                                restroom while it‘s occupied
                            </i>
                        </div>
                        <div class="col-3 text-end">
                            <img src="/img/bagnoimage.jpg" height="150" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <FullCalendar
                    :options="calendarOptions"
                    :fixedWeekCount="false"
                    :button-text="{ month: 'Month' }"
                    class="dashboard-calendar mx-auto"
                />
            </div>
            <div class="col-lg-4 h-100">
                <div class="card bg-white py-4 px-3 h-100">
                    <div class="card-header">
                        <h3 class="text-center mb-0 fw-bold">
                            <i class="icon ph-bold ph-nut me-4"></i>HOLIDAY NOTICE<i class="icon ph-bold ph-nut ms-4"></i>
                        </h3>
                    </div>
                    <div class="card-body p-4">
                        <p>
                            Lorem ipsum dolor sit amet. Et excepturi facere et
                            adipisci rerum est minus voluptas non molestiae
                            molestias qui dolore doloremque quo alias facilis.
                            Eos beatae saepe ut soluta magni et pariatur eaque
                            et cupiditate obcaecati quo dolorem autem vel
                            reprehenderit consequatur. Aut dolor architecto est
                            reiciendis modi est molestias tenetur in esse itaque
                            est quaerat eaque ab maiores assumenda. Et illum
                            quia a placeat molestiae est asperiores suscipit qui
                            molestias galisum ut dignissimos ipsa et laudantium
                            perspiciatis. Non velit labore sit rerum tempore ut
                            iste velit eum voluptatem dolorem in quos quia hic
                            deserunt labore. Ut quibusdam officia est esse
                            libero et accusantium molestias aut pariatur esse
                            est dolorem nihil! Qui quia praesentium sed unde
                            quae eum nihil neque. Ut laborum eveniet est fugiat
                            quod aut minima maiores ad vero quibusdam rem quia
                            perspiciatis. Ut rerum enim ut modi rerum qui odio
                            labore ut repellat repellat.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ViewEventDetailsPopup :is-open="isViewEventDetailsPopupOpen"
        @close="(_) => { isViewEventDetailsPopupOpen = false; }"
        v-model="companyEvent" />

    <CreateOrUpdatePersonalNoteOnDatePopup :is-open="isCreateOrUpdatePersonalNoteOnDatePopupOpen"
        @close="(_) => { isCreateOrUpdatePersonalNoteOnDatePopupOpen = false; }"
        :date="dateBeingViewed"
        v-model="personalNote"
        @success="(_) => {
            fetchCompanyEventsAndPersonalNotes();
            isCreateOrUpdatePersonalNoteOnDatePopupOpen = false;
        }" />
</template>

<script setup>
import { ref } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useCompanyEvents } from "@/composables/data/companyEvents";
import { usePersonalNotes } from "@/composables/data/personalNotes";

import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import rrulePlugin from '@fullcalendar/rrule';
import moment from "moment";

import ViewEventDetailsPopup from "./utils/popups/ViewEventDetailsPopup.vue";
import CreateOrUpdatePersonalNoteOnDatePopup from "./utils/popups/CreateOrUpdatePersonalNoteOnDatePopup.vue";

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { companyEvents, getCompanyEvents } = useCompanyEvents();
const { personalNotes, getLoggedInUserPersonalNotes } = usePersonalNotes();

const calendarOptions = ref({
    plugins: [
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        bootstrap5Plugin,
        rrulePlugin,
    ],
    headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
    },
    themeSystem: "bootstrap5",
    initialView: "dayGridMonth",
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    weekends: true,
    datesSet: calendarDatesSet,
    eventClick: calendarEventClick,
    dateClick: calendarDateClick,
    events: [],
});

const { companyEvent, getCompanyEvent } = useCompanyEvents();
const isViewEventDetailsPopupOpen = ref(false);

const dateBeingViewed = ref(null);
const { personalNote, getLoggedInUserPersonalNote } = usePersonalNotes();
const isCreateOrUpdatePersonalNoteOnDatePopupOpen = ref(false);

const calendarStartDate = ref(null);
const calendarEndDate = ref(null);

async function fetchCompanyEventsAndPersonalNotes() {
    loadingFlags.add("fetchCompanyEventsOnDashboard");
    try {
        await getCompanyEvents({
            start_date: calendarStartDate.value,
            end_date: calendarEndDate.value,
        });

        await getLoggedInUserPersonalNotes({
            start_date: calendarStartDate.value,
            end_date: calendarEndDate.value,
        });

        calendarOptions.value.events = [
            ...companyEvents.value.map(companyEvent => ({
                id: ["EVENT", companyEvent.id, companyEvent.event_date].join("_"),
                title: companyEvent.name,
                start: companyEvent.event_date,
                rrule: companyEvent.is_recurring && !companyEvent.is_duplicate ? {
                    freq: 'yearly',
                    interval: 1,
                    dtstart: moment.utc(companyEvent.event_date).format('YYYY-MM-DD')
                } : null
            })),
            ...personalNotes.value.map(personalNote => ({
                id: ["NOTE", personalNote.id, personalNote.note_date].join("_"),
                title: `(NOTE) ${personalNote.name}`,
                start: personalNote.note_date,
                backgroundColor: '#FF6961',
                borderColor: '#FF6961',
                rrule: personalNote.recurrence_type != null && !personalNote.is_duplicate ? {
                    freq: personalNote.recurrence_type.toLowerCase(),
                    interval: 1,
                    dtstart: moment.utc(personalNote.note_date).format('YYYY-MM-DD')
                } : null,
            })),
        ];
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchCompanyEventsOnDashboard");
}

async function calendarDatesSet(dateInfo) {
    calendarStartDate.value = dateInfo.startStr.split("T", 1)[0];
    calendarEndDate.value = dateInfo.endStr.split("T", 1)[0];
    fetchCompanyEventsAndPersonalNotes();
}

async function calendarEventClick(eventClickInfo) {
    const eventId = eventClickInfo.event.id;

    if (eventId.startsWith("EVENT")) {
        loadingFlags.add("fetchCompanyEvent");
        try {
            await getCompanyEvent(Number(eventId.split("_")[1]));
            isViewEventDetailsPopupOpen.value = true;
        } catch (e) {
            toasts.add("ERROR", "Error", e.message);
        }
        loadingFlags.delete("fetchCompanyEvent");
    } else if (eventId.startsWith("NOTE")) {
        loadingFlags.add("fetchPersonalNote");
        try {
            await getLoggedInUserPersonalNote(Number(eventId.split("_")[1]));
            isCreateOrUpdatePersonalNoteOnDatePopupOpen.value = true;
        } catch (e) {
            toasts.add("ERROR", "Error", e.message);
        }
        loadingFlags.delete("fetchPersonalNote");
    }
}

function calendarDateClick(dateClickInfo) {
    dateBeingViewed.value = dateClickInfo.dateStr;
    personalNote.value = null;
    isCreateOrUpdatePersonalNoteOnDatePopupOpen.value = true;
}
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
.dashboard-calendar {
    max-width: 1200px;
}

:deep(.fc-scrollgrid-sync-table) {
    height: auto !important;
}

:deep(.fc-daygrid-day) {
    height: 98px;
}

:deep(.fc-scroller-liquid-absolute) {
    position: relative;
    height: auto;
}

:deep(.fc .fc-view-harness-active > .fc-view) {
    position: relative;
}

:deep(.fc .fc-view-harness) {
    height: auto !important;
}

:deep(.fc-theme-bootstrap5 td) {
    background-color: #fff;
}

:deep(.fc .fc-scrollgrid-section),
:deep(.fc .fc-scrollgrid-section table),
:deep(.fc .fc-scrollgrid-section > td),
:deep(.fc .fc-daygrid-body) {
    width: 100% !important;
}

:deep(.fc-h-event) {
    background-color: #668cd9;
    border: 1px solid #668cd9;
    display: block;
}

:deep(.fc-h-event .fc-event-title), :deep(.fc-event) {
    font-size: 12px;
    line-height: 1;
    margin-left: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

:deep(td.fc-day-sun) {
    background-color: #fff1f1;
}

:deep(td.fc-day-sat) {
    background-color: #f1fffe;
}

:deep(.fc-h-event .fc-event-title-container) {
    display: flex;
    padding: 3px;
}

:deep(.fc-direction-ltr .fc-daygrid-event .fc-event-time){
    font-size: 13px;
    margin-right: 5px
}

:deep(.fc-direction-ltr .fc-daygrid-event .fc-event-title) {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
