<template>
    <form @submit.prevent="createDetail">
        <fieldset>
            <legend>
                <i class="icon ph-bold ph-note-pencil me-2"></i>
                Create or Update Detail
            </legend>
            <div class="row g-3 mb-md-1 mb-2">
                <div class="col-lg-6">
                    <FormInput
                        type="text"
                        v-model="sampleDetail.MATERIAL"
                        label="Material"
                        id-prefix="material"
                        disabled
                        :errors="errors.MATERIAL"
                        @keydown-enter="focusnext('colorFormInput')"
                    />
                </div>
                <div class="col-lg-6">
                    <FormInput
                        type="text"
                        v-model="sampleDetail.COLOR"
                        label="Color"
                        id-prefix="color"
                        disabled
                        :errors="errors.COLOR"
                        @keydown-enter="focusnext('selectMaterialAndColorButton')"
                    />
                </div>
            </div>
            <div class="text-end mb-3 mt-2">
                <button
                    type="button"
                    class="btn btn-primary"
                    id="selectMaterialAndColorButton"
                    @click="
                        (_) => {
                            isSelectingMaterial = true;
                        }
                    "
                    @keydown.enter.prevent="
                        focusnext('detailTotalQuantityFormInput')
                    ">
                    <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                </button>
            </div>
            <FormInput
                type="text"
                v-model="material.UNIT"
                label="Unit of Measure"
                id-prefix="unitOfMeasure"
                class="mb-md-1 mb-2 align-items-center"
                disabled
                is-horizontal
                label-class="col-md-5"
                input-container-class="col-md-7 col-12"
            />
            <FormInput
                type="number"
                step=".01"
                v-model="sampleDetail.QTY"
                label="Quantity"
                id-prefix="quantity"
                class="mb-md-1 mb-2 align-items-center"
                disabled
                :errors="errors.QTY"
                is-horizontal
                label-class="col-md-5"
                input-container-class="col-md-7 col-12"
            />
            <FormInput
                type="number"
                step=".01"
                v-model="totalQuantity"
                label="Total Quantity"
                id-prefix="detailTotalQuantity"
                class="mb-md-1 mb-2 align-items-center"
                @keyup="totalQuantityOnChange"
                @change="totalQuantityOnChange"
                @keydown-enter="
                    (_) => {
                        if (!sampleDetail.id)
                            focusnext('insertSampleDetailButton');
                        else focusnext('updateSampleDetailButton');
                    }
                "
                is-horizontal
                label-class="col-md-5"
                input-container-class="col-md-7 col-12"
            />
            <FormInput
                type="number"
                step=".01"
                v-model="material.PRICE"
                label="Original Unit Price"
                id-prefix="originalUnitPrice"
                class="mb-md-1 mb-2 align-items-center"
                disabled
                is-horizontal
                label-class="col-md-5"
                input-container-class="col-md-7 col-12"
            />
            <FormInput
                type="number"
                step=".01"
                v-model="totalPrice"
                label="Total Price"
                id-prefix="totalPrice"
                disabled
                class="mb-md-1 mb-2 align-items-center"
                is-horizontal
                label-class="col-md-5"
                input-container-class="col-md-7 col-12"
            />
            <hr class="my-4" />
            <div class="text-end">
                <button type="button"
                    class="btn btn-outline-secondary me-2 px-2 mb-1"
                    @click="reset">
                    <i class="icon ph-bold ph-arrow-counter-clockwise me-2"></i>
                    Reset
                </button>
                <button type="submit"
                    class="btn btn-primary me-2 px-2 mb-1"
                    id="insertSampleDetailButton"
                    :disabled="sampleDetail.id">
                    <i class="icon ph-bold ph-file-plus me-2"></i>
                    Insert
                </button>
                <button type="button"
                    class="btn btn-primary me-2 px-2 mb-1"
                    id="updateSampleDetailButton"
                    :disabled="!sampleDetail.id"
                    @click="updateDetail">
                    <i class="icon ph-bold ph-arrow-clockwise me-2"></i>
                    Update
                </button>
            </div>
        </fieldset>
    </form>

    <MaterialSelectDialog
        :is-open="isSelectingMaterial"
        @select="
            (selectedMaterial) => {
                material = selectedMaterial;
                sampleDetail.MATERIAL = selectedMaterial.MATERIAL;
                sampleDetail.COLOR = selectedMaterial.COLOR;
                isSelectingMaterial = false;
            }
        "
        @close="
            (_) => {
                isSelectingMaterial = false;
            }
        "
    />
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import { useSampleDetails } from "@/composables/data/sampleDetails";
import MaterialSelectDialog from "./MaterialSelectDialog.vue";
import FormInput from "@/components/utils/FormInput.vue";
import focusnext from "@/utils/focusnext";
import scrollToTop from "@/utils/scrollToTop";

const props = defineProps({
    sampleId: Number,
    sampleQuantity: Number,
    selectedSampleDetail: Object,
});

const emit = defineEmits("create-or-update");

const { addFlashMessage } = useFlashMessages();

const { postSampleDetail, putSampleDetail } = useSampleDetails();

const errors = ref({});

const sampleDetail = ref({
    id: null,
    MATERIAL: null,
    COLOR: null,
    QTY: null,
});

const material = ref({
    id: null,
    MATERIAL: null,
    COLOR: null,
    UNIT: null,
    PRICE: null,
});

const totalQuantity = ref(null);
const totalPrice = computed(
    () => (totalQuantity.value ?? 0) * (material.value.PRICE ?? 0)
);

const isSelectingMaterial = ref(false);

watch(
    () => props.sampleQuantity,
    () => {
        totalQuantity.value =
            (sampleDetail.value.QTY ?? 0) * (props.sampleQuantity ?? 0);
    }
);

function totalQuantityOnChange() {
    sampleDetail.value.QTY =
        (totalQuantity.value ?? 0) / (props.sampleQuantity ?? 0);
}

function reset() {
    sampleDetail.value = {
        id: null,
        MATERIAL: null,
        COLOR: null,
        QTY: null,
    };
    material.value = {
        id: null,
        MATERIAL: null,
        COLOR: null,
        UNIT: null,
        PRICE: null,
    };
    totalQuantity.value = null;
    errors.value = {};
}

async function createDetail() {
    errors.value = {};
    try {
        sampleDetail.value = await postSampleDetail(
            props.sampleId,
            sampleDetail.value
        );
        addFlashMessage("SUCCESS", "Successfully saved sample detail.");
        emit("create-or-update");
    } catch (e) {
        addFlashMessage("ERROR", e.message);
        errors.value = e.errors;
    }
    scrollToTop();
}

watch(
    () => props.selectedSampleDetail,
    () => {
        material.value = props.selectedSampleDetail?.material;
        sampleDetail.value = props.selectedSampleDetail;
        totalQuantity.value =
            (sampleDetail.value.QTY ?? 0) * (props.sampleQuantity ?? 0);
    }
);

async function updateDetail() {
    errors.value = {};
    try {
        sampleDetail.value = await putSampleDetail(
            props.sampleId,
            sampleDetail.value.id,
            sampleDetail.value
        );
        addFlashMessage("SUCCESS", "Successfully updated sample detail.");
        emit("create-or-update");
    } catch (e) {
        addFlashMessage("ERROR", e.message);
        errors.value = e.errors;
    }
    scrollToTop();
}
</script>
