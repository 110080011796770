<template>
    <div class="row g-3">
        <div class="col-md-4">
            <Card class="mb-3">
                <template #header>
                    Select Time Period
                </template>
                <FormSelect v-model="paytype"
                    :options="['BIMONTHLY', 'WEEKLY']"
                    label="Select Paytype" id-prefix="paytype" />
            </Card>
            <TimePeriodSelectTableCard v-model="timePeriod"
                :show-only-type="paytype" />
        </div>
        <div class="col-md-8">
            <Card>
                <template #header>
                    Payslips in Time Period
                </template>
                <PayslipsInTimePeriodTable :time-period="timePeriod?.TIME_PERIOD"
                    v-model="selectedPayslip" />
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-primary me-1"
                            @click="generatePayslipsPDF">
                            Print All Payslips
                        </button>
                        <button type="button" class="btn btn-primary"
                            :disabled="!selectedPayslip"
                            @click="generatePayslipOfEmployeePDF">
                            Print Selected Payslip
                        </button>
                    </div>
                </template>
            </Card>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

import Card from '@/components/utils/Card';
import FormSelect from '@/components/utils/FormSelect';

import TimePeriodSelectTableCard from '@/components/utils/TimePeriodSelectTableCard.vue';
import PayslipsInTimePeriodTable from '@/components/utils/tables/PayslipsInTimePeriodTable.vue';

const paytype = ref(null);
const timePeriod = ref(null);

const selectedPayslip = ref(null);

function generatePayslipsPDF() {
    window.open(route('api.time-periods.payslips.pdf.show', {
        time_period: timePeriod.value.TIME_PERIOD,
    }));
}

function generatePayslipOfEmployeePDF() {
    window.open(route('api.time-periods.payslips.pdf.show', {
        time_period: timePeriod.value.TIME_PERIOD,
        empid: selectedPayslip.value.employee.EMPID,
    }));
}
</script>
