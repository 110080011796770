<template>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <Card>
            <template #header>
                <div class="d-flex justify-content-between pt-2">
                    <h4 class="title">Bulk Create Loan</h4>
                    <button type="button" class="btn-close" @click="emit('close')"></button>
                </div>
            </template>
            <div class="row g-3">
                <div class="col-md-6">
                    <h3>Bulk Loan Information</h3>
                    <FormSelect v-model="form.LOANTYPE"
                        :options="['ITEM', 'CASH ADVANCE', 'DONATION']"
                        label="Loan Type"
                        id-prefix="loanType"
                        class="mb-3"
                        :errors="errors.LOANTYPE" />
                    <FormInput type="date"
                        v-model="form.DATE_GRANTED"
                        label="Date Granted"
                        id-prefix="dateGranted"
                        class="mb-3"
                        :errors="errors.DATE_GRANTED" />
                    <FormInput type="date"
                        v-model="form.DATE"
                        label="Effectivity Date"
                        id-prefix="date"
                        class="mb-3"
                        :errors="errors.DATE" />
                    <FormInput type="text"
                        v-model="form.DESCRIPTION"
                        label="Description"
                        id-prefix="description"
                        class="mb-3"
                        :errors="errors.DESCRIPTION" />
                    <FormInput type="number"
                        v-model="form.NUM_WEEKS"
                        label="# of Weeks"
                        id-prefix="numWeeks"
                        class="mb-3"
                        :errors="errors.NUM_WEEKS" />
                </div>
                <div class="col-md-6">
                    <h3>Individual Details</h3>
                    <DataTable
                        :value="form.DETAILS"
                        paginator
                        :rows="10"
                        :pt="{ table: { class: 'table table-bordered table-hover' }}"
                        class="mb-3">
                        <Column field="employee.EMPID" header="Employee ID" />
                        <Column field="employee.FNAME" header="First Name" />
                        <Column field="employee.LNAME" header="Last Name" />
                        <Column field="INIT_AMT" header="Initial Amt." />
                        <Column field="PRINCIPAL" header="Principal" />
                        <Column field="AMT_WEEK" header="Amt. per Week" />
                        <Column header="Delete">
                            <template #body="{ index }">
                                <button type="button" class="btn btn-sm btn-danger"
                                    @click="deleteDetail(index)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </template>
                        </Column>
                        <template #footer>
                            {{ form.DETAILS.length }} total employees
                        </template>
                        <template #empty>
                            <div class="text-center">
                                Please add individuals using the form below, or import an Excel file.
                            </div>
                        </template>
                    </DataTable>
                    <FormInput type="text"
                        v-model="individualDetailForm.employee.EMPID"
                        disabled
                        label="Employee"
                        id-prefix="empid"
                        :form-text="`${individualDetailForm.employee.FNAME ?? ''} ${individualDetailForm.employee.LNAME ?? ''}`"
                        class="mb-1" />
                    <div class="text-end mb-3">
                        <button type="button" class="btn btn-sm btn-primary"
                            @click="_ => { isSelectEmployeePopupOpen = true; }">
                            Select
                        </button>
                    </div>
                    <FormInput type="number"
                        step=".01"
                        v-model="individualDetailForm.INIT_AMT"
                        label="Initial Amount"
                        id-prefix="initAmt"
                        class="mb-3" />
                    <FormInput type="number"
                        step=".01"
                        v-model="individualDetailForm.PRINCIPAL"
                        label="Principal"
                        id-prefix="principal"
                        class="mb-3" />
                    <FormInput type="number"
                        step=".01"
                        v-model="individualDetailForm.AMT_WEEK"
                        label="Amount per Week"
                        id-prefix="amtWeek"
                        disabled
                        class="mb-3" />
                    <div class="text-end mb-3">
                        <button type="button" class="btn btn-primary"
                            :disabled="!individualDetailForm.employee.EMPID ||
                                !individualDetailForm.INIT_AMT ||
                                !individualDetailForm.PRINCIPAL ||
                                !individualDetailForm.AMT_WEEK"
                            @click="addIndividual">
                            Add Individual
                        </button>
                    </div>
                    <hr />
                    <div class="mb-3">
                        <label for="loansFile" class="form-label">Import File (.xlsx)</label>
                        <input type="file"
                            class="form-control"
                            name="loansFile"
                            id="loansFile"
                            ref="loansFileInput"
                            @change="e => { loansFile = e.target.files[0]; }" />
                    </div>
                    <div class="text-end mb-3">
                        <button type="button" class="btn btn-primary"
                            @click="extractLoansFromSpreadsheet"
                            :disabled="!loansFile">
                            Import .xlsx
                        </button>
                    </div>
                </div>
            </div>
            <template #footer>
                <div class="text-end">
                    <button type="button" class="btn btn-primary me-2"
                        @click="exportSampleBulkSpreadsheet">
                        Get Sample BULK Spreadsheet
                    </button>
                    <button type="button" class="btn btn-primary"
                        :disabled="!form.DETAILS.length"
                        @click="createLoans">
                        Create Bulk
                    </button>
                </div>
            </template>
        </Card>
    </div>

    <SelectEmployee :is-open="isSelectEmployeePopupOpen"
        :on-close="_ => { isSelectEmployeePopupOpen = false; }"
        :on-select-employee="selectedEmployee => {
            individualDetailForm.employee = selectedEmployee;
            isSelectEmployeePopupOpen = false;
        }" />
</template>

<script setup>
import { ref, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useEmployeeLoans } from "@/composables/data/employeeLoans";

import Card from "@/components/utils/Card.vue";
import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import SelectEmployee from "@/components/utils/SelectEmployee.vue";

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(['close']);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const {
    postBulkLoanExtractionFromSpreadsheet,
    postLoansFlock
} = useEmployeeLoans();

const form = ref({
    LOANTYPE: null,
    DATE_GRANTED: null,
    DATE: null,
    DESCRIPTION: null,
    NUM_WEEKS: null,
    DETAILS: [],
});

const individualDetailForm = ref({
    employee: {
        EMPID: null,
    },
    INIT_AMT: null,
    PRINCIPAL: null,
    AMT_WEEK: null,
});

const errors = ref({});

const loansFileInput = ref(null);
const loansFile = ref(null);

const isSelectEmployeePopupOpen = ref(false);

function addIndividual() {
    if (form.value.DETAILS
        .filter(detail => detail.employee.EMPID == individualDetailForm.value.employee.EMPID).length > 0) {
        toasts.add('ERROR', 'Error', `Employee with ID ${individualDetailForm.value.employee.EMPID} already exists.`);
        return;
    }

    form.value.DETAILS.push(individualDetailForm.value);
    individualDetailForm.value = {
        employee: {
            EMPID: null,
        },
        INIT_AMT: null,
        PRINCIPAL: null,
        AMT_WEEK: null,
    };
}

function deleteDetail(index) {
    form.value.DETAILS = [
        ...form.value.DETAILS.slice(0, index),
        ...form.value.DETAILS.slice(index + 1)
    ];
}

function exportSampleBulkSpreadsheet() {
    window.open(route('api.loans.flock.spreadsheet-format.show'));
}

async function extractLoansFromSpreadsheet() {
    loadingFlags.add('extractLoansFromSpreadsheet');
    try {
        const formData = new FormData();
        formData.append("spreadsheet", loansFile.value);
        const extractedData = await postBulkLoanExtractionFromSpreadsheet(formData);
        extractedData.map(loan => {
            const newLoan = {...loan};
            newLoan.AMT_WEEK = loan.INIT_AMT / form.value.NUM_WEEKS;
            return newLoan;
        }).forEach(loan => {
            if (form.value.DETAILS.filter(detail => detail.employee.EMPID == loan.employee.EMPID).length > 0) {
                toasts.add('ERROR', 'Error', `Employee with ID ${loan.employee.EMPID} already exists.`);
                return;
            }

            form.value.DETAILS.push(loan);
        });
        toasts.add('SUCCESS', 'Success', 'Done importing Excel file to table.');
        loansFile.value = null;
        loansFileInput.value.value = null;
    } catch (e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('extractLoansFromSpreadsheet');
}

async function createLoans() {
    errors.value = {};
        
    loadingFlags.add('createLoans');
    try {
        const loanInformation = JSON.parse(JSON.stringify(form.value));
        loanInformation.DETAILS = loanInformation.DETAILS.map(detail => {
            const newDetail = Object.assign({}, detail);
            newDetail.EMPID = newDetail.employee.EMPID;
            return newDetail;
        });
        const createdLoans = await postLoansFlock(loanInformation);
        toasts.add(
            'SUCCESS',
            'Success',
            `Successfully created ${createdLoans.length} loans.<br/>` +
                `Search them in the table using the ff. filters:<br/>` +
                `-> Type: ${loanInformation.LOANTYPE}<br/>` +
                `-> Description: ${loanInformation.DESCRIPTION}<br/>` +
                `-> Date Granted: ${loanInformation.DATE_GRANTED}<br/>` +
                `-> Date: ${loanInformation.DATE}`
        );
        form.value = {
            LOANTYPE: null,
            DATE_GRANTED: null,
            DATE: null,
            DESCRIPTION: null,
            NUM_WEEKS: null,
            DETAILS: [],
        };
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('createLoans');
}

watch(() => form.value.NUM_WEEKS, () => {
    for (const detail of form.value.DETAILS) {
        if (form.value.NUM_WEEKS) {
            detail.AMT_WEEK = detail.INIT_AMT / form.value.NUM_WEEKS;
        } else {
            detail.AMT_WEEK = 0;
        }
    }
});

watch(() => [form.value.NUM_WEEKS, individualDetailForm.value.INIT_AMT], () => {
    if (form.value.NUM_WEEKS && individualDetailForm.value.INIT_AMT) {
        individualDetailForm.value.AMT_WEEK = individualDetailForm.value.INIT_AMT / form.value.NUM_WEEKS;
    } else {
        individualDetailForm.value.AMT_WEEK = 0;
    }
});
</script>

<style scoped>
.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgb(0, 0, 0, 0.5);
    overflow-y: auto;
}

.pop-up .card {
    width: 50%;
}

@media (min-width: 768px) {
    .pop-up .card {
        width: 85%;
    }
}

@media (min-width: 992px) {
    .pop-up .card {
        width: 70%;
    }
}
</style>
