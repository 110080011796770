<template>
    <form @submit.prevent="addNOBC" class="h-100">
        <fieldset class="h-100 d-flex flex-column justify-content-between">
            <legend>
                <i class="icon ph-bold ph-barcode me-2"></i>Add NOBC to Inventory Check
            </legend>
            <div>
                <div class="row mb-xl-0 mb-3">
                    <div class="col-xl-8">
                        <FormInput
                            type="text"
                            v-model="inventoryCheckNoBarcode.STYLE"
                            label="Style"
                            id-prefix="style"
                            :errors="errors.STYLE"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            label-class="col-xl-3 col-lg-2 col-md-5"
                            input-container-class="col-xl-9 col-lg-10 col-md-7 col-12"
                        />
                    </div>
                    <div class="col-xl-4 text-end">
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="(_) => { isSelectingStyle = true; }">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                        </button>
                    </div>
                </div>
                <div class="row mb-xl-0 mb-3">
                    <div class="col-xl-8">
                        <FormInput
                            type="text"
                            v-model="inventoryCheckNoBarcode.COLOR"
                            label="Color"
                            id-prefix="color"
                            :errors="errors.COLOR"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            label-class="col-xl-3 col-lg-2 col-md-5"
                            input-container-class="col-xl-9 col-lg-10 col-md-7 col-12"
                        />
                    </div>
                    <div class="col-xl-4 text-end">
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="(_) => { isSelectingColor = true; }">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                        </button>
                    </div>
                </div>
            </div>
            <div class="text-end">
                <hr class="my-3" />
                <button type="submit" class="btn btn-primary">
                    <i class="icon ph-bold ph-file-plus me-2"></i>Add NOBC
                </button>
            </div>
        </fieldset>
    </form>
    <SelectStyle
        :is-open="isSelectingStyle"
        @select="
            (selectedStyle) => {
                inventoryCheckNoBarcode.STYLE = selectedStyle.STYLE;
                isSelectingStyle = false;
            }
        "
        @close="
            (_) => {
                isSelectingStyle = false;
            }
        "
    />
    <SelectColor
        :is-open="isSelectingColor"
        @select="
            (selectedColor) => {
                inventoryCheckNoBarcode.COLOR = selectedColor.COLOR;
                isSelectingColor = false;
            }
        "
        @close="
            (_) => {
                isSelectingColor = false;
            }
        "
    />
</template>

<script setup>
import { ref } from "vue";
import { useStyles } from "@/composables/data/styles";
import { useColors } from "@/composables/data/colors";
import FormInput from "@/components/utils/FormInput";
import SelectColor from "@/components/utils/SelectColor";
import SelectStyle from "@/components/utils/style/SelectStyle";

const emit = defineEmits(["add"]);

const isSelectingStyle = ref(false);
const isSelectingColor = ref(false);

const { getStyle } = useStyles();
const { getColor } = useColors();

const errors = ref({});

const inventoryCheckNoBarcode = ref({
    STYLE: null,
    COLOR: null,
});

async function addNOBC() {
    errors.value = {};

    try {
        await getStyle(inventoryCheckNoBarcode.value.STYLE);
    } catch (e) {
        errors.value.STYLE = ["Invalid style."];
    }

    try {
        await getColor(inventoryCheckNoBarcode.value.COLOR);
    } catch (e) {
        errors.value.COLOR = ["Invalid color."];
    }

    if (Object.keys(errors.value).length > 0) return;

    emit("add", inventoryCheckNoBarcode.value);
    inventoryCheckNoBarcode.value = {
        STYLE: null,
        COLOR: null,
    };
}
</script>
