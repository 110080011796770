<template>
    <fieldset>
        <legend>NOBC</legend>
        <DataTable
            :loading="isTableLoading"
            lazy
            :value="inventoryNoBarcodes"
            :total-records="inventoryNoBarcodesTotalRecords"
            paginator
            :rows="10"
            @page="(e) => { onPage(e); fetchNoBarcodes(); }"
            v-model:filters="filters"
            filter-display="row"
            @filter="tableOnFilter"
            sort-mode="multiple"
            @sort="(e) => { onSort(e); fetchNoBarcodes(); }"
            v-model:selection="selectedRecord"
            selection-mode="single"
            :pt="{ table: { class: 'table table-sm table-bordered table-hover recon-table' } }">
            <Column
                field="STYLE"
                header="Style"
                sortable
                :pt="{
                    bodyCell: { class: 'barcode' },
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="COMMENT_ID"
                header="ID"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="ROWNUM"
                header="Rownum"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="PREV_LOC"
                header="Prev. Loc"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="LOCATION"
                header="Location"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="PRICE"
                header="Price"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="EMPID"
                header="EMPID"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="SOLD_DT"
                header="Sold Dt."
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="COLOR"
                header="Color"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data.
                </div>
            </template>
        </DataTable>
        <template v-if="$slots.footer">
            <slot name="footer" />
        </template>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useInventoryNoBarcodes } from "@/composables/data/inventoryNoBarcodes";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    location: String,
});

const emit = defineEmits(["error"]);

const { params, filters, onPage, onFilter, onSort } = useDataTableParams([
    "STYLE",
    "COMMENT_ID",
    "ROWNUM",
    "PREV_LOC",
    "LOCATION",
    "PRICE",
    "EMPID",
    "SOLD_DT",
    "COLOR",
]);

const {
    inventoryNoBarcodes,
    inventoryNoBarcodesTotalRecords,
    getInventoryNoBarcodesInLocation,
} = useInventoryNoBarcodes();

const selectedRecord = defineModel();

const isTableLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchNoBarcodes() {
    inventoryNoBarcodes.value = null;

    if (!props.location) {
        return;
    }

    isTableLoading.value = true;
    try {
        await getInventoryNoBarcodesInLocation(props.location, params.value);
    } catch (e) {
        emit("error", e.message);
    }
    isTableLoading.value = false;
}

async function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchNoBarcodes, 300);
}

watch(() => props.location, fetchNoBarcodes);
</script>
