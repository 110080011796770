<template>
    <div class="row">
        <div class="col-xl-8">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-buildings me-2"></i>Quantity per Store
                </legend>
                <div class="row pb-3 px-3">
                    <div class="col-lg-8 mb-lg-0 mb-2">
                        <FormInput
                            label="Location:"
                            is-horizontal
                            label-class="col-xl-3 col-md-5"
                            input-class="form-control"
                            input-container-class="col-xl-9 col-md-7 col-12"
                            class="mb-2"
                            disabled
                            v-model="location.LOCATION"
                        />
                        <div class="row">
                            <div class="col-lg-6 mb-lg-0 mb-2">
                                <button class="btn btn-primary w-100"
                                    data-bs-toggle="modal"
                                    data-bs-target="#modalSelectLocation">
                                    <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select Location
                                </button>
                            </div>
                            <div class="col-lg-6">
                                <button class="btn btn-primary w-100"
                                    :disabled="!location.LOCATION"
                                    @click="reset">
                                    <i class="icon ph-bold ph-arrow-counter-clockwise me-2"></i>Reset Location
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 d-grid">
                        <button class="btn btn-primary" @click="ShowReport">
                            <i class="icon ph-bold ph-storefront me-2"></i>Store Quantity
                        </button>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
    <SelectLocationModal v-model="location" />
</template>
<script setup>
import { ref } from "vue";
import FormInput from "@/components/utils/FormInput.vue";
import SelectLocationModal from "@/components/reports/modal/SelectLocationModal.vue";

const location = ref({
    LOCATION: null,
});

function reset() {
    location.value.LOCATION = null;
}

function ShowReport() {
    window.open(
        route(`api.reports.inventory.quantity-per-store`, {
            LOCATION: location.value?.LOCATION,
        }),
        "_blank"
    );
}
</script>
