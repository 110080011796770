<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <form @submit.prevent="createInventoryCheckDescription">
                <div class="d-flex justify-content-between align-items-center px-4">
                    <h4 class="title mb-0 fw-bold">
                        <i class="icon ph-bold ph-archive-box me-2"></i>
                        Create Inventory Check Description
                    </h4>
                    <button type="button"
                        class="btn-close"
                        @click="emit('close')">
                    </button>
                </div>
                <hr />
                <div class="modal-container px-4">
                    <div class="modal-padding-container">
                        <FormInput
                            type="text"
                            v-model="inventoryCheckDescription.LOCATION"
                            disabled
                            label="Location"
                            id-prefix="location"
                            :errors="errors.LOCATION"
                            class="mb-3"
                            @keydown-enter="focusnext('inventoryDoneFormInput')"
                        />
                        <FormInput
                            type="date"
                            v-model="inventoryCheckDescription.INVENTORY_DONE"
                            label="Inventory Done"
                            id-prefix="inventoryDone"
                            :errors="errors.INVENTORY_DONE"
                            class="mb-3"
                            @keydown-enter="focusnext('descriptionFormInput')"
                        />
                        <FormInput
                            type="text"
                            v-model="inventoryCheckDescription.DESCRIPTION"
                            label="Description"
                            id-prefix="description"
                            :errors="errors.DESCRIPTION"
                            @keydown-enter="focusnext('createInventoryCheckDescriptionButton')"
                        />
                    </div>
                    <div class="modal-padding-container">
                        <hr class="my-3" />
                        <div class="text-end">
                            <button type="submit"
                                class="btn btn-primary"
                                id="createInventoryCheckDescriptionButton"
                                :disabled="isCreatingInventoryCheckDescription">
                                <span v-if="isCreatingInventoryCheckDescription"
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true">
                                </span>
                                <i class="icon ph-bold ph-file-plus me-2"></i>Create
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useInventoryCheckDescriptions } from "@/composables/data/inventoryCheckDescriptions";
import FormInput from "@/components/utils/FormInput";
import focusnext from "@/utils/focusnext";

const { postInventoryCheckDescription } = useInventoryCheckDescriptions();

const props = defineProps({
    location: String,
    isOpen: Boolean,
});

const emit = defineEmits(["success", "error", "close"]);

const inventoryCheckDescription = ref({
    LOCATION: null,
    INVENTORY_DONE: null,
    DESCRIPTION: null,
});

const errors = ref({});

const isCreatingInventoryCheckDescription = ref(false);

watch(
    () => props.location,
    () => {
        inventoryCheckDescription.value.LOCATION = props.location;
    }
);

watch(
    () => props.isOpen,
    () => {
        errors.value = {};
        inventoryCheckDescription.value = {
            LOCATION: props.location,
            INVENTORY_DONE: null,
            DESCRIPTION: null,
        };
    }
);

async function createInventoryCheckDescription() {
    isCreatingInventoryCheckDescription.value = true;
    try {
        const saveResponse = await postInventoryCheckDescription(
            inventoryCheckDescription.value
        );
        emit("success", saveResponse.data.data);
        inventoryCheckDescription.value = {
            LOCATION: props.location,
            INVENTORY_DONE: null,
            DESCRIPTION: null,
        };
    } catch (e) {
        emit("error", e.message);
        errors.value = e.errors;
    }
    isCreatingInventoryCheckDescription.value = false;
}
</script>
