<template>
    <div class="row">
        <div class="col-xl-2">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-calendar me-2"></i>Month
                </legend>
                <DataTable
                    :value="commentDates"
                    paginator
                    :rows="10"
                    filter-display="row"
                    v-model:filters="commentDateFilters"
                    v-model:selection="commentDate"
                    selection-mode="single"
                    data-key="date"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }"
                    class="mb-3">
                    <Column
                        field="date"
                        header="Date"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                                @keydown.enter.prevent=""
                            />
                        </template>
                    </Column>
                    <template #empty>
                        <div class="text-center py-2">
                            <i class="icon ph-bold ph-database me-2"></i>No dates.
                        </div>
                    </template>
                </DataTable>
            </fieldset>
        </div>
        <div class="col-xl-2">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-map-pin me-2"></i>Location
                </legend>
                <DataTable
                    :value="locations"
                    paginator
                    :rows="10"
                    v-model:filters="filters"
                    filterDisplay="row"
                    v-model:selection="location"
                    selection-mode="single"
                    data-key="LOCATION"
                    :row-class="
                        (data) => [
                            { 'bg-primary text-white':  data.LOCATION == location?.LOCATION },
                        ]"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }"
                    class="mb-3">
                    <Column
                        field="LOCATION"
                        header="Location"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                </DataTable>
            </fieldset>
        </div>
        <div class="col-xl-4">
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-arrows-split me-2"></i>Brand Split
                </legend>
                <FormSelect
                    v-model="brandSplit"
                    label="Brand Split"
                    id-prefix="brandSplit"
                    :options="[
                        'KIMBEL + KIMCHI',
                        'KIMBEL + OTHER SPLIT',
                        'KIMBEL + OTHER COMBINED',
                        'KIMCHI ONLY',
                        'ALI OTRS ONLY',
                    ]"
                    class="mb-3"
                    select-class="form-select-sm"
                />
                <div class="row g-1">
                    <div class="col-xl-12 col-lg-6 col-12">
                        <button :disabled="!commentDate || !location || !brandSplit"
                            class="btn btn-primary w-100 btn-sm px-1"
                            @click="
                                (_) => {
                                    generateBrandSplitReport(
                                        'monthly-sales-brand-split-separate-report-pdf'
                                    );
                                }
                            ">
                            <i class="icon ph-bold ph-arrows-out-simple me-2"></i>Sales Report 1 (separate) (QS/QR/AMT/RET)
                        </button>
                    </div>
                    <div class="col-xl-12 col-lg-6 col-12">
                        <button :disabled="!commentDate || !location || !brandSplit"
                            class="btn btn-primary w-100 btn-sm px-1"
                            @click="
                                (_) => {
                                    generateBrandSplitReport(
                                        'monthly-sales-brand-split-combined-report-pdf'
                                    );
                                }
                            ">
                            <i class="icon ph-bold ph-arrows-merge me-2"></i>Sales Report 1 (combined) (QS/QR/AMT/RET)
                        </button>
                    </div>
                    <div class="col-xl-12 col-lg-6 col-12">
                        <button :disabled="!commentDate || !location || !brandSplit"
                            class="btn btn-primary w-100 btn-sm px-1"
                            @click="
                                (_) => {
                                    selectEmployeeReportType = 'SPLIT';
                                    isSelectingPreparedBy = true;
                                }
                            ">
                            <i class="icon ph-bold ph-credit-card me-2"></i>Sales Report 2 (receipt/cash/charge)
                        </button>
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-clipboard-text me-2"></i>SM REPORTS
                </legend>
                <div class="row g-1">
                    <div class="col-xl-12 col-lg-6 col-12">
                        <button :disabled="!commentDate"
                            class="btn btn-primary w-100 px-1 btn-sm"
                            @click="ShowPDFReport('sm-detail')">
                            <i class="icon ph-bold ph-file-text me-2"></i>SM DETAIL REPORTS (periodic + liq)
                        </button>
                    </div>
                    <div class="col-xl-12 col-lg-6 col-12">
                        <button :disabled="!commentDate"
                            class="btn btn-primary w-100 px-1 btn-sm"
                            @click="ShowPDFReport('sm-summary')">
                            <i class="icon ph-bold ph-note me-2"></i>SM SUMMARY REPORTS (periodic + liq + po)
                        </button>
                    </div>
                    <div class="col-xl-12 col-lg-6 col-12">
                        <button :disabled="!commentDate"
                            class="btn btn-primary w-100 px-1 btn-sm"
                            @click="ShowPDFReport('sm-po')">
                            <i class="icon ph-bold ph-ticket me-2"></i>SM PO VOUCHER
                        </button>
                    </div>
                </div>
            </fieldset>
        </div>
        <div class="col-xl-4">
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-percent me-2"></i>Landed Cost/Commission/Daily Sales
                </legend>
                <div class="row g-1">
                    <div class="col-xl-12 col-lg-6 col-12">
                        <button class="btn btn-primary w-100 px-1 btn-sm"
                            disabled>
                            <i class="icon ph-bold ph-currency-dollar me-2"></i>Landed Cost Report (disabled)
                        </button>
                    </div>
                    <div class="col-xl-12 col-lg-6 col-12">
                        <button :disabled="!commentDate"
                            class="btn btn-primary w-100 px-1 btn-sm"
                            @click="generateMonthlyCommissionReport">
                            <i class="icon ph-bold ph-percent me-2"></i>Commission Report
                        </button>
                    </div>
                    <div class="col-xl-12 col-lg-6 col-12">
                        <button class="btn btn-primary w-100 px-1 btn-sm"
                            @click="updateDailySales">
                            <i class="icon ph-bold ph-chart-line-up me-2"></i>Update Daily Sales
                        </button>
                    </div>
                </div>
            </fieldset>
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-arrows-merge me-2"></i>Do Not Split
                </legend>
                <div class="row g-1">
                    <div class="col-xl-12 col-lg-6 col-12">
                        <button :disabled="!commentDate || !location"
                            class="btn btn-primary w-100 px-1 btn-sm"
                            @click="
                                (_) => {
                                    preparedByEmployee = null;
                                    notedByEmployee = null;
                                    generateDoNotSplitReport(
                                        'monthly-sales-separate-report-pdf'
                                    );
                                }
                            ">
                            <i class="icon ph-bold ph-arrows-out-simple me-2"></i>Sales Report 1 (separate) (QS/QR/AMT/RET)
                        </button>
                    </div>
                    <div class="col-xl-12 col-lg-6 col-12">
                        <button :disabled="!commentDate || !location"
                            class="btn btn-primary w-100 px-1 btn-sm"
                            @click="
                                (_) => {
                                    preparedByEmployee = null;
                                    notedByEmployee = null;
                                    generateDoNotSplitReport(
                                        'monthly-sales-combined-report-pdf'
                                    );
                                }
                            ">
                            <i class="icon ph-bold ph-arrows-merge me-2"></i>Sales Report 1 (combined) (QS/QR/AMT/RET)
                        </button>
                    </div>
                    <div class="col-xl-12 col-lg-6 col-12">
                        <button :disabled="!commentDate || !location"
                            class="btn btn-primary w-100 px-1 btn-sm"
                            @click="
                                (_) => {
                                    selectEmployeeReportType = 'NO SPLIT';
                                    isSelectingPreparedBy = true;
                                }
                            ">
                            <i class="icon ph-bold ph-credit-card me-2"></i>Sales Report 2 (receipt/cash/charge)
                        </button>
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-tea-bag me-2"></i>Style Reports
                </legend>
                <div class="row g-1">
                    <div class="col-xl-12 col-lg-6 col-12">
                        <button :disabled="!commentDate"
                            class="btn btn-primary w-100 px-1 btn-sm"
                            @click="
                                (_) => {
                                    generateStyleReport('deliveries');
                                }
                            ">
                            <i class="icon ph-bold ph-truck me-2"></i>Deliveries
                        </button>
                    </div>
                    <div class="col-xl-12 col-lg-6 col-12">
                        <button :disabled="!commentDate"
                            class="btn btn-primary w-100 px-1 btn-sm"
                            @click="
                                (_) => {
                                    generateStyleReport('sales');
                                }
                            ">
                            <i class="icon ph-bold ph-chart-line-up me-2"></i>Sales
                        </button>
                    </div>
                    <div class="col-xl-12 col-lg-6 col-12">
                        <button :disabled="!commentDate"
                            class="btn btn-primary w-100 px-1 btn-sm"
                            @click="
                                (_) => {
                                    generateStyleReport('pullouts');
                                }
                            ">
                            <i class="icon ph-bold ph-arrow-up-right me-2"></i>Pullouts
                        </button>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>

    <SelectEmployee
        :is-open="isSelectingPreparedBy || isSelectingNotedBy"
        :on-select-employee="onEmployeeSelect"
        :on-close="
            (_) => {
                isSelectingPreparedBy = false;
                isSelectingNotedBy = false;
            }
        "
        :title="isSelectingPreparedBy ? 'SELECT PREPARED BY' : 'SELECT NOTED BY'"
        :only-show="['ACCOUNTING', 'ADMIN', 'EXTRA']"
    />
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useCommentDates } from "@/composables/data/commentDates";
import { useLocations } from "@/composables/data/locations";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import FormSelect from "@/components/utils/FormSelect.vue";
import SelectEmployee from "@/components/utils/SelectEmployee.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import { FilterMatchMode } from "primevue/api";

const commentDateFilters = ref({
    date: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const { commentDates, getCommentDates } = useCommentDates();
const commentDate = ref(null);

const { locations, getLocations } = useLocations();
const location = ref(null);

const { filters } = useDataTableParams(["LOCATION"]);

const preparedByEmployee = ref(null);
const notedByEmployee = ref(null);

const isSelectingPreparedBy = ref(false);
const isSelectingNotedBy = ref(false);

const selectEmployeeReportType = ref(null);

const brandSplit = ref(null);

onMounted(() => {
    getLocations();
    getCommentDates();
});

function ShowPDFReport(reportType) {
    window.open(
        route(`api.reports.inventory.monthly-report-generator`, {
            REPORT_TYPE: reportType,
            MONTH: commentDate.value.date,
            LOCATION: location.value?.LOCATION,
        }),
        "_blank"
    );
}

function generateDoNotSplitReport(reportType) {
    window.open(
        route(`api.inventory.${reportType}.show`, {
            MONTH: commentDate.value.date,
            LOCATION: location.value?.LOCATION,
            PREPARED_BY: preparedByEmployee.value?.EMPID,
            NOTED_BY: notedByEmployee.value?.EMPID,
        })
    );
}

function onEmployeeSelect(selectedEmployee) {
    if (isSelectingPreparedBy.value) {
        isSelectingPreparedBy.value = false;
        preparedByEmployee.value = selectedEmployee;
        setTimeout(() => {
            isSelectingNotedBy.value = true;
        }, 200);
    } else if (isSelectingNotedBy.value) {
        isSelectingNotedBy.value = false;
        notedByEmployee.value = selectedEmployee;

        if (selectEmployeeReportType.value == "SPLIT") {
            generateBrandSplitReport(
                "monthly-sales-brand-split-details-report-pdf"
            );
        } else if (selectEmployeeReportType.value == "NO SPLIT") {
            generateDoNotSplitReport("monthly-sales-details-report-pdf");
        }
    }
}

function generateBrandSplitReport(reportType) {
    window.open(
        route(`api.inventory.${reportType}.show`, {
            MONTH: commentDate.value.date,
            LOCATION: location.value?.LOCATION,
            BRAND_SPLIT: brandSplit.value,
            PREPARED_BY: preparedByEmployee.value?.EMPID,
            NOTED_BY: notedByEmployee.value?.EMPID,
        })
    );
}

function generateStyleReport(reportType) {
    window.open(
        route(`api.inventory.monthly-style-report-pdf.show`, {
            MONTH: commentDate.value.date,
            LOCATION: location.value?.LOCATION,
            REPORT_TYPE: reportType,
        })
    );
}

function generateMonthlyCommissionReport() {
    window.open(
        route(`api.inventory.monthly-commission-report-pdf.show`, {
            MONTH: commentDate.value.date,
            LOCATION: location.value?.LOCATION,
        })
    );
}

function updateDailySales() {
    window.open(route(`report.inventory.monthly-reports.update-daily-sales`));
}
</script>
