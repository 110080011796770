<template>
    <Modal id="findCommentOnReturnModal" dialog-class="modal-lg">
        <template #header>
            <h4 class="modal-title">
                <i class="icon ph-bold ph-map-pin me-2"></i>Find Comment by Selling Location
            </h4>
        </template>
        <FormSelect
            label="Location"
            v-model="location"
            :options="locations"
            :option-to-string="(location) => location.LOCATION"
            id-prefix="findCommentLocation"
            :errors="locationErrors"
            @keydown-enter="focusNextInput('findCommentButton')"
        />
        <template #footer>
            <button type="button"
                class="btn btn-outline-secondary"
                ref="closeButton"
                data-bs-dismiss="modal">
                <i class="icon ph-bold ph-x-circle me-2"></i>Close
            </button>
            <button type="button"
                class="btn btn-primary"
                @click="findComment"
                id="findCommentButton">
                <i class="icon ph-bold ph-magnifying-glass me-2"></i>Find
            </button>
        </template>
    </Modal>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useComments } from "@/composables/data/comments";
import { useFlashMessages } from "@/composables/flashmessages";
import { useLocations } from "@/composables/data/locations";
import FormSelect from "@/components/utils/FormSelect.vue";
import Modal from "@/components/utils/Modal.vue";
import focusNextInput from "@/utils/focusnext";

const { addFlashMessage } = useFlashMessages();

const props = defineProps({
    commentDate: String,
});

const emit = defineEmits(["found"]);

const closeButton = ref(null);

const { locations, getLocations } = useLocations();
const location = ref(null);
const locationErrors = ref(null);

const { comments, getComments } = useComments();

onMounted(getLocations);

async function findComment() {
    locationErrors.value = null;

    await getComments({
        MONTH: props.commentDate,
        COMMENT_TYPE: "Return",
        LOCATION: location.value.LOCATION,
    });

    if (comments.value.length > 0) {
        const comment = comments.value[0];
        addFlashMessage(
            "SUCCESS",
            `Comment ID ${comment.COMMENT_ID} found ` +
                `for returning items to location ${location.value.LOCATION}.`
        );
        emit("found", comment);
        closeButton.value.click();
    } else
        locationErrors.value = [
            `No return comment found with this location ` +
                `and comment date ${props.commentDate}.`,
        ];
}
</script>
