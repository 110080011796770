<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-arrows-out-cardinal me-2"></i>Movements
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')"></button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <DataTable
                        :loading="isLoading"
                        lazy
                        :value="inventoryNoBarcodes"
                        :total-records="inventoryNoBarcodesTotalRecords"
                        paginator
                        :rows="10"
                        @page="(e) => { onPage(e); fetchInventoryNoBarcodes(); }"
                        filter-display="row"
                        v-model:filters="filters"
                        @filter="tableOnFilter"
                        @sort="(e) => { onSort(e); fetchInventoryNoBarcodes(); }"
                        :pt="{ table: { class: tableClass } }">
                        <Column
                            field="STYLE"
                            header="Style"
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by Style"
                                />
                            </template>
                        </Column>
                        <Column
                            field="COMMENT_ID"
                            header="ID"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by Comment ID"
                                />
                            </template>
                        </Column>
                        <Column
                            field="ROWNUM"
                            header="Rownum"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by Rownum"
                                />
                            </template>
                        </Column>
                        <Column
                            field="PREV_LOC"
                            header="Prev. Loc."
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by Prevloc"
                                    disabled
                                />
                            </template>
                        </Column>
                        <Column
                            field="LOCATION"
                            header="Location"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by Location"
                                />
                            </template>
                        </Column>
                        <Column
                            field="PRICE"
                            header="Price"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by Price"
                                />
                            </template>
                        </Column>
                        <Column
                            field="SOLD_DT"
                            header="Sold Dt."
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by Sold Date"
                                />
                            </template>
                        </Column>
                        <Column
                            field="COLOR"
                            header="Color"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by Color"
                                />
                            </template>
                        </Column>
                        <template #empty>
                            <div class="text-center py-2">
                                <i class="icon ph-bold ph-database me-2"></i>No data.
                            </div>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useInventoryNoBarcodes } from "@/composables/data/inventoryNoBarcodes";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    noBarcodeEntry: Object,
    tableClass: String,
    isOpen: Boolean,
});

const emit = defineEmits(["error", "close"]);

const tableClass = computed(() => {
    let tableClass = "table table-bordered table-hover";
    if (props.tableClass) {
        tableClass += " " + props.tableClass;
    }
    return tableClass;
});

const { params, filters, onPage, onFilter, onSort } = useDataTableParams([
    "STYLE",
    "COMMENT_ID",
    "ROWNUM",
    "PREV_LOC",
    "LOCATION",
    "PRICE",
    "SOLD_DT",
    "COLOR",
]);

const {
    inventoryNoBarcodes,
    inventoryNoBarcodesTotalRecords,
    getInventoryNoBarcodes,
} = useInventoryNoBarcodes();

const fetchTimeout = ref(null);

const isLoading = ref(false);

async function fetchInventoryNoBarcodes() {
    inventoryNoBarcodes.value = null;

    if (!props.noBarcodeEntry) {
        return;
    }

    isLoading.value = true;
    try {
        await getInventoryNoBarcodes({
            ...params.value,
            filters: {
                ...params.value.filters,
                STYLE: {
                    value: props.noBarcodeEntry.STYLE,
                    matchMode: "equals",
                },
                COMMENT_ID: {
                    value: props.noBarcodeEntry.COMMENT_ID,
                    matchMode: "equals",
                },
                ROWNUM: {
                    value: props.noBarcodeEntry.ROWNUM,
                    matchMode: "equals",
                },
            },
        });
    } catch (e) {
        emit("error", e.message);
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchInventoryNoBarcodes, 300);
}

watch(() => props.noBarcodeEntry, fetchInventoryNoBarcodes);
</script>
