import { ref } from "vue";

import axios from "axios";

import constructFetchError from "./constructFetchError";

export function usePageLocks() {
    const pageLocks = ref(null);

    const getPageLocks = async () => {
        try {
            const getPageLocksResponse = await axios.get(route('api.page-locks.index'));
            pageLocks.value = getPageLocksResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching page locks.');
        }
    };
    
    return {
        pageLocks,
        getPageLocks,
    };
}
