<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-arrows-out-cardinal me-2"></i>Movements
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <DataTable
                        :loading="isLoading"
                        lazy
                        :value="inventoryRecords"
                        :total-records="inventoryTotalRecords"
                        paginator
                        :rows="10"
                        @page="(e) => { onPage(e); fetchInventoryRecords(); }"
                        filter-display="row"
                        v-model:filters="filters"
                        @filter="tableOnFilter"
                        @sort="(e) => { onSort(e); fetchInventoryRecords(); }"
                        :pt="{ table: { class: tableClass } }">
                        <Column
                            field="STYLE"
                            header="Style"
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    disabled
                                    class="form-control"
                                    placeholder="Search by Style"
                                />
                            </template>
                        </Column>
                        <Column
                            field="DATE"
                            header="Date"
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    disabled
                                    class="form-control"
                                    placeholder="Search by Date"
                                />
                            </template>
                        </Column>
                        <Column
                            field="SERIAL"
                            header="Serial"
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    disabled
                                    class="form-control"
                                    placeholder="Search by Serial"
                                />
                            </template>
                        </Column>
                        <Column
                            field="SEQ_NO"
                            header="Seq. No"
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by Seq. No."
                                />
                            </template>
                        </Column>
                        <Column
                            field="PREV_LOC"
                            header="Prev. Loc."
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by Prev Loc"
                                />
                            </template>
                        </Column>
                        <Column
                            field="LOCATION"
                            header="Location"
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by Location"
                                />
                            </template>
                        </Column>
                        <Column
                            field="PRICE"
                            header="Price"
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by Price"
                                />
                            </template>
                        </Column>
                        <Column
                            field="EVENT_DT"
                            header="Event Date"
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by Event Date"
                                />
                            </template>
                        </Column>
                        <Column
                            field="COLOR"
                            header="Color"
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search by Color"
                                />
                            </template>
                        </Column>
                        <template #empty>
                            <div class="text-center py-2">
                                <i class="icon ph-bold ph-database me-2"></i>No data.
                            </div>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useInventory } from "@/composables/data/inventory";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    barcode: Object,
    tableClass: String,
    isOpen: Boolean,
});

const emit = defineEmits(["error", "close"]);

const tableClass = computed(() => {
    let tableClass = "table table-bordered table-hover";
    if (props.tableClass) {
        tableClass += " " + props.tableClass;
    }
    return tableClass;
});

const { params, filters, onPage, onFilter, onSort } = useDataTableParams([
    "STYLE",
    "DATE",
    "SERIAL",
    "SEQ_NO",
    "PREV_LOC",
    "LOCATION",
    "PRICE",
    "EVENT_DT",
    "COLOR",
]);

const { inventoryRecords, inventoryTotalRecords, getInventoryRecords } =
    useInventory();

const fetchTimeout = ref(null);

const isLoading = ref(false);

async function fetchInventoryRecords() {
    inventoryRecords.value = null;

    if (!props.barcode) {
        return;
    }

    isLoading.value = true;
    try {
        await getInventoryRecords({
            ...params.value,
            filters: {
                ...params.value.filters,
                STYLE: {
                    value: props.barcode.STYLE,
                    matchMode: "equals",
                },
                DATE: {
                    value: props.barcode.DATE,
                    matchMode: "equals",
                },
                SERIAL: {
                    value: props.barcode.SERIAL,
                    matchMode: "equals",
                },
            },
        });
    } catch (e) {
        emit("error", e.message);
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchInventoryRecords, 300);
}

watch(() => props.barcode, fetchInventoryRecords);
</script>
