<template>
    <div v-if="isToastError"
        class="position-fixed start-0 end-0 top-0 bottom-0 d-flex justify-content-center align-items-center toast-shadow">
        <div class="toast show bg-danger w-50"
            role="alert"
            aria-live="assertive"
            aria-atomic="true">
            <div class="toast-header">
                <h6 class="me-auto text-black m-0">Error!</h6>
                <button @click="hideToast()"
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="toast"
                    aria-label="Close">
                </button>
            </div>
            <div class="toast-body text-white p-4 fs-1.4">
                {{ toastMessage }}
            </div>
        </div>
    </div>
    <div v-if="isOpen" class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <form action="" method="POST">
                <div class="d-flex justify-content-between align-items-center px-4">
                    <h4 class="title mb-0 fw-bold">
                        <i class="icon ph-bold ph-user me-2"></i>
                        {{ title != null ? title : "SELECT EMPLOYEE" }}
                    </h4>
                    <button type="button"
                        class="btn-close"
                        @click="close()">
                    </button>
                </div>
                <hr />
                <div class="modal-container px-4">
                    <div class="modal-padding-container">
                        <div class="row pe-4">
                            <div class="col-xl-2 col-md-3 col-6">
                                <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('ACCOUNTING')"
                                    @click="filterToDepartment('ACCOUNTING','equals')"
                                    type="button"
                                    class="btn btn-primary w-100 m-1">
                                    ACCOUNTING
                                </button>
                            </div>
                            <div class="col-xl-2 col-md-3 col-6">
                                <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('ADMIN')"
                                    @click="filterToDepartment('ADMIN', 'startsWith')"
                                    type="button"
                                    class="btn btn-primary w-100 m-1">
                                    ADMIN
                                </button>
                            </div>
                            <div class="col-xl-2 col-md-3 col-6">
                                <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('EXTRA')"
                                    @click="filterToDepartment('EXTRA', 'startsWith')"
                                    type="button"
                                    class="btn btn-primary w-100 m-1">
                                    EXTRA
                                </button>
                            </div>
                            <div class="col-xl-2 col-md-3 col-6">
                                <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('CUTTING')"
                                    @click="filterToDepartment('CUTTING', 'equals')"
                                    type="button"
                                    class="btn btn-primary w-100 m-1">
                                    CUTTING
                                </button>
                            </div>
                            <div class="col-xl-2 col-md-3 col-6">
                                <button  v-if="!onlyShowEmployees.size || onlyShowEmployees.has('INDIRECT LABOR') || onlyShowEmployees.has('PRODQC')"
                                    @click="filterToDepartment(['INDIRECT LABOR', 'PRODQC'], 'in')"
                                    type="button"
                                    class="btn btn-primary w-100 m-1">
                                    INDIRECT LABOR
                                </button>
                            </div>
                            <div class="col-xl-2 col-md-3 col-6">
                                <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('RESIGNED')"
                                    @click="filterToDepartment('RESIGNED', 'equals')"
                                    type="button"
                                    class="btn btn-primary w-100 m-1">
                                    RESIGNED
                                </button>
                            </div>
                            <div class="col-xl-2 col-md-3 col-6">
                                <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('MAINTENANCE')"
                                    @click="filterToDepartment('MAINTENANCE', 'equals')"
                                    type="button"
                                    class="btn btn-primary w-100 m-1">
                                    MAINTENANCE
                                </button>
                            </div>
                            <div class="col-xl-2 col-md-3 col-6">
                                <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('MARKETING')"
                                    @click="filterToDepartment('MARKETING', 'equals')"
                                    type="button"
                                    class="btn btn-primary w-100 m-1">
                                    MARKETING
                                </button>
                            </div>
                            <div class="col-xl-2 col-md-3 col-6">
                                <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('PRODUCTION')"
                                    @click="filterToDepartment('PRODUCTION', 'equals')"
                                    type="button"
                                    class="btn btn-primary w-100 m-1">
                                    PRODUCTION
                                </button>
                            </div>
                            <div class="col-xl-2 col-md-3 col-6">
                                <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('RESEARCH AND DEV')"
                                    @click="filterToDepartment('RESEARCH AND DEV', 'equals')"
                                    type="button"
                                    class="btn btn-primary w-100 m-1">
                                    R AND D
                                </button>
                            </div>
                            <div class="col-xl-2 col-md-3 col-6">
                                <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('SALES')"
                                    @click="filterToDepartment('S', 'startsWith')"
                                    type="button"
                                    class="btn btn-primary w-100 m-1">
                                    SALES
                                </button>
                            </div>
                            <div class="col-xl-2 col-md-3 col-6">
                                <button v-if="!onlyShowEmployees.size || onlyShowEmployees.has('CONSTRUCTION')"
                                    @click="filterToDepartment('CONSTRUCTION', 'equals')"
                                    type="button"
                                    class="btn btn-primary w-100 m-1">
                                    CONSTRUCTION
                                </button>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="modal-padding-container">
                        <h5 class="fw-bold mb-3">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>
                            SEARCH EMPLOYEE
                        </h5>
                        <div class="row px-lg-4">
                            <div class="col-md-9 mb-md-0 mb-">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="FIRST_NAME"
                                            class="form-label d-flex align-items-center mb-0">
                                            FIRST NAME:
                                        </label>
                                        <input
                                            v-model="filters.FNAME.value"
                                            type="text"
                                            class="form-control my-1"
                                        />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="LAST_NAME"
                                            class="form-label d-flex align-items-center mb-0">
                                            LAST NAME:
                                        </label>
                                        <input
                                            v-model="filters.LNAME.value"
                                            type="text"
                                            class="form-control my-1"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 d-flex mt-auto mb-1 align-items-center">
                                <button type="button"
                                    class="btn btn-primary w-100 h-100"
                                    @click="fetchEmployees">
                                    <i class="icon ph-bold ph-magnifying-glass me-2"></i>SEARCH
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="modal-padding-container px-lg-4">
                            <DataTable
                                :loading="isLoading"
                                lazy
                                :value="employees"
                                :total-records="totalRecords"
                                paginator
                                :rows="10"
                                @page="
                                    (e) => {
                                        onPage(e);
                                        fetchEmployees();
                                    }"
                                filter-display="row"
                                v-model:filters="filters"
                                @filter="tableOnFilter"
                                selection-mode="single"
                                v-model:selection="employee"
                                :pt="{
                                    table: { class: 'table table-bordered table-hover' },
                                }">
                                <Column
                                    field="EMPID"
                                    header="ID"
                                    :pt="{
                                        filterInput: { class: 'input-group input-group-sm' },
                                        filterMenuButton: { class: 'd-none' },
                                        headerFilterClearButton: { class: 'd-none' },
                                    }">
                                    <template #filter="{ filterModel, filterCallback }">
                                        <InputText
                                            type="text"
                                            v-model="filterModel.value"
                                            @input="filterCallback"
                                            class="form-control"
                                            placeholder="Search"
                                        />
                                    </template>
                                </Column>
                                <Column
                                    field="PAYTYPE"
                                    header="Paytype"
                                    :pt="{
                                        filterInput: { class: 'input-group input-group-sm' },
                                        filterMenuButton: { class: 'd-none' },
                                        headerFilterClearButton: { class: 'd-none' },
                                    }">
                                    <template #filter="{ filterModel, filterCallback }">
                                        <InputText
                                            type="text"
                                            v-model="filterModel.value"
                                            @input="filterCallback"
                                            class="form-control"
                                            placeholder="Search"
                                        />
                                    </template>
                                </Column>
                                <Column
                                    field="FNAME"
                                    header="First Name"
                                    :pt="{
                                        filterInput: { class: 'input-group input-group-sm' },
                                        filterMenuButton: { class: 'd-none' },
                                        headerFilterClearButton: { class: 'd-none',
                                        },
                                    }">
                                    <template #filter="{ filterModel, filterCallback }">
                                        <InputText
                                            type="text"
                                            v-model="filterModel.value"
                                            @input="filterCallback"
                                            class="form-control"
                                            placeholder="Search"
                                        />
                                    </template>
                                </Column>
                                <Column
                                    field="LNAME"
                                    header="Last Name"
                                    :pt="{
                                        filterInput: { class: 'input-group input-group-sm' },
                                        filterMenuButton: { class: 'd-none' },
                                        headerFilterClearButton: { class: 'd-none' },
                                    }">
                                    <template #filter="{ filterModel, filterCallback }">
                                        <InputText
                                            type="text"
                                            v-model="filterModel.value"
                                            @input="filterCallback"
                                            class="form-control"
                                            placeholder="Search"
                                        />
                                    </template>
                                </Column>
                                <Column
                                    field="DEPT"
                                    header="Department"
                                    :pt="{
                                        filterInput: { class: 'input-group input-group-sm' },
                                        filterMenuButton: { class: 'd-none' },
                                        headerFilterClearButton: { class: 'd-none' },
                                    }">
                                    <template #filter="{ filterModel, filterCallback }">
                                        <InputText
                                            type="text"
                                            v-model="filterModel.value"
                                            @input="
                                                (e) => {
                                                    filterModel.matchMode =
                                                        'contains';
                                                    filterCallback(e);
                                                }
                                            "
                                            class="form-control"
                                            placeholder="Search"
                                        />
                                    </template>
                                </Column>
                            </DataTable>
                    </div>
                    <div class="modal-padding-container pb-4">
                        <button type="button"
                            class="btn btn-primary mx-lg-4 mt-3"
                            :disabled="!employee"
                            @click="handleSubmit">
                            <i class="icon ph-bold ph-hand-pointing me-2"></i>SELECT EMPLOYEE
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useEmployees } from "@/composables/data/employees";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    isOpen: Boolean,
    onClose: Function,
    onSelectEmployee: Function,
    onlyShow: Array,
    title: String,
});

const { params, filters, onPage, onFilter, onSort } = useDataTableParams([
    "EMPID",
    "PAYTYPE",
    "FNAME",
    "LNAME",
    "DEPT",
]);

const { employees, totalRecords, getEmployees } = useEmployees();

const employee = ref(null);

const toastMessage = ref("");
const isToastError = ref(false);
const onlyShowEmployees = ref(new Set(props.onlyShow));

const isLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchEmployees() {
    isLoading.value = true;
    try {
        await getEmployees(params.value);
    } catch (e) {
        console.error(e);
        isToastError.value = true;
        toastMessage.value = e.message;
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchEmployees, 300);
}

onMounted(fetchEmployees);

async function filterToDepartment(dept, matchMode) {
    filters.value.DEPT = {
        value: dept,
        matchMode,
    };
    tableOnFilter();
}

function handleSubmit() {
    if (!employee.value) {
        isToastError.value = true;
        toastMessage.value = "No employee selected.";
    } else {
        props.onSelectEmployee(employee.value);
        close();
    }
}

function close() {
    props.onClose();
}

function hideToast() {
    isToastError.value = false;
}
</script>
