import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useStyleSMSkus() {
    const styleSMSkus = ref(null);

    const getStyleSMSkus = async (style, params) => {
        try {
            const styleSMSkusResponse = await axios.get(route('api.styles.sm-skus.index', {
                style: style,
                ...params
            }));
            styleSMSkus.value = styleSMSkusResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching SM SKUs.');
        }
    };

    const postStyleSMSku = async (style, styleSMSku) => {
        try {
            const postStyleSMSkuResponse = await axios.post(route('api.styles.sm-skus.store', {
                style,
            }), styleSMSku);
            return postStyleSMSkuResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while inserting SM SKU.');
        }
    };

    const putStyleSMSku = async (style, styleSMSkuId, styleSMSku) => {
        try {
            const putStyleSMSkuResponse = await axios.put(route('api.styles.sm-skus.update', {
                style,
                sm_sku: styleSMSkuId,
            }), styleSMSku);
            return putStyleSMSkuResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating SM SKU.');
        }
    };
    
    return {
        styleSMSkus,
        getStyleSMSkus,
        postStyleSMSku,
        putStyleSMSku,
    };
}
