<template>
    <div class="row">
        <div class="col-xl-4 mb-xl-0 mb-3">
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-user me-2"></i>Select Employee
                </legend>
                <FormInput
                    type="text"
                    v-model="selectedEmployeeId"
                    label="Employee ID"
                    id-prefix="empid"
                    disabled
                    class="mb-md-1 mb-2"
                    is-horizontal
                    label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                />
                <FormInput
                    type="text"
                    v-model="selectedEmployeeDepartment"
                    label="Department"
                    id-prefix="department"
                    disabled
                    class="mb-md-1 mb-2"
                    is-horizontal
                    label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                />
                <FormInput
                    type="text"
                    v-model="selectedEmployeeFirstName"
                    label="First Name"
                    id-prefix="firstName"
                    disabled
                    class="mb-md-1 mb-2"
                    is-horizontal
                    label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                />
                <FormInput
                    type="text"
                    v-model="selectedEmployeeLastName"
                    label="Last Name"
                    id-prefix="lastName"
                    disabled
                    class="mb-md-1 mb-2"
                    is-horizontal
                    label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                />
                <div class="text-end mt-3">
                    <button  type="button"
                        class="btn btn-primary btn-sm"
                        @click="(_) => { isSelectEmployeePopupOpen = true; }">
                        <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                    </button>
                </div>
            </fieldset>
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-calendar me-2"></i>Specify Date Range
                </legend>
                <FormInput
                    type="date"
                    v-model="startDateFilter"
                    label="Start Date"
                    id-prefix="startDate"
                    class="mb-md-1 mb-2"
                    is-horizontal
                    label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                />
                <FormInput
                    type="date"
                    v-model="endDateFilter"
                    label="End Date"
                    id-prefix="endDate"
                    class="mb-md-1 mb-2"
                    is-horizontal
                    label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                />
                <div class="text-end mt-3">
                    <button type="button"
                        class="btn btn-primary btn-sm"
                        @click="applyDateFilters">
                        <i class="icon ph-bold ph-checks me-2"></i>Apply
                    </button>
                </div>
            </fieldset>
        </div>
        <div class="col-xl-8">
            <EmployeeNidekaHoursTableCard
                :employee-id="selectedEmployee?.EMPID"
                :start-date="appliedFilters.startDate"
                :end-date="appliedFilters.endDate"
            />
        </div>
    </div>

    <SelectEmployee
        :is-open="isSelectEmployeePopupOpen"
        :on-close="
            (_) => {
                isSelectEmployeePopupOpen = false;
            }
        "
        :on-select-employee="
            (employee) => {
                selectedEmployee = employee;
                isSelectEmployeePopupOpen = false;
            }
        "
    />
</template>

<script setup>
import { computed, ref } from 'vue';
import FormInput from "@/components/utils/FormInput.vue";
import SelectEmployee from "@/components/utils/SelectEmployee.vue";
import EmployeeNidekaHoursTableCard from './EmployeeNidekaHoursTableCard.vue';

const selectedEmployee = ref(null);

const selectedEmployeeId = computed(() => selectedEmployee.value?.EMPID);
const selectedEmployeeDepartment = computed(() => selectedEmployee.value?.DEPT);
const selectedEmployeeFirstName = computed(() => selectedEmployee.value?.FNAME);
const selectedEmployeeLastName = computed(() => selectedEmployee.value?.LNAME);

const isSelectEmployeePopupOpen = ref(false);

const startDateFilter = ref(null);
const endDateFilter = ref(null);

const appliedFilters = ref({
    startDate: null,
    endDate: null,
});

function applyDateFilters() {
    appliedFilters.value.startDate = startDateFilter.value;
    appliedFilters.value.endDate = endDateFilter.value;
}
</script>
