<style scoped>
    .sample-image {
        max-height: 150px;
    }
</style>

<template>
    <div v-if="isLoading"
        class="spinner-border"
        role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
    <div v-else-if="sampleImages?.length > 0"
        class="row g-3">
        <div v-for="(imageURL, index) in imageURLs"
            class="col-md-4 text-center">
            <p v-if="imageLoadingStatuses[index]">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                Loading image #{{ index + 1 }}
            </p>
            <img :src="imageURL"
                :alt="sample.DESCRIPTION + ' sample image ' + index"
                @load="_ => { imageLoadingStatuses[index] = false; }"
                @click="_ => { isImageSelected[index] = !isImageSelected[index] ?? true; }"
                class="img-fluid sample-image"
                :class="{ 'border border-4 border-danger': isImageSelected[index] }"
                @error="_ => { setDefaultImage(index); }"
            />
        </div>
    </div>
    <div v-else>
        No images.
    </div>
    <hr class="my-4">
    <div class="text-end">
        <button type="button"
            class="btn btn-danger"
            :disabled="Object.values(isImageSelected).filter(status => status).length == 0 || isDeleting"
            @click="deleteSelectedImages">
            <span v-if="isDeleting"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true">
            </span>
            <i class="icon ph-bold ph-trash me-2"></i>Delete Selected Images
        </button>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useSampleImages } from '@/composables/data/sampleImages';

const props = defineProps({
    sample: Object,
    class: String
});

const emit = defineEmits(['error']);

const { sampleImages, getSampleImages, deleteSampleImage } = useSampleImages();

const isLoading = ref(false);

const imageLoadingStatuses = ref(null);
const imageURLs = ref(null);

const isDeleting = ref(false);

const isImageSelected = ref({});

async function fetchImages() {
    sampleImages.value = null;
    isImageSelected.value = {};

    if(!props.sample)
        return;

    isLoading.value = true;
    try {
        await getSampleImages(props.sample.SAMPLE_ID);
        setTimeout(() => {
            imageURLs.value = sampleImages.value.map(sampleImage => sampleImage.image_url);
            imageLoadingStatuses.value = sampleImages.value.map(_ => true);
        });
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

async function deleteSelectedImages() {
    isDeleting.value = true;
    try {
        for(const imageIndex of Object.keys(isImageSelected.value).filter(imageIndex => isImageSelected.value[imageIndex]))
            await deleteSampleImage(props.sample.SAMPLE_ID, sampleImages.value[imageIndex].id);
        emit('success', 'Successfully deleted images.');
    } catch(e) {
        emit('error', e.message);
    }
    isDeleting.value = false;
    isImageSelected.value = {};
    fetchImages();
}

watch(() => props.sample, fetchImages);

function setDefaultImage(index) {
    imageLoadingStatuses.value[index] = true;
    imageURLs.value[index] = `${Ziggy.url}/img/bagnoimage.jpg`;
}
</script>
