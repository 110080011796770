<template>
    <fieldset>
        <legend><i class="icon ph-bold ph-barcode me-2"></i>SKU Price</legend>
        <div class="row g-3 mb-3">
            <div class="col-xl-8">
                <DataTable :value="styleSkuPrices"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }">
                    <Column field="STYLE" header="Style" />
                    <Column field="START_DATE" header="Start Date" />
                    <Column field="END_DATE" header="End Date" />
                    <Column field="SKU_PRICE" header="SKU Price" />
                    <Column field="COMMENT" header="Comment" />
                    <template #empty>
                        <template v-if="style == null">
                            Please select a Style first.
                        </template>
                        <template v-else>
                            There are no SKU prices for this Style.
                        </template>
                    </template>
                </DataTable>
            </div>
            <div class="col-xl-4">
                <form @submit.prevent="saveStyleSkuPrice">
                    <FormInput
                        type="date"
                        label="Start Date"
                        v-model="styleSkuPrice.START_DATE"
                        is-horizontal
                        id-prefix="skuStartDate"
                        class="mb-md-1 mb-2"
                        input-class="form-control-sm"
                        :errors="errors.START_DATE"
                        @keydown-enter="focusNextInput('skuPriceFormInput')"
                        label-class="col-xl-5 col-lg-3 col-md-5 label-sm"
                        input-container-class="col-xl-7 col-lg-9 col-md-7 col-12"
                    />
                    <FormInput
                        type="number"
                        step=".01"
                        label="SKU Price"
                        v-model="styleSkuPrice.SKU_PRICE"
                        is-horizontal
                        id-prefix="skuPrice"
                        class="mb-md-1 mb-2"
                        input-class="form-control-sm"
                        :errors="errors.SKU_PRICE"
                        @keydown-enter="focusNextInput('saveSkuPriceButton')"
                        label-class="col-xl-5 col-lg-3 col-md-5 label-sm"
                        input-container-class="col-xl-7 col-lg-9 col-md-7 col-12"
                    />
                    <div class="text-end mt-3">
                        <button type="submit"
                            class="btn btn-primary"
                            id="saveSkuPriceButton"
                            :disabled="style == null">
                            <i class="icon ph-bold ph-floppy-disk me-2"></i>Save SKU Price
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";
import FormInput from "../../../utils/FormInput.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import axios from "axios";
import focusNextInput from "../../../../utils/focusnext";
import handleFetchErrors from "../../../../utils/handleFetchErrors";
import scrollToTop from "../../../../utils/scrollToTop";
import { useFlashMessages } from "../../../../composables/flashmessages";

const { addFlashMessage } = useFlashMessages();

const props = defineProps({
    style: Object,
    class: String,
});

const errors = ref({});

const styleSkuPrices = ref();

const styleSkuPrice = ref({
    START_DATE: null,
    SKU_PRICE: null,
});

async function loadStyleSkuPrices() {
    try {
        const styleSkuPricesResponse = await axios.get(
            route("api.styles.style-sku-prices.index", {
                style: props.style.STYLE,
            })
        );
        styleSkuPrices.value = styleSkuPricesResponse.data.data;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching Style SKU Prices.");
        scrollToTop();
    }
}

watch(
    () => props.style,
    () => {
        styleSkuPrices.value = null;
        styleSkuPrice.value = {
            START_DATE: null,
            SM_PRICE: null,
            LM_REG_PRICE: null,
            LM_SALE_PRICE: null,
        };
        errors.value = {};
        if (props.style == null) return;
        loadStyleSkuPrices();
    }
);

async function saveStyleSkuPrice() {
    errors.value = {};
    try {
        await axios.post(
            route("api.styles.style-sku-prices.store", {
                style: props.style.STYLE,
            }),
            styleSkuPrice.value
        );
        addFlashMessage("SUCCESS", "Successfully saved style sku price.");
        styleSkuPrice.value = {
            START_DATE: null,
            SKU_PRICE: null,
        };
        loadStyleSkuPrices();
    } catch (e) {
        console.log(e);
        errors.value = handleFetchErrors(
            e,
            "Error while fetching Style SKU Prices."
        );
    }
    scrollToTop();
}
</script>
