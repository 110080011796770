<template>
    <Card class="mb-3">
        <template #header>
            Select Comment
        </template>
        <CommentsTable v-model="selectedComment"
            :refresh-flag="tableRefreshFlag" />
        <template #footer>
            <div class="text-end">
                <button type="button" class="btn btn-danger"
                    :disabled="!selectedComment"
                    @click="closeComment">
                    Close Comment
                </button>
            </div>
        </template>
    </Card>
    <div class="row g-3">
        <div class="col-lg-4">
            <fieldset>
                <legend>
                    Update Comment Details
                </legend>
                <FormInput type="date"
                    label="Comment Timestamp"
                    v-model="form.COMMENT_TIMESTAMP"
                    is-horizontal
                    id-prefix="commentTimestamp"
                    class="mb-3"
                    label-class="col-xl-3 col-md-5 label-sm"
                    input-container-class="col-xl-4 col-md-7 col-12"
                    disabled />
                <FormInput type="date"
                    label="Comment Rpt. Date (Original)"
                    v-model="form.COMMENT_RPT_DATE_ORIG"
                    is-horizontal
                    id-prefix="commentRptDateOrig"
                    class="mb-3"
                    label-class="col-xl-3 col-md-5 label-sm"
                    input-container-class="col-xl-4 col-md-7 col-12"
                    disabled />
                <FormInput type="date"
                    label="Comment Rpt. Date"
                    v-model="form.COMMENT_RPT_DATE"
                    is-horizontal
                    id-prefix="commentRptDate"
                    class="mb-3"
                    label-class="col-xl-3 col-md-5 label-sm"
                    input-container-class="col-xl-4 col-md-7 col-12"
                    :errors="errors.COMMENT_RPT_DATE" />
                <FormInput type="text"
                    label="Location"
                    v-model="form.LOCATION"
                    is-horizontal
                    id-prefix="location"
                    class="mb-1"
                    label-class="col-xl-3 col-md-5 label-sm"
                    input-container-class="col-xl-4 col-md-7 col-12"
                    :errors="errors.LOCATION"
                    disabled />
                <div class="text-end mb-3">
                    <button type="button" class="btn btn-sm btn-primary"
                        @click="_ => { isSelectLocationPopupOpen = true; }">
                        Select
                    </button>
                </div>
                <FormInput type="text"
                    label="Comment Text"
                    v-model="form.COMMENT_TEXT"
                    is-horizontal
                    id-prefix="commentText"
                    class="mb-3"
                    label-class="col-xl-3 col-md-5 label-sm"
                    input-container-class="col-xl-4 col-md-7 col-12"
                    :errors="errors.COMMENT_TEXT" />
                <FormInput type="text"
                    label="Payee Type"
                    v-model="form.PAYEE_TYPE"
                    class="mb-3"
                    id-prefix="payeeType"
                    label-class="col-xl-3 col-md-5 label-sm"
                    input-container-class="col-xl-4 col-md-7 col-12"
                    is-horizontal
                    disabled />
                <FormInput type="text"
                    label="Payee ID"
                    v-model="form.PAYEE_ID"
                    is-horizontal
                    id-prefix="payeeId"
                    label-class="col-xl-3 col-md-5 label-sm"
                    input-container-class="col-xl-4 col-md-7 col-12"
                    disabled />
                <div class="text-end">
                    <button type="button" class="btn btn-primary"
                        :disabled="!selectedComment"
                        @click="updateComment">
                        Update Comment
                    </button>
                </div>
            </fieldset>
        </div>
    </div>

    <SelectLocationPopup :is-open="isSelectLocationPopupOpen"
        :filters="locationFilters"
        @close="_ => { isSelectLocationPopupOpen = false; }"
        @select="selectedLocation => {
            form.LOCATION = selectedLocation.LOCATION;
            location = selectedLocation;
            isSelectLocationPopupOpen = false;
        }" />
</template>

<script setup>
import { ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useComments } from '@/composables/data/comments';

import Card from '@/components/utils/Card.vue';
import CommentsTable from '@/components/utils/tables/CommentsTable.vue';
import FormInput from '@/components/utils/FormInput.vue';
import SelectLocationPopup from '@/components/utils/popups/SelectLocationPopup.vue';

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { putComment, postCommentClosure } = useComments();

const selectedComment = ref(null);

const form = ref({
    COMMENT_ID: null,
    COMMENT_TIMESTAMP: null,
    COMMENT_RPT_DATE_ORIG: null,
    COMMENT_RPT_DATE: null,
    LOCATION: null,
    COMMENT_TEXT: null,
    PAYEE_TYPE: null,
    PAYEE_ID: null,
});

const location = ref(null);

const errors = ref({});

const isSelectLocationPopupOpen = ref(false);
const locationFilters = ref({});

const tableRefreshFlag = ref(false);

watch(selectedComment, () => {
    form.value = Object.assign({
        COMMENT_ID: null,
        COMMENT_TIMESTAMP: null,
        COMMENT_RPT_DATE_ORIG: null,
        COMMENT_RPT_DATE: null,
        LOCATION: null,
        COMMENT_TEXT: null,
        PAYEE_TYPE: null,
        PAYEE_ID: null,
    }, selectedComment.value);

    if (form.value.COMMENT_TEXT) {
        form.value.COMMENT_TEXT = [...form.value.COMMENT_TEXT.matchAll(/(\([^\)]+\)\s)?\((.*)\)/g)].at(-1)?.at(-1) ?? form.value.COMMENT_TEXT;
    }

    // Location Filters depend on comment text type.
    // DEV NOTE: There is no appropriate TYPE field since old system. (There is a COMMENT_TYPE but it is not for this context.)
    locationFilters.value = {};
    if (selectedComment.value) {
        if (selectedComment.value.COMMENT_TEXT.startsWith('Initial Inventory') ||
            selectedComment.value.COMMENT_TEXT.startsWith('Return') ||
            selectedComment.value.COMMENT_TEXT.startsWith('Replacement')) {
            locationFilters.value = {
                TYPE: {
                    matchMode: 'in',
                    value: ['S', 'H'],
                },
            };
        } else if (selectedComment.value.COMMENT_TEXT.startsWith('Delivery')) {
            locationFilters.value = {
                TYPE: {
                    matchMode: 'equals',
                    value: 'S',
                },
            };
        } else if (selectedComment.value.COMMENT_TEXT.startsWith('Pull-out') ||
            selectedComment.value.COMMENT_TEXT.startsWith('New Stock')) {
            locationFilters.value = {
                TYPE: {
                    matchMode: 'equals',
                    value: 'H',
                },
            };
        } else if (selectedComment.value.COMMENT_TEXT.startsWith('Sales')) {
            locationFilters.value = {
                TYPE: {
                    matchMode: 'equals',
                    value: 'P',
                },
            };
        } else if (selectedComment.value.COMMENT_TEXT.startsWith('Reconciliation')) {
            locationFilters.value = {
                TYPE: {
                    matchMode: 'equals',
                    value: 'R',
                },
            };
        }
    }

    errors.value = {};
});

async function updateComment() {
    errors.value = {};

    loadingFlags.add('updateComment');
    try {
        await putComment(selectedComment.value.COMMENT_ID, form.value);
        toasts.add('SUCCESS', 'Success', 'Successfully updated comment.');
        tableRefreshFlag.value = !tableRefreshFlag.value;
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('updateComment');
}

async function closeComment() {
    if (window.prompt("Are you sure? Type 'CLOSE' to proceed.") != 'CLOSE') {
        return;
    }

    loadingFlags.add('closeComment');
    try {
        await postCommentClosure(selectedComment.value.COMMENT_ID);
        toasts.add('SUCCESS', 'Success', 'Successfully closed comment.');
        tableRefreshFlag.value = !tableRefreshFlag.value;
    } catch (e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('closeComment');
}
</script>
