<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-user me-2"></i>Change Seller
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <form @submit.prevent="updateSellerOfBarcodeSequence"
                        class="my-3">
                        <FormSelect
                            label="Employee"
                            v-model="seller"
                            :options="employees"
                            :option-to-string="(employee) => [ employee.EMPID, employee.FNAME + ' ' + employee.LNAME, ].join(' | ')"
                            id-prefix="employee"
                            class="mb-2"
                            :errors="errors.EMPID"
                            @keydown-enter="focusnext('updateButton')"
                        />

                        <div class="text-end">
                            <button type="submit"
                                class="btn btn-primary"
                                id="updateButton">
                                <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useInventory } from "@/composables/data/inventory";
import { useEmployeeLocations } from "@/composables/data/employeeLocations";
import FormSelect from "@/components/utils/FormSelect.vue";
import focusnext from "@/utils/focusnext";

const { updateSeller } = useInventory();

const props = defineProps({
    inventoryRecord: Object,
    isOpen: Boolean,
});

const emit = defineEmits(["success", "close"]);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { employeeLocations, getEmployeeLocations } = useEmployeeLocations();

const employees = ref(null);

const seller = ref(null);

const errors = ref({});

async function updateSellerOfBarcodeSequence() {
    errors.value = {};

    loadingFlags.add("updateSellerOfBarcodeSequence");
    try {
        await updateSeller(
            props.inventoryRecord.STYLE,
            props.inventoryRecord.DATE,
            props.inventoryRecord.SERIAL,
            props.inventoryRecord.SEQ_NO,
            seller.value?.EMPID
        );
        toasts.add("SUCCESS", "Success", "Successfully updated seller.");
        emit("success");
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("updateSellerOfBarcodeSequence");
}

async function fetchAvailableEmployees() {
    loadingFlags.add("fetchAvailableEmployees");
    try {
        await getEmployeeLocations({
            LOCATION: props.inventoryRecord.PREV_LOC,
            START_DATE_LTE: props.inventoryRecord.SOLD_DT,
            END_DATE_GTE: props.inventoryRecord.SOLD_DT,
        });
        employees.value = employeeLocations.value.map(
            (employeeLocation) => employeeLocation.employee
        );
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchAvailableEmployees");
}

watch(
    () => props.isOpen,
    async () => {
        seller.value = null;

        if (props.inventoryRecord.SOLD_DT) {
            await fetchAvailableEmployees();
        }
    }
);
</script>

<style scoped>
.container {
    max-width: 600px;
}
</style>
