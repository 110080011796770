<template>
    <fieldset>
        <legend><i class="icon ph-bold ph-barcode me-2"></i>SM SKU</legend>
        <DataTable
            :loading="loadingFlags.has('fetchSMSkus')"
            :value="styleSMSkus"
            paginator
            :rows="10"
            selection-mode="single"
            v-model:selection="smSku"
            data-key="id"
            table-class="table table-bordered table-hover"
            class="mb-3">
            <Column field="id" header="ID" />
            <Column field="COLOR" header="Color" />
            <Column field="SM_SKU" header="SM SKU" />
            <template #empty>No SM Skus found.</template>
        </DataTable>
        <form>
            <FormSelect
                label="Color"
                v-model="form.COLOR"
                :options="colors"
                is-horizontal
                id-prefix="color"
                class="mb-md-1 mb-2"
                select-class="form-select-sm"
                :errors="errors.COLOR"
                @keydown-enter="focusNextInput('smSkuFormInput')"
                label-class="col-xl-4 col-md-5 label-sm"
                select-container-class="col-xl-8 col-md-7 col-12"
            />
            <FormInput
                label="SM SKU"
                type="text"
                v-model="form.SM_SKU"
                is-horizontal
                id-prefix="smSku"
                class="mb-md-1 mb-2"
                input-class="form-control-sm"
                :errors="errors.SM_SKU"
                label-class="col-xl-4 col-md-5 label-sm"
                input-container-class="col-xl-8 col-md-7 col-12"
            />
            <div class="text-end">
                <hr class="my-3" />
                <button type="button"
                    class="btn btn-primary me-1 mb-1"
                    :disabled="!style || smSku"
                    @click="insertSMSku">
                    <i class="icon ph-bold ph-file-plus me-2"></i>Insert
                </button>
                <button type="button"
                    class="btn btn-primary me-1 mb-1"
                    :disabled="!style || !smSku"
                    @click="updateSMSku">
                    <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Update
                </button>
                <button type="reset"
                    class="btn btn-outline-danger me-1 mb-1"
                    @click="clear">
                    <i class="icon ph-bold ph-x me-2"></i>Clear
                </button>
            </div>
        </form>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useStyleSMSkus } from "@/composables/data/styleSMSkus";

import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";

import Column from "primevue/column";
import DataTable from "primevue/datatable";

import focusNextInput from "@/utils/focusnext";

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const props = defineProps({
    style: String,
    colors: Array,
    class: String,
});

const errors = ref({});

const { styleSMSkus, getStyleSMSkus, postStyleSMSku, putStyleSMSku } = useStyleSMSkus();

const smSku = ref(null);

const form = ref({
    COLOR: null,
    SM_SKU: null,
});

async function fetchSMSkus() {
    styleSMSkus.value = null;

    if (!props.style) {
        return;
    }

    loadingFlags.add("fetchSMSkus");
    try {
        await getStyleSMSkus(props.style);
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchSMSkus");
}

watch(
    () => props.style,
    async () => {
        errors.value = {};
        smSku.value = null;
        fetchSMSkus();
    }
);

watch(smSku, () => {
    errors.value = {};
    form.value = Object.assign({
        COLOR: null,
        SM_SKU: null,
    }, smSku.value);
});

async function insertSMSku() {
    errors.value = {};

    loadingFlags.add("insertSMSku");
    try {
        const newSMSku = await postStyleSMSku(props.style, form.value);
        toasts.add(
            "SUCCESS",
            "Success",
            `Successfully created SM SKU ${newSMSku.SM_SKU}.`
        );
        smSku.value = newSMSku;
        fetchSMSkus();
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("insertSMSku");
}

async function updateSMSku() {
    errors.value = {};
    
    loadingFlags.add("updateSMSku");
    try {
        const newSMSku = await putStyleSMSku(props.style, smSku.value.id, form.value);
        toasts.add(
            "SUCCESS",
            "Success",
            `Successfully updated SM SKU to ${newSMSku.SM_SKU}.`
        );
        smSku.value = newSMSku;
        fetchSMSkus();
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("updateSMSku");
}

function clear() {
    smSku.value = null;
}
</script>
