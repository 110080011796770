<style scoped>
#employeesTableContainer {
    max-height: 300px;
    overflow-y: scroll;
}
</style>

<template>
    <Card :class="class">
        <h2>Select an Employee</h2>
        <form @submit.prevent="fetchEmployees">
            <!-- Filters -->
            <div class="row g-3 mb-3">
                <!-- Time Period-->
                <div class="col-md-6">
                    <label for="timePeriodFilter">Time Period</label>
                    <select v-model="selectedTimePeriod" class="form-select" id="timePeriodFilter"
                        @change="timePeriodFilterOnChange">
                        <option disabled selected :value="null">SELECT OPEN TIME PERIOD</option>
                        <option v-for="timePeriod in openTimePeriodsList" :value="timePeriod">
                            {{ timePeriod.TIME_PERIOD }} - {{ timePeriod.PAYTYPE }} - {{ timePeriod.START_DATE }} to
                            {{ timePeriod.END_DATE }}
                        </option>
                    </select>
                </div>
                <!-- Department -->
                <div class="col-md-6">
                    <label for="departmentFilter">Department (optional)</label>
                    <select v-model="selectedDepartment" class="form-select" id="departmentFilter"
                        @change="departmentFilterOnChange">
                        <option disabled selected :value="null">SELECT DEPARTMENT</option>
                        <option v-for="department in departmentsList" :value="department">
                            {{ department.DEPT }}
                        </option>
                    </select>
                </div>
                <!-- Employee First Name -->
                <div class="col-md-6">
                    <label for="firstNameFilter">First Name of Employee</label>
                    <input type="text" v-model="firstNameFilter" id="firstNameFilter" class="form-control"
                        placeholder="Specify the first name...">
                </div>
                <!-- Employee Last Name -->
                <div class="col-md-6">
                    <label for="lastNameFilter">Last Name of Employee</label>
                    <input type="text" v-model="lastNameFilter" id="lastNameFilter" class="form-control"
                        placeholder="Specify the last name...">
                </div>
                <!-- Load Button -->
                <div class="col-md-6">
                    <button type="submit" class="btn btn-primary me-2 mb-2"
                        :disabled="!selectedTimePeriod">Load</button>
                    <button type="button" class="btn btn-secondary me-2 mb-2"
                        @click="clearButtonOnClick">Clear</button>
                </div>
            </div>
        </form>
        <!-- Employees List Based on Filter -->
         <DataTable
            :loading="loadingFlags.has('fetchEmployees')"
            lazy
            :value="employees"
            :total-records="totalRecords"
            paginator
            :rows="10"
            @page="e => { onPage(e); fetchEmployees(); }"
            selection-mode="single"
            v-model:selection="selectedEmployee"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="EMPID" header="Employee ID" />
            <Column field="PAYTYPE" header="Paytype" />
            <Column field="DEPT" header="Department" />
            <Column field="FNAME" header="First Name" />
            <Column field="LNAME" header="Last Name" />
            <template #empty>
                <div class="text-center">
                    No data yet.
                </div>
            </template>
         </DataTable>
    </Card>
</template>

<script setup>
import { ref, onMounted } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useEmployees } from '@/composables/data/employees';

import Card from '@/components/utils/Card.vue';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import { watch } from 'vue';

const props = defineProps({
    class: String
});

const emit = defineEmits(['time-period-select', 'employee-select', 'error']);

const { employees, totalRecords, getEmployees } = useEmployees();

const { params, onPage } = useDataTableParams([
    'EMPID', 'PAYTYPE', 'DEPT', 'FNAME', 'LNAME'
]);

const loadingFlags = useLoadingFlagsStore();

const openTimePeriodsList = ref(null);
const departmentsList = ref(null);

const selectedTimePeriod = ref(null);
const selectedDepartment = ref(null);

const firstNameFilter = ref(null);
const lastNameFilter = ref(null);

const selectedEmployee = ref(null);

onMounted(async function () {
    loadingFlags.add('fetchTimePeriodsAndDepartments');

    try {
        const timePeriodsResponse = await axios.get(route('api.time-periods.index', {
            filters: {
                PAYTYPE: {
                    value: ['WEEKLY', 'BIMONTHLY'],
                    matchMode: 'in',
                },
                STATUS: {
                    value: 'O',
                    matchMode: 'equals',
                }
            },
        }));
        openTimePeriodsList.value = timePeriodsResponse.data.data;

        const departmentsResponse = await axios.get(route('api.departments.index'));
        departmentsList.value = departmentsResponse.data.data.filter(department => department.DEPT != 'RESIGNED');
    } catch (e) {
        console.log(e);
        emit('error', 'An error occurred while initializing time periods/departments. Contact your administrator.');
    }

    loadingFlags.delete('fetchTimePeriodsAndDepartments');
});

function timePeriodFilterOnChange() {
    employees.value = null;
    emit('time-period-select', selectedTimePeriod.value);
}

function clearButtonOnClick() {
    selectedTimePeriod.value = null;
    selectedDepartment.value = null;
    employeeFirstNameFilter.value = "";
    employeeLastNameFilter.value = "";
    selectedEmployee.value = null;
    employees.value = null;
    emit('clear');
}

async function fetchEmployees() {
    loadingFlags.add('fetchEmployees');
    try {
        await getEmployees({
            ...params.value,
            filters: {
                PAYTYPE: {
                    value: selectedTimePeriod.value.PAYTYPE,
                    matchMode: 'equals',
                },
                DEPT: {
                    value: selectedDepartment.value?.DEPT,
                    matchMode: 'equals',
                },
                FNAME: {
                    value: firstNameFilter?.value,
                    matchMode: 'contains',
                },
                LNAME: {
                    value: lastNameFilter?.value,
                    matchMode: 'contains',
                },
            },
            hardFilters: {
                DEPT: {
                    value: 'RESIGNED',
                    matchMode: 'notEquals',
                },
            }
        });
    } catch (e) {
        emit('error', e.message);
    }
    loadingFlags.delete('fetchEmployees');
}

watch(selectedEmployee, () => {
    if(selectedEmployee.value) {
        emit('employee-select', selectedEmployee.value);
    }
})
</script>
