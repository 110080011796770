<template>
    <fieldset :class="class">
        <legend>
            <i class="icon ph-bold ph-archive-info me-2"></i>Legacy Barcodes
        </legend>
        <DataTable
            :loading="loadingFlags.has('fetchLegacyBarcodes')"
            lazy
            :value="legacyBarcodes"
            :total-records="totalRecords"
            paginator
            :rows="10"
            @page="(e) => { onPage(e); fetchLegacyBarcodes(); }"
            filter-display="row"
            v-model:filters="filters"
            @filter="tableOnFilter"
            sort-mode="multiple"
            @sort="(e) => { onSort(e); fetchLegacyBarcodes(); }"
            :pt="{ table: { class: ' table table-bordered table-hover' } }">
            <Column
                field="BARCODE_LEGACY"
                header="Legacy Barcode"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="BARCODE_NEW"
                header="New Barcode"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <template #footer>
                There are {{ totalRecords ?? 0 }} records.
            </template>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data.
                </div>
            </template>
        </DataTable>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useLegacyBarcodes } from "@/composables/data/legacyBarcodes";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    barcode: String,
    refreshFlag: null,
    class: String,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { params, filters, onPage, onFilter, onSort } = useDataTableParams([
    "BARCODE_LEGACY",
    "BARCODE_NEW",
]);

const { legacyBarcodes, totalRecords, getLegacyBarcodes } = useLegacyBarcodes();

const fetchTimeout = ref(null);

async function fetchLegacyBarcodes() {
    legacyBarcodes.value = null;

    if (!props.barcode) {
        return;
    }

    loadingFlags.add("fetchLegacyBarcodes");
    try {
        await getLegacyBarcodes({
            ...params.value,
            hardFilters: {
                BARCODE_NEW: {
                    value: props.barcode,
                    matchMode: 'equals',
                },
            },
        });
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchLegacyBarcodes");
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchLegacyBarcodes, 300);
}

watch(() => [props.barcode, props.refreshFlag], fetchLegacyBarcodes);
</script>
