<template>
    <div class="row g-3">
        <div class="col-md-8">
            <InventoryCheckCountsTableCard
                :location="props.location"
                v-model="selectedInventoryCheckCount">
                <template #footer>
                    <div class="text-end">
                        <hr class="my-3" />
                        <button type="button"
                            class="btn btn-primary me-2 mb-1"
                            :disabled="!selectedInventoryCheckCount"
                            @click="(_) => { generateCountsReport('counts-by-style-report'); }">
                            <i class="icon ph-bold ph-hash me-2"></i> Counts by Style
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary me-2 mb-1"
                            :disabled="!selectedInventoryCheckCount"
                            @click="(_) => { generateCountsReport('counts-by-sku-report'); }">
                            <i class="icon ph-bold ph-hash me-2"></i>Counts by SKU
                        </button>
                    </div>
                </template>
            </InventoryCheckCountsTableCard>
        </div>
        <div class="col-md-4 inventory-reports-totals">
            <fieldset class="mb-2">
                <legend>
                    <i class="icon ph-bold ph-archive-box me-2"></i>Total
                </legend>
                <div class="row g-3">
                    <div class="col">
                        <FormInput
                            type="number"
                            step=".02"
                            v-model="selectedInventoryCheckDetails.total.total"
                            label="Total Amount"
                            id-prefix="totalTotalAmount"
                            disabled
                        />
                    </div>
                    <div class="col">
                        <FormInput
                            type="number"
                            v-model="selectedInventoryCheckDetails.total.quantity"
                            label="Quantity"
                            id-prefix="totalQuantity"
                            disabled
                        />
                    </div>
                </div>
            </fieldset>
            <fieldset class="mb-2">
                <legend>
                    <i class="icon ph-bold ph-archive-box me-2"></i>Regular
                </legend>
                <div class="row g-3">
                    <div class="col">
                        <FormInput
                            type="number"
                            step=".02"
                            v-model="selectedInventoryCheckDetails.regular.total"
                            label="Total Amount"
                            id-prefix="regularTotalAmount"
                            disabled
                        />
                    </div>
                    <div class="col">
                        <FormInput
                            type="number"
                            v-model="selectedInventoryCheckDetails.regular.quantity"
                            label="Quantity"
                            id-prefix="regularQuantity"
                            disabled
                        />
                    </div>
                </div>
            </fieldset>
            <fieldset class="mb-2">
                <legend>
                    <i class="icon ph-bold ph-archive-box me-2"></i>Sales
                </legend>
                <div class="row g-3">
                    <div class="col">
                        <FormInput
                            type="number"
                            step=".02"
                            v-model="selectedInventoryCheckDetails.sales.total"
                            label="Total Amount"
                            id-prefix="salesTotalAmount"
                            disabled
                        />
                    </div>
                    <div class="col">
                        <FormInput
                            type="number"
                            v-model="selectedInventoryCheckDetails.sales.quantity"
                            label="Quantity"
                            id-prefix="salesQuantity"
                            disabled
                        />
                    </div>
                </div>
            </fieldset>
            <fieldset class="mb-2">
                <legend>
                    <i class="icon ph-bold ph-archive-box me-2"></i>Individual Reports
                </legend>
                <FormSelect
                    v-model="selectedReportType"
                    :options="['ALL', 'REGULAR', 'SALE']"
                    label="Report Type"
                    id-prefix="reportType"
                    class="mb-3"
                />
                <div class="form-check">
                    <input
                        type="checkbox"
                        v-model="includeLandedCost"
                        id="includeLandedCostFormInput"
                        class="form-check-input"
                    />
                    <label
                        for="includeLandedCostFormInput"
                        class="form-check-label"
                    >
                        Include Landed Costs
                    </label>
                </div>
                <div>
                    <hr class="my-3" />
                    <div class="text-end">
                        <button
                            type="button"
                            class="btn btn-primary"
                            :disabled="
                                !selectedInventoryCheckCount ||
                                !selectedReportType
                            "
                            @click="generateIndividualReport">
                            <i class="icon ph-bold ph-gear me-2"></i>Generate Report
                        </button>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import { useInventoryCheckCounts } from "@/composables/data/inventoryCheckCounts";
import InventoryCheckCountsTableCard from "../../utils/InventoryCheckCountsTableCard.vue";
import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";

const props = defineProps({
    location: null,
});

const { addFlashMessage } = useFlashMessages();

const { getInventoryCheckTotals } = useInventoryCheckCounts();

const selectedInventoryCheckCount = ref(null);

const selectedInventoryCheckDetails = ref({
    total: {
        quantity: null,
        total: null,
    },
    regular: {
        quantity: null,
        total: null,
    },
    sales: {
        quantity: null,
        total: null,
    },
});

const selectedReportType = ref(null);
const includeLandedCost = ref(false);

watch(
    () => props.location,
    async () => {
        inventoryCheckCounts.value = null;

        if (!props.location) return;

        try {
            await getInventoryCheckCounts(props.location);
        } catch (e) {
            addFlashMessage("ERROR", e.message);
        }
    }
);

watch(selectedInventoryCheckCount, async () => {
    selectedInventoryCheckDetails.value = {
        total: {
            quantity: null,
            total: null,
        },
        regular: {
            quantity: null,
            total: null,
        },
        sales: {
            quantity: null,
            total: null,
        },
    };

    if (!selectedInventoryCheckCount.value) return;

    try {
        selectedInventoryCheckDetails.value = await getInventoryCheckTotals(
            selectedInventoryCheckCount.value.LOCATION,
            selectedInventoryCheckCount.value.INVENTORY_DONE
        );
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
});

function generateCountsReport(countsReportName) {
    window.open(
        route(`api.inventory-checks.${countsReportName}.show`, {
            LOCATION: selectedInventoryCheckCount.value.LOCATION,
            INVENTORY_DONE: selectedInventoryCheckCount.value.INVENTORY_DONE,
        })
    );
}

function generateIndividualReport() {
    if (!includeLandedCost.value)
        window.open(
            route("api.inventory-checks.individual-report.show", {
                LOCATION: selectedInventoryCheckCount.value.LOCATION,
                INVENTORY_DONE:
                    selectedInventoryCheckCount.value.INVENTORY_DONE,
                TYPE: selectedReportType.value,
            })
        );
    else
        window.open(
            route(
                "api.inventory-checks.individual-report-with-landed-cost.show",
                {
                    LOCATION: selectedInventoryCheckCount.value.LOCATION,
                    INVENTORY_DONE:
                        selectedInventoryCheckCount.value.INVENTORY_DONE,
                    TYPE: selectedReportType.value,
                }
            )
        );
}
</script>
