<template>
    <fieldset :class="class">
        <legend>
            <i class="icon ph-bold ph-gear me-2"></i>Recon Generations
        </legend>
        <DataTable
            lazy
            :value="reconInfoGenerations"
            :total-records="totalRecords"
            paginator
            :rows="10"
            @page="(e) => { onPage(e); fetchReconInfoGenerations(); }"
            v-model:filters="filters"
            filter-display="row"
            @filter="tableOnFilter"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column
                field="location"
                header="Location"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="time_period"
                header="TIME_PERIOD"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="status"
                header="Status"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
                <template #body="{ data }">
                    <span v-if="data.status == 'PENDING'"
                        class="badge bg-secondary">
                        PENDING
                    </span>
                    <span  v-else-if="data.status == 'RUNNING'"
                        class="badge bg-primary">
                        RUNNING
                    </span>
                    <span v-else-if="data.status == 'DONE'"
                        class="badge bg-success">
                        DONE
                    </span>
                    <span v-else-if="data.status == 'FAILED'"
                        class="badge bg-danger">
                        FAILED
                    </span>
                </template>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data.
                </div>
            </template>
        </DataTable>
        <div class="text-end">
            <button type="button"
                class="btn btn-primary"
                @click="fetchReconInfoGenerations">
                <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Refresh
            </button>
        </div>
    </fieldset>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useReconInfoGenerations } from "@/composables/data/reconInfoGenerations";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    timePeriod: Object,
    refreshFlag: Boolean,
    class: String,
});

const { addFlashMessage } = useFlashMessages();

const { reconInfoGenerations, totalRecords, getReconInfoGenerations } =
    useReconInfoGenerations();

const { params, filters, onPage, onFilter } = useDataTableParams([
    "time_period",
    "location",
    "status",
]);

const fetchTimeout = ref(null);

async function fetchReconInfoGenerations() {
    reconInfoGenerations.value = null;

    try {
        await getReconInfoGenerations(params.value);
    } catch (e) {
        addFlashMessage("ERROR", e.message);
    }
}

onMounted(fetchReconInfoGenerations);

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchReconInfoGenerations, 300);
}

watch(() => props.refreshFlag, fetchReconInfoGenerations);
</script>
