<style scoped>
#importedHoursTableContainer {
    max-height: 400px;
    overflow-y: scroll;
}
</style>

<template>
    <div class="row g-3 mb-3">
        <div class="col-sm-6 col-md-4">
            <Card>
                <template #header>
                    Employee Hours NIDEKA
                </template>
                <p>Last File Uploaded: {{ lastFileUploadedName ?? 'No file yet.' }}</p>
                <div class="mb-3">
                    <label for="csvFileInput" class="form-label">Current File: </label>
                    <input type="file" required ref="csvFileInput" accept=".csv"
                        @change="csvFileOnChange"
                        id="csvFileInput" class="form-control" />
                </div>
                <template #footer>
                    <div class="text-end">
                        <button type="submit" class="btn btn-primary me-2"
                            @click="uploadCsvFile">
                            Import File
                        </button>
                        <button type="button" class="btn btn-outline-secondary"
                            @click="clearFlashMessages">
                            Dismiss Notifications
                        </button>
                    </div>
                </template>
            </Card>
        </div>
        <div class="col-sm-6 col-md-8">
            <Card>
                <template #header>
                    Import Errors
                </template>
                <DataTable :value="importErrors" paginator :rows="10"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }">
                    <Column field="status" header="Status">
                        <template #body="{ data }">
                            <span v-if="data.status == 'ERROR'" class="badge bg-danger">
                                Error
                            </span>
                        </template>
                    </Column>
                    <Column field="field" header="Field" />
                    <Column field="message" header="Message" />
                    <template #empty>
                        <div class="text-center">
                            No errors.
                        </div>
                    </template>
                </DataTable>
            </Card>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-md-4">
            <Card>
                <template #header>
                    Select Time Period
                </template>
                <FormSelect v-model="selectedPaytype"
                    label="Paytype" id-prefix="paytype"
                    :options="['BIMONTHLY', 'MONTHLY', 'WEEKLY']"
                    class="mb-3" />
                <TimePeriodTable show-from-current-date
                    :paytype="selectedPaytype ?? 'WEEKLY'"
                    :sort="[{ field: 'TIME_PERIOD', order: -1 }]"
                    :hide-properties="new Set(['STATUS'])"
                    v-model="selectedTimePeriod" />
            </Card>
        </div>
        <div class="col-md-8">
            <Card>
                <template #header>
                    Current Imported Hours
                </template>
                <DataTable
                    :loading="loadingFlags.has('fetchNidekaLogs')"
                    :value="nidekaLogs"
                    paginator
                    :rows="10"
                    :pt="{ table: { class: 'table table-bordered table-hover' }}">
                    <Column field="EMPID" header="Employee ID" />
                    <Column field="nideka_map.employee.EMPID" header="NIDEKA ID" />
                    <Column field="nideka_map.employee.FNAME" header="First Name" />
                    <Column field="nideka_map.employee.LNAME" header="Last Name" />
                    <Column field="DATE_TIME" header="Date/Time" />
                    <template #empty>
                        <div class="text-center">
                            No data yet.
                        </div>
                    </template>
                </DataTable>
                <template #footer>
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary"
                            @click="deleteImportedHours">
                            Reset Imported Hours
                        </button>
                    </div>
                </template>
            </Card>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useFlashMessages } from "@/composables/flashmessages";

import Card from "@/components/utils/Card.vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import TimePeriodTable from "@/components/utils/tables/TimePeriodTable.vue";

import axios from "axios";

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { addFlashMessage, clearFlashMessages } = useFlashMessages();

const selectedPaytype = ref(null);
const selectedTimePeriod = ref(null);

const csvFileInput = ref();
const csvFile = ref(null);

const lastFileUploadedName = ref(null);

const importErrors = ref(null);

const nidekaLogs = ref(null);

function csvFileOnChange(event) {
    const file = event.target.files[0];
    csvFile.value = file;
}

onMounted(async() => {
    loadingFlags.add('fetchLatestCSV');

    try {
        const latestCSVResponse = await axios.get(
            route('api.nideka-csvs.latest'));
        lastFileUploadedName.value = latestCSVResponse.data.data.filename;
    } catch(e) {
        console.log(e);
        toasts.add('ERROR', 'Error', 'Error while fetching latest CSV information: ' + (e.response?.data.message ?? e.message));
    }

    loadingFlags.delete('fetchLatestCSV');
});

async function uploadCsvFile() {
    importErrors.value = null;

    loadingFlags.add('uploadCsvFile');
    try {
        const formData = new FormData();
        formData.append("csv", csvFile.value);
        const response = await axios.post(route('api.nideka-csvs.store'), formData);
        toasts.add('SUCCESS', 'Success', response.data.message);
        if(response.data.errors) {
            importErrors.value = Object.entries(response.data.errors).reduce((importErrors, [field, errors]) => {
                return [
                    ...importErrors,
                    ...errors.map(error => ({
                        status: 'ERROR',
                        field: field,
                        message: error
                    }))
                ];
            }, []);
        }
        csvFileInput.value.value = null;
        csvFile.value = null;
        lastFileUploadedName.value = response.data.data.filename;
        fetchNidekaLogs();
    } catch(e) {
        console.log(e);
        if(e.response) {
            if(e.response.status == 413)
                toasts.add('ERROR', 'Error', 'File is too large.');
            else {
                toasts.add('ERROR', 'Error', e.response.data.message);
                if(e.response.data.errors)
                    Object.entries(e.response.data.errors).forEach(([field, errors]) => {
                        errors.forEach(error => {
                            addFlashMessage('ERROR', error);
                        });
                    });
            }
        } else {
            toasts.add('ERROR', 'Error', 'Error while submitting CSV. ' +
                'Please contact your administrator.');
        }
    }
    loadingFlags.delete('uploadCsvFile');
}

async function fetchNidekaLogs() {
    nidekaLogs.value = null;

    if(!selectedTimePeriod.value) {
        return;
    }

    loadingFlags.add('fetchNidekaLogs');
    try {
        const nidekaLogsResponse = await axios.get(route('api.nideka-logs.index', {
            TIME_PERIOD: selectedTimePeriod.value.TIME_PERIOD
        }));
        nidekaLogs.value = nidekaLogsResponse.data.data;
    } catch(e) {
        console.log(e);
        toasts.add('ERROR', 'Error', 'Error while fetching logs: ' + (e.response?.data.message ?? e.message));
    }
    loadingFlags.delete('fetchNidekaLogs');
}

watch(selectedTimePeriod, fetchNidekaLogs);

async function deleteImportedHours() {
    if(prompt("Are you sure? Enter 'DELETE' to continue.") !== 'DELETE')
        return;

    loadingFlags.add('deleteImportedHours');
    try {
        const response = await axios.delete(route('api.nideka-logs.all.destroy'));
        toasts.add('SUCCESS', 'Success', response.data.message);
        fetchNidekaLogs();
    } catch(e) {
        console.log(e);
        toasts.add('ERROR', 'Error', 'Error while clearing logs: ' + (e.response?.data.message ?? e.message));
    }
    loadingFlags.delete('deleteImportedHours');
}
</script>
