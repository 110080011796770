<template>
    <fieldset :class="class">
        <legend>
            <i class="icon ph-bold ph-archive-box me-2"></i>Inventory Checks
        </legend>
        <DataTable
            :loading="loadingFlags.has('fetchInventoryChecksOfBarcode')"
            lazy
            :value="inventoryChecks"
            :total-records="totalRecords"
            paginator
            :rows="10"
            @page="(e) => { onPage(e); fetchInventoryChecksOfBarcode(); }"
            filter-display="row"
            v-model:filters="filters"
            @filter="tableOnFilter"
            sort-mode="multiple"
            @sort="(e) => { onSort(e); fetchInventoryChecksOfBarcode(); }"
            :pt="{ table: { class: ' table table-bordered table-hover' } }">
            <Column
                field="INVENTORY_DONE"
                header="Inventory Done"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search by Inventory Done"
                    />
                </template>
            </Column>
            <Column
                field="LOCATION"
                header="Location"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search by Location"
                    />
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    <div class="text-center py-2">
                        <i class="icon ph-bold ph-database me-2"></i>No data.
                    </div>
                </div>
            </template>
        </DataTable>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useInventoryChecks } from "@/composables/data/inventoryChecks";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    barcode: String,
    refreshFlag: null,
    class: String,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { params, filters, onPage, onFilter, onSort } = useDataTableParams([
    "INVENTORY_DONE",
    "LOCATION",
]);

const { inventoryChecks, totalRecords, getInventoryChecksOfBarcode } =
    useInventoryChecks();

const fetchTimeout = ref(null);

async function fetchInventoryChecksOfBarcode() {
    inventoryChecks.value = null;

    if (!props.barcode) {
        return;
    }

    loadingFlags.add("fetchInventoryChecksOfBarcode");
    try {
        await getInventoryChecksOfBarcode(props.barcode, params.value);
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchInventoryChecksOfBarcode");
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchInventoryChecksOfBarcode, 700);
}

watch(() => [props.barcode, props.refreshFlag], fetchInventoryChecksOfBarcode);
</script>
