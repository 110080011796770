<template>
    <fieldset class="mb-3">
        <legend>
            <i class="icon ph-bold ph-sliders me-2"></i>Filters
        </legend>
        <div class="row mb-3">
            <div class="col-md-4">
                <div class="row">
                    <div class="col-xl-9 col-lg-8">
                        <FormInput
                            type="text"
                            v-model="filters['PREV_LOC'].value"
                            label="Prev. Loc."
                            id-prefix="prevLoc"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            input-class="form-control-sm"
                            label-class="col-xl-4 col-lg-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-lg-7 col-12"
                        />
                    </div>
                    <div class="col-xl-3 col-lg-4 text-end">
                        <button type="button"
                            class="btn btn-sm btn-primary mb-1 me-2 px-2"
                            @click="(_) => {
                                isSelectLocationPopupOpen = true;
                                locationPopupFieldToSet = 'PREV_LOC';
                            }">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-9 col-lg-8">
                        <FormInput
                            type="text"
                            v-model="filters['comment.LOCATION'].value"
                            label="Location"
                            id-prefix="commentLocation"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            input-class="form-control-sm"
                            label-class="col-xl-4 col-lg-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-lg-7 col-12"
                        />
                    </div>
                    <div class="col-xl-3 col-lg-4 text-end">
                        <button type="button"
                            class="btn btn-sm btn-primary mb-1 me-2 px-2"
                            @click="(_) => {
                                isSelectLocationPopupOpen = true;
                                locationPopupFieldToSet = 'comment.LOCATION';
                            }">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-9 col-lg-8">
                        <FormInput
                            type="text"
                            v-model="filters['COMMENT_ID'].value"
                            label="Comment ID"
                            id-prefix="commentId"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            input-class="form-control-sm"
                            label-class="col-xl-4 col-lg-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-lg-7 col-12"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-9 col-lg-8">
                        <FormInput
                            type="text"
                            v-model="filters['STYLE'].value"
                            label="Style"
                            id-prefix="style"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            input-class="form-control-sm"
                            label-class="col-xl-4 col-lg-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-lg-7 col-12"
                        />
                    </div>
                    <div class="col-xl-3 col-lg-4 text-end">
                        <button type="button"
                            class="btn btn-sm btn-primary mb-1 me-2 px-2"
                            @click="(_) => {
                                isSelectStylePopupOpen = true;
                            }">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-4 pe-md-5">
                <div class="row">
                    <div class="col-xl-12">
                        <FormInput
                            type="text"
                            v-model="filters['DATE'].value"
                            label="Date"
                            id-prefix="date"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            input-class="form-control-sm"
                            label-class="col-xl-4 col-lg-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-lg-7 col-12"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12">
                        <FormInput
                            type="text"
                            v-model="filters['SERIAL'].value"
                            label="Serial"
                            id-prefix="serial"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            input-class="form-control-sm"
                            label-class="col-xl-4 col-lg-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-lg-7 col-12"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12">
                        <FormInput
                            type="number"
                            v-model="filters['SEQ_NO'].value"
                            label="Seq. No."
                            id-prefix="seqNo"
                            class="mb-1"
                            is-horizontal
                            input-class="form-control-sm"
                            label-class="col-xl-4 col-lg-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-lg-7 col-12"
                        />
                    </div>
                    <div class="col-xl-8 offset-xl-4 form-check mb-md-1 mb-2">
                        <input class="form-check-input ms-0 me-2"
                            type="checkbox"
                            v-model="showOnlyLatestLogs"
                            id="showOnlyLatestLogsCheckbox"
                        />
                        <label class="form-check-label"
                            for="showOnlyLatestLogsCheckbox">
                            Show only Latest (Max SEQ_NO)
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row">
                    <div class="col-xl-9 col-lg-8">
                        <FormInput
                            type="text"
                            v-model="filters['COLOR'].value"
                            label="Color"
                            id-prefix="color"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            input-class="form-control-sm"
                            label-class="col-xl-4 col-lg-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-lg-7 col-12"
                        />
                    </div>
                    <div class="col-xl-3 col-lg-4 text-end">
                        <button type="button"
                            class="btn btn-sm btn-primary mb-1 me-2 px-2"
                            @click="(_) => {
                                isSelectColorPopupOpen = true;
                            }">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-9 col-lg-8">
                        <FormInput
                            type="number"
                            step=".01"
                            v-model="filters['PRICE'].value"
                            label="Price"
                            id-prefix="price"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            input-class="form-control-sm"
                            label-class="col-xl-4 col-lg-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-lg-7 col-12"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-9 col-lg-8">
                        <FormInput
                            type="text"
                            v-model="filters['EMPID'].value"
                            label="Employee ID"
                            id-prefix="empid"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            input-class="form-control-sm"
                            label-class="col-xl-4 col-lg-5 label-sm align-items-center"
                            input-container-class="col-xl-8 col-lg-7 col-12"
                        />
                    </div>
                    <div class="col-xl-3 col-lg-4 text-end">
                        <button type="button"
                            class="btn btn-sm btn-primary mb-1 me-2 px-2"
                            @click="(_) => {
                                isSelectEmployeePopupOpen = true;
                            }">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-end">
            <button type="button"
                class="btn btn-primary btn-sm"
                @click="tableOnFilter">
                <i class="icon ph-bold ph-sliders me-2"></i>Apply Filters
            </button>
        </div>
    </fieldset>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-table me-2"></i>Inventory Table
        </legend>
        <DataTable
            :loading="isLoading"
            lazy
            scrollable
            :value="inventoryRecords"
            :total-records="inventoryTotalRecords"
            paginator
            :rows="10"
            @page="(e) => {
                onPage(e);
                fetchInventoryRecords();
            }"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column header="Barcode" :pt="{ bodyCell: { class: 'barcode' } }">
                <template #body="{ data }">
                    {{ constructBarcode(data.STYLE, data.DATE, data.SERIAL) }}
                </template>
            </Column>
            <Column field="STYLE" header="Style" />
            <Column field="DATE" header="Date" />
            <Column field="SERIAL" header="Serial" />
            <Column field="SEQ_NO" header="Seq. No." />
            <Column field="COLOR" header="Color" />
            <Column field="COMMENT_ID" header="Comment ID" />
            <Column field="comment.COMMENT_TIMESTAMP" header="Comment Timestamp" />
            <Column field="comment.COMMENT_TEXT" header="Comment ID" />
            <Column field="comment.LOCATION" header="Location" />
            <Column field="PREV_LOC" header="Prev. Loc." />
            <Column field="PRICE" header="Price" />
            <Column field="DISCOUNT" header="Discount" />
            <Column field="EMPID" header="Employee ID" />
            <Column field="employee.FNAME" header="Employee First Name" />
            <Column field="employee.LNAME" header="Employee Last Name" />
            <Column field="CREATED_BY" header="Created By" />
            <template #loading>
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                Fetching inventory records...
            </template>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data.
                </div>
            </template>
            <template #footer>
                {{ inventoryTotalRecords ?? 0 }} total records
            </template>
        </DataTable>
    </fieldset>
    <SelectLocationPopup
        :is-open="isSelectLocationPopupOpen"
        @select="
            (selectedLocation) => {
                filters[locationPopupFieldToSet].value = selectedLocation.LOCATION;
                locationPopupFieldToSet = null;
                isSelectLocationPopupOpen = false;
            }
        "
        @close="(_) => { isSelectLocationPopupOpen = false; }"
        @error="
            (message) => {
                addFlashMessage('ERROR', message);
                scrollToTop();
            }
        "
    />
    <SelectStyle
        :is-open="isSelectStylePopupOpen"
        @select="
            (selectedStyle) => {
                filters['STYLE'].value = selectedStyle.STYLE;
                isSelectStylePopupOpen = false;
            }
        "
        @close="(_) => { isSelectStylePopupOpen = false; }"
        @error="
            (message) => {
                addFlashMessage('ERROR', message);
                scrollToTop();
            }
        "
    />
    <SelectColor
        :is-open="isSelectColorPopupOpen"
        @select="
            (selectedColor) => {
                filters['COLOR'].value = selectedColor.COLOR;
                isSelectColorPopupOpen = false;
            }
        "
        @close="(_) => { isSelectColorPopupOpen = false; }"
        @error="
            (message) => {
                addFlashMessage('ERROR', message);
                scrollToTop();
            }
        "
    />
    <SelectEmployee
        :is-open="isSelectEmployeePopupOpen"
        :on-select-employee="
            (selectedEmployee) => {
                filters['EMPID'].value = selectedEmployee.EMPID;
                isSelectEmployeePopupOpen = false;
            }
        "
        :on-close="(_) => { isSelectEmployeePopupOpen = false; }"
    />
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useFlashMessages } from "@/composables/flashmessages";
import { useInventory } from "@/composables/data/inventory";
import FormInput from "@/components/utils/FormInput.vue";
import SelectLocationPopup from "@/components/utils/popups/SelectLocationPopup.vue";
import SelectStyle from "@/components/utils/style/SelectStyle.vue";
import SelectColor from "@/components/utils/SelectColor.vue";
import SelectEmployee from "@/components/utils/SelectEmployee.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import scrollToTop from "@/utils/scrollToTop";
import { constructBarcode } from "@/utils/barcodes";

const { addFlashMessage } = useFlashMessages();

const { params, filters, onPage, onFilter } = useDataTableParams([
    "STYLE",
    "DATE",
    "SERIAL",
    "SEQ_NO",
    "COLOR",
    "COMMENT_ID",
    "comment.COMMENT_TIMESTAMP",
    "comment.COMMENT_TEXT",
    "PRICE",
    "comment.LOCATION",
    "PREV_LOC",
    "EMPID",
    "employee.FNAME",
    "employee.LNAME",
]);

const { inventoryRecords, inventoryTotalRecords, getInventoryReportRecords } =
    useInventory();

const isLoading = ref(false);

const fetchTimeout = ref(null);

const isSelectLocationPopupOpen = ref(false);
const isSelectStylePopupOpen = ref(false);
const isSelectColorPopupOpen = ref(false);
const isSelectEmployeePopupOpen = ref(false);

const locationPopupFieldToSet = ref(null); // Either PREV_LOC or comment.LOCATION

const showOnlyLatestLogs = ref(false);

async function fetchInventoryRecords() {
    inventoryRecords.value = null;

    isLoading.value = true;
    try {
        await getInventoryReportRecords({
            ...params.value,
            only_latest: showOnlyLatestLogs.value,
        });
    } catch (e) {
        addFlashMessage("ERROR", e.message);
        scrollToTop();
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchInventoryRecords, 300);
}

onMounted(() => {
    for (const filterField of Object.keys(filters.value)) {
        filters.value[filterField].matchMode = "equals";
    }
});
</script>
