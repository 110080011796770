<template>
    <div class="row">
        <div class="col-lg-6 col-md-9">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-timer me-2"></i> Interval Reports
                </legend>
                <div class="row">
                    <div class="col-xl-9">
                        <FormInput
                            type="date"
                            v-model="startDate"
                            label="Start Date"
                            id-prefix="startDate"
                            :errors="errors.start_date"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            label-class="col-xl-4 col-md-4 col-12 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-8 col-12"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-9">
                        <FormInput
                            type="date"
                            v-model="endDate"
                            label="End Date"
                            id-prefix="endDate"
                            :errors="errors.end_date"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            label-class="col-xl-4 col-md-4 col-12 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-8 col-12"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-9">
                        <FormInput
                            type="number"
                            v-model="empid"
                            disabled
                            label="Employee ID"
                            id-prefix="empid"
                            :errors="errors.empid"
                            class="mb-md-1 mb-2"
                            is-horizontal
                            label-class="col-xl-4 col-md-4 col-12 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-8 col-12"
                        />
                    </div>
                    <div class="col-xl-3 text-end">
                        <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            @click="
                                (_) => {
                                    isSelectEmployeePopupOpen = true;
                                }
                            ">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                        </button>
                    </div>
                </div>
                <hr class="my-3" />
                <div class="text-end">
                    <button type="button"
                        class="btn btn-primary btn-sm px-1 mb-1 me-1"
                        :disabled="!startDate || !endDate"
                        @click="generateIntervalReport('dow')">
                        <i class="icon ph-bold ph-calendar me-2"></i>Days of Work
                    </button>
                    <button type="button"
                        class="btn btn-primary btn-sm px-1 mb-1"
                        :disabled="!startDate || !endDate"
                        @click="generateIntervalReport('incentives')">
                        <i class="icon ph-bold ph-gift me-2"></i> Incentive
                    </button>
                </div>
            </fieldset>
        </div>
    </div>
    <SelectEmployee
        :is-open="isSelectEmployeePopupOpen"
        :on-close="
            (_) => {
                isSelectEmployeePopupOpen = false;
            }
        "
        :on-select-employee="
            (employee) => {
                isSelectEmployeePopupOpen = false;
                empid = employee.EMPID;
            }
        "
    />
</template>

<script setup>
import { ref } from 'vue';
import FormInput from '@/components/utils/FormInput.vue';
import SelectEmployee from '@/components/utils/SelectEmployee.vue';

const startDate = ref(null);
const endDate = ref(null);
const empid = ref(null);

const errors = ref({});

const isSelectEmployeePopupOpen = ref(false);

function generateIntervalReport(type) {
    window.open(route('api.employees.interval-report.show', {
            START_DATE: startDate.value,
            END_DATE: endDate.value,
            EMPID: empid.value,
            TYPE: type
        }));
}
</script>
