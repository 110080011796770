export function useCompanyEventTypes() {
    const companyEventTypes = [
        {
            value: 'R-HOLIDAY',
            label: 'Regular Holiday',
        },
        {
            value: 'S-HOLIDAY',
            label: 'Special Holiday',
        },
        {
            value: 'COMPANY',
            label: 'Company Event',
        },
    ];
    
    return { companyEventTypes };
}
