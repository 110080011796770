<template>
    <div class="row g-3 my-3">
        <div class="col-xl-4">
            <fieldset class="h-100">
                <legend>
                    <i class="icon ph-bold ph-map-pin me-2"></i>Select a Location
                </legend>
                <LocationSelectTableCard v-model="selectedLocation" />
            </fieldset>
        </div>
        <div class="col-xl-8">
            <InventoryCheckDescriptionsTableCard
                :location-filter="selectedLocation?.LOCATION"
                :id-filter="idFilter"
                v-model="selectedInventoryCheckDescription">
                <template #footer>
                    <div class="text-end">
                        <button type="button"
                            class="btn btn-primary me-1"
                            :disabled="!selectedInventoryCheckDescription"
                            @click="exportInventoryChecks">
                            <i class="icon ph-bold ph-upload me-2"></i>Export Inventory Checks
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            :disabled="!selectedLocation"
                            @click="(_) => { isCreatingInventoryCheckDescription = true; }">
                            <i class="icon ph-bold ph-plus-circle me-2"></i>Create Inventory Check Description
                        </button>
                    </div>
                </template>
            </InventoryCheckDescriptionsTableCard>
        </div>
    </div>

    <UploadBarcodesToInventoryCheckTableCards
        :inventory-check-description="selectedInventoryCheckDescription"
        @success="
            (
                savedBarcodesReturned,
                savedNoBarcodesReturned,
                duplicateBarcodesReturned
            ) => {
                addFlashMessage('SUCCESS', 'Successfully saved barcodes.');
                if (
                    duplicateBarcodesReturned &&
                    duplicateBarcodesReturned.length > 0
                )
                    addFlashMessage(
                        'WARNING',
                        'There are DUPLICATE barcodes, please check.'
                    );
                savedBarcodes = savedBarcodesReturned;
                savedNoBarcodes = savedNoBarcodesReturned;
                duplicateBarcodes = duplicateBarcodesReturned;
                scrollToTop();
            }
        "
        @error="
            (message) => {
                addFlashMessage('ERROR', message);
                scrollToTop();
            }
        "
        class="mb-3"
    />

    <div class="row g-3 mt-1">
        <div class="col-xl-4">
            <ResultBarcodesTableCard
                :barcodes="savedBarcodes"
                title="Saved Barcodes"
                class="h-100"
            />
        </div>
        <div class="col-xl-4">
            <ResultNoBarcodesTableCard
                :noBarcodes="savedNoBarcodes"
                class="h-100"
            />
        </div>
        <div class="col-xl-4">
            <ResultBarcodesTableCard
                :barcodes="duplicateBarcodes"
                title="Duplicate Barcodes"
                class="h-100"
            />
        </div>
    </div>

    <CreateInventoryCheckDescriptionPopup
        :location="selectedLocation?.LOCATION"
        :is-open="isCreatingInventoryCheckDescription"
        @success="
            (inventoryCheckDescription) => {
                selectedInventoryCheckDescription = inventoryCheckDescription;
                idFilter = inventoryCheckDescription.id;
                isCreatingInventoryCheckDescription = false;
                addFlashMessage(
                    'SUCCESS',
                    'Successfully saved inventory check description.'
                );
                scrollToTop();
            }
        "
        @error="
            (message) => {
                addFlashMessage('ERROR', message);
                scrollToTop();
            }
        "
        @close="
            (_) => {
                isCreatingInventoryCheckDescription = false;
            }
        "
    />
</template>

<script setup>
import { ref } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import LocationSelectTableCard from "@/components/utils/LocationSelectTableCard.vue";
import InventoryCheckDescriptionsTableCard from "./InventoryCheckDescriptionsTableCard.vue";
import CreateInventoryCheckDescriptionPopup from "./CreateInventoryCheckDescriptionPopup.vue";
import UploadBarcodesToInventoryCheckTableCards from "./UploadBarcodesToInventoryCheckTableCards.vue";
import ResultBarcodesTableCard from "./ResultBarcodesTableCard.vue";
import ResultNoBarcodesTableCard from "./ResultNoBarcodesTableCard.vue";
import scrollToTop from "@/utils/scrollToTop";

const { addFlashMessage } = useFlashMessages();

const selectedLocation = ref(null);

const idFilter = ref(null);

const selectedInventoryCheckDescription = ref(null);

const isCreatingInventoryCheckDescription = ref(false);

const savedBarcodes = ref(null);
const savedNoBarcodes = ref(null);
const duplicateBarcodes = ref(null);

function exportInventoryChecks() {
    window.open(
        route("api.inventory-check-descriptions.report.show", {
            inventory_check_description:
                selectedInventoryCheckDescription.value.id,
        })
    );
}
</script>
