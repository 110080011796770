<template>
    <div class="row g-3 mb-4">
        <div class="col-md-3">
            <FormInput
                type="date"
                v-model="customFilters.DATE_CREATED_FROM"
                label="Date Created (From)"
                id-prefix="dateCreatedFrom"
                @change="refreshSamplesData"
            />
        </div>
        <div class="col-md-3">
            <FormInput
                type="date"
                v-model="customFilters.DATE_CREATED_TO"
                label="Date Created (To)"
                id-prefix="dateCreatedTo"
                @change="refreshSamplesData"
            />
        </div>
    </div>
    <DataTable
        :loading="isLoading"
        :value="samples"
        lazy
        :total-records="totalRecords"
        paginator
        :rows="10"
        filter-display="row"
        v-model:filters="filters"
        v-model:selection="sample"
        selection-mode="single"
        @page="
            (e) => {
                onPage(e);
                refreshSamplesData();
            }
        "
        @filter="
            (e) => {
                onFilter(e);
                refreshSamplesData();
            }
        "
        sort-mode="multiple"
        @sort="
            (e) => {
                onSort(e);
                refreshSamplesData();
            }
        "
        :pt="{ table: { class: 'table table-bordered table-hover' } }">
        <Column field="SAMPLE_ID"
            header="ID"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search by ID"
                />
            </template>
        </Column>
        <Column
            field="LEGACY_SAMPNO"
            header="Legacy No."
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search by Legacy No."
                />
            </template>
        </Column>
        <Column
            field="DESCRIPTION"
            header="Description"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search by Description"
                />
            </template>
        </Column>
        <Column
            field="ACCOUNT_ID"
            header="Account"
            filter-field="relation_account_ACCOUNT_NAME"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search by Account"
                />
            </template>
            <template #body="slotProps">
                {{ slotProps.data.account?.ACCOUNT_NAME }}
            </template>
        </Column>
        <Column
            field="DATE_CREATED"
            header="Date Created"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search by Date Created"
                />
            </template>
        </Column>
        <Column
            field="DATE_FINISHED"
            header="Date Finished"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search by Date Finished"
                />
            </template>
        </Column>
        <Column
            field="MEASURED_BY"
            header="Measured By"
            filter-field="relation_measuredBy_FNAME"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search by Measured By"
                />
            </template>
            <template #body="slotProps">
                {{ slotProps.data.measured_by?.FNAME }}
                {{ slotProps.data.measured_by?.LNAME }}
            </template>
        </Column>
        <Column
            field="SAMPLE_MAKER"
            header="Sample Maker"
            filter-field="relation_sampleMaker_FNAME"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search by Sample Maker"
                />
            </template>
            <template #body="slotProps">
                {{ slotProps.data.sample_maker?.FNAME }}
                {{ slotProps.data.sample_maker?.LNAME }}
            </template>
        </Column>
        <Column
            field="CREATED_BY"
            header="Created By"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search By Created By"
                />
            </template>
        </Column>
        <template #empty>
            <div class="text-center py-3">
                <i class="icon ph-bold ph-database me-2"></i>No samples.
            </div>
        </template>
    </DataTable>
    <div class="text-end mt-3">
        <hr class="my-4" />
        <button class="btn btn-primary me-2 mb-1"
            :disabled="!sample"
            @click="viewSampleDetails">
            <i class="icon ph-bold ph-info me-2"></i> Detail
        </button>
        <button type="button"
            class="btn btn-primary me-2 mb-1"
            @click="(_) => viewSampleApprovalList()">
            <i class="icon ph-bold ph-check me-2"></i>See Apprv. List
        </button>
        <button type="button"
            class="btn btn-primary me-2 mb-1"
            :disabled="!sample"
            @click="(_) => viewSampleApprovalList(sample.SAMPLE_ID)">
            <i class="icon ph-bold ph-target me-2"></i>See Specific
        </button>
        <button type="button"
            class="btn btn-primary me-2 mb-1"
            :disabled="!sample"
            data-bs-toggle="modal"
            data-bs-target="#sampleCostingFormModal">
            <i class="icon ph-bold ph-calculator me-2"></i>Apprv/Costing
        </button>
        <button class="btn btn-primary me-2 mb-1"
            @click="
                (_) => {
                    isBulkImageUploadPopupOpen = true;
                }
            ">
            <i class="icon ph-bold ph-cloud-arrow-up me-2"></i>Bulk Upload Images
        </button>
    </div>
    <SampleCostingFormModal :sample-id="sample?.SAMPLE_ID" />
    <BulkImageUploadPopup
        :is-open="isBulkImageUploadPopupOpen"
        @close="
            (_) => {
                isBulkImageUploadPopupOpen = false;
            }
        "
        @error="
            (message) => {
                isBulkImageUploadPopupOpen = false;
                emit('error', message);
            }
        "
    />
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useSamples } from "@/composables/data/samples";
import SampleCostingFormModal from "./SampleCostingFormModal.vue";
import FormInput from "@/components/utils/FormInput.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import { FilterMatchMode } from "primevue/api";
import BulkImageUploadPopup from "./BulkImageUploadPopup.vue";

const props = defineProps({
    selectedSample: Object,
    refreshFlag: null,
    class: String,
});

const emit = defineEmits(["error"]);

const { params, filters, onPage, onFilter, onSort } = useDataTableParams(
    [
        "SAMPLE_ID",
        "LEGACY_SAMPNO",
        "DESCRIPTION",
        "ACCOUNT_ID",
        "DATE_CREATED",
        "DATE_FINISHED",
        "MEASURED_BY",
        "SAMPLE_MAKER",
        "CREATED_BY",
        "QUANTITY",
    ],
    {
        include_relations: "account,measuredBy,sampleMaker",
    },
    {
        SAMPLE_ID: { value: null, matchMode: FilterMatchMode.EQUALS },
        relation_account_ACCOUNT_NAME: {
            value: null,
            matchMode: FilterMatchMode.CONTAINS,
        },
        relation_measuredBy_FNAME: {
            value: null,
            matchMode: FilterMatchMode.CONTAINS,
            or: {
                LNAME: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
        },
        relation_sampleMaker_FNAME: {
            value: null,
            matchMode: FilterMatchMode.CONTAINS,
            or: {
                LNAME: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
        },
    }
);

const customFilters = ref({
    DATE_CREATED_FROM: null,
    DATE_CREATED_TO: null,
});

const sample = defineModel();

const { samples, totalRecords, getSamples } = useSamples();

const isBulkImageUploadPopupOpen = ref(false);

const isLoading = ref(false);

async function refreshSamplesData() {
    // Filters for FNAMEs for related fields shall be applied as well to the corresponding LNAME
    // For both measured by and sample maker
    filters.value.relation_measuredBy_FNAME.or.LNAME.value =
        filters.value.relation_measuredBy_FNAME.value;
    filters.value.relation_sampleMaker_FNAME.or.LNAME.value =
        filters.value.relation_sampleMaker_FNAME.value;

    isLoading.value = true;
    try {
        await getSamples({
            ...params.value,
            customFilters: customFilters.value,
        });
    } catch (e) {
        emit("error", e.message);
    }
    isLoading.value = false;
}

watch(() => props.refreshFlag, refreshSamplesData);

onMounted(refreshSamplesData);

function viewSampleDetails() {
    window.open(
        route("activity.sampling-maintenance.detail", {
            SAMPLE_ID: sample.value.SAMPLE_ID,
        })
    );
}

function viewSampleApprovalList(sampleId) {
    if (!sampleId)
        window.open(route("activity.sampling-maintenance.approval-list"));
    else
        window.open(
            route("activity.sampling-maintenance.approval-list", {
                SAMPLE_ID: sampleId,
            })
        );
}
</script>
