<style scoped>
#jobordersTableContainer {
    height: 350px;
    overflow-y: auto;
}

#jobordersTableContainer table thead {
    position: sticky;
    top: 0;
    background-color: var(--bs-card-bg);
}
</style>

<template>
    <div class="row mb-3">
        <div class="col-md-6">
            <form @submit.prevent="saveJoborder">
                <Card>
                    <div class="row mb-3">
                        <div class="col-md-3">
                            <FormInput type="text" label="ID"
                                v-model="form.JOBORDER_ID" id-prefix="paytype"
                                :is-horizontal="true" :errors="errors.JOBORDER_ID"
                                disabled @keydown-enter="focusnext('endDateFormInput')" />
                        </div>
                        <div class="col-md-3 text-md-end">
                            <button type="button" class="btn btn-primary"
                                :disabled="!selectedJoborder"
                                @click="extendJoborder">
                                Extend Till
                            </button>
                        </div>
                        <div class="col-md-6">
                            <FormInput type="date" label="Extend Till" hide-label
                                v-model="form.END_DATE" id-prefix="endDate"
                                :errors="errors.END_DATE"
                                @keydown-enter="focusnext('jonoFormInput')" />
                        </div>
                    </div>
                    <FormInput type="text" label="JONO"
                        v-model="form.JONO" id-prefix="jono"
                        :is-horizontal="true" class="mb-3"
                        :errors="errors.JONO"
                        @keydown-enter="focusnext('styleFormInput')" />
                    <FormInput type="text" label="STYLE"
                        v-model="form.STYLE" id-prefix="style"
                        :is-horizontal="true" class="mb-3"
                        :errors="errors.STYLE"
                        @keydown-enter="focusnext('glIdFormInput')" />
                    <div class="row mb-3">
                        <div class="col-sm-3">
                            <label for="glIdFormInput" class="col-form-label">
                                GL ID
                            </label>
                        </div>
                        <div class="col-sm-2">
                            <FormInput type="text" label="GL ID" hide-label
                                v-model="form.GL_ID" id-prefix="glId"
                                :errors="errors.GL_ID"
                                :form-text="`${selectedRelationships.glAccount?.CLASS ?? ''} - ${selectedRelationships.glAccount?.ACCTNAME ?? ''}`"
                                @keydown-enter="focusnext('selectGLButton')" />
                        </div>
                        <div class="col-sm-3">
                            <button type="button" class="btn btn-primary" id="selectGLButton"
                                data-bs-toggle="modal" data-bs-target="#selectGLModal"
                                @keydown.enter.prevent="focusnext('qtyFormInput')">
                                Select GL
                            </button>
                        </div>
                    </div>
                    <FormInput type="number" label="QTY"
                        v-model="form.QTY" id-prefix="qty"
                        :is-horizontal="true" class="mb-3"
                        :errors="errors.QTY"
                        @keydown-enter="_ => {
                            if(!selectedJoborder) {
                                focusnext('insertButton');
                            } else {
                                focusnext('updateButton');
                            }
                        }" />
                    <template #footer>
                        <div class="text-end">
                            <button type="submit" class="btn btn-primary me-2"
                                :disabled="selectedJoborder"
                                id="insertButton">
                                Insert
                            </button>
                            <button type="button" class="btn btn-primary me-2"
                                :disabled="!selectedJoborder"
                                @click="updateJoborder">
                                Update
                            </button>
                            <button type="button" class="btn btn-outline-secondary"
                                @click="reset">
                                Clear
                            </button>
                        </div>
                    </template>
                </Card>
            </form>
        </div>
    </div>
    <Card>
        <template #header>
            Joborders
        </template>
        <JobordersTable v-model="selectedJoborder"
            :refresh-flag="tableRefreshFlag"
            :joborder-id="joborderIdFilterForTable" />
    </Card>

    <SelectGLModal @select-gl="glAccount => {
        form.GL_ID = glAccount.GL_ID;
        selectedRelationships.glAccount = glAccount;
    }" />
</template>

<script setup>
import { ref, watch } from "vue";

import { useToastsStore } from "@/stores/toasts";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useJoborders } from "@/composables/data/joborders";

import Card from '@/components/utils/Card.vue';
import FormInput from '@/components/utils/FormInput.vue';

import SelectGLModal from "./SelectGLModal.vue";

import JobordersTable from "@/components/utils/tables/JobordersTable.vue";

import focusnext from "@/utils/focusnext";

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const tableRefreshFlag = ref(false);

const { postJoborder, putJoborder, postJoborderExtension } = useJoborders();

const selectedJoborder = ref(null);

const form = ref({
    JOBORDER_ID: null,
    JONO: null,
    STYLE: null,
    QTY: null,
    GL_ID: null,
    START_DATE: null,
    END_DATE: null
});

const selectedRelationships = ref({});

const errors = ref({});

const joborderIdFilterForTable = ref(null); // When inserting a joborder, set this filter so table can focus to it

watch(selectedJoborder, () => {
    form.value = Object.assign({
        JOBORDER_ID: null,
        JONO: null,
        STYLE: null,
        QTY: null,
        GL_ID: null,
        START_DATE: null,
        END_DATE: null
    }, selectedJoborder.value);
    errors.value = {};
});

function reset() {
    selectedJoborder.value = null;
    form.value = {
        JOBORDER_ID: null,
        JONO: null,
        STYLE: null,
        QTY: null,
        GL_ID: null,
        START_DATE: null,
        END_DATE: null
    };
    errors.value = {};
    joborderIdFilterForTable.value = null;
}

async function saveJoborder() {
    errors.value = {};

    loadingFlags.add('saveJoborder');
    try {
        const newJoborder = await postJoborder(form.value);
        toasts.add('SUCCESS', 'Success', 'Successfully created joborder.');
        selectedJoborder.value = newJoborder;
        tableRefreshFlag.value = !tableRefreshFlag.value;
        joborderIdFilterForTable.value = selectedJoborder.value.JOBORDER_ID;
    } catch(e) {
        errors.value = e.errors ?? {};
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('saveJoborder');
}

async function updateJoborder() {
    errors.value = {};

    loadingFlags.add('updateJoborder');
    try {
        const newJoborder = await putJoborder(selectedJoborder.value.JOBORDER_ID, form.value);
        toasts.add('SUCCESS', 'Success', 'Successfully updated joborder.');
        selectedJoborder.value = newJoborder;
        tableRefreshFlag.value = !tableRefreshFlag.value;
    } catch(e) {
        errors.value = e.errors ?? {};
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('updateJoborder');
}

async function extendJoborder() {
    errors.value = {};

    loadingFlags.add('extendJoborder');
    try {
        const newJoborder = await postJoborderExtension(selectedJoborder.value.JOBORDER_ID, form.value.END_DATE);
        toasts.add('SUCCESS', 'Success', 'Successfully extended joborder.');
        selectedJoborder.value = newJoborder;
        tableRefreshFlag.value = !tableRefreshFlag.value;
    } catch(e) {
        errors.value = e.errors ?? {};
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('extendJoborder');
}
</script>
