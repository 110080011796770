<template>
    <form @submit.prevent="insertJoborderNew">
        <p v-if="!account.ACCOUNT_ID" class="text-primary">Please select an account before inserting a JONO.</p>
        <div class="row mb-md-1 mb-2">
            <div class="col">
                <FormInput
                    type="text"
                    v-model="joborder.JONO"
                    label="JONO"
                    id-prefix="jono"
                    input-class="jono-input-min-width form-control-sm"
                    :errors="errors.JONO"
                    is-horizontal
                    disabled
                    label-class="col-xl-3 col-md-5 label-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                />
            </div>
            <div class="col-auto mt-auto-new-sp-btn">
                <button type="button"
                    class="btn btn-primary"
                    @click="newSP">
                    <i class="icon ph-bold ph-plus-circle me-2"></i>
                    New SP
                </button>
            </div>
        </div>

        <FormInput
            type="text"
            v-model="joborder.DESCRIPTION"
            label="DESCRIPTION"
            id-prefix="description"
            :errors="errors.DESCRIPTION"
            is-horizontal
            class="mb-md-1 mb-2"
            label-class="col-xl-3 col-md-5 label-sm"
            input-class="form-control-sm"
            input-container-class="col-xl-9 col-md-7 col-12"
        />
        <FormInput
            type="date"
            v-model="joborder.DATE_DUE"
            label="DATE_DUE"
            id-prefix="dateDue"
            :errors="errors.DATE_DUE"
            is-horizontal
            class="mb-md-1 mb-2"
            label-class="col-xl-3 col-md-5 label-sm"
            input-class="form-control-sm"
            input-container-class="col-xl-9 col-md-7 col-12"
        />
        <FormInput
            type="text"
            v-model="joborder.PO_NUM"
            label="PO_NUM"
            id-prefix="poNum"
            :errors="errors.PO_NUM"
            is-horizontal
            class="mb-md-1 mb-2"
            label-class="col-xl-3 col-md-5 label-sm"
            input-class="form-control-sm"
            input-container-class="col-xl-9 col-md-7 col-12"
        />

        <div class="text-end">
            <hr class="my-4" />
            <button type="submit"
                class="btn btn-success me-2"
                :disabled="!account.ACCOUNT_ID || isUpdating">
                <i class="icon ph-bold ph-file-plus me-2"></i>Insert
            </button>
            <button type="button"
                class="btn btn-primary"
                :disabled="!account.ACCOUNT_ID || !isUpdating"
                @click="updateJoborderNew">
                <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Update
            </button>
        </div>
    </form>
</template>

<script setup>
import { ref, watch } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import { useJobordersNew } from "@/composables/data/jobordersNew";
import FormInput from "@/components/utils/FormInput.vue";
import scrollToTop from "@/utils/scrollToTop";
import moment from "moment";

const props = defineProps({
    selectedJoborderFromTable: Object,
    account: Object,
});

const emit = defineEmits(["create-or-update"]);

const { addFlashMessage } = useFlashMessages();

const { getNextJONO, postJoborderNew, putJoborderNew } = useJobordersNew();

const errors = ref({});

const joborder = ref({
    JONO: null,
    ACCOUNT_ID: null,
    DESCRIPTION: null,
    DATE_DUE: null,
    PO_NUM: null,
});

const isUpdating = ref(false);

async function newSP() {
    try {
        const nextJONO = await getNextJONO("SP", moment().format("YYYY"));
        joborder.value = {
            JONO: nextJONO,
            ACCOUNT_ID: props.account.ACCOUNT_ID,
            DESCRIPTION: null,
            DATE_DUE: null,
            PO_NUM: null,
        };
        isUpdating.value = false;
    } catch (e) {
        addFlashMessage("ERROR", e.message);
        scrollToTop();
    }
}

async function insertJoborderNew() {
    errors.value = {};
    try {
        joborder.value = await postJoborderNew(joborder.value);
        addFlashMessage("SUCCESS", "Successfully created joborder.");
        emit("create-or-update", joborder.value);
        isUpdating.value = true;
    } catch (e) {
        addFlashMessage("ERROR", e.message);
        errors.value = e.errors;
    }
    scrollToTop();
}

async function updateJoborderNew() {
    errors.value = {};
    try {
        joborder.value = await putJoborderNew(
            joborder.value.JONO,
            joborder.value
        );
        addFlashMessage("SUCCESS", "Successfully updated joborder.");
        emit("create-or-update", joborder.value);
    } catch (e) {
        addFlashMessage("ERROR", e.message);
        errors.value = e.errors;
    }
    scrollToTop();
}

watch(
    () => props.selectedJoborderFromTable,
    () => {
        joborder.value = Object.assign({}, props.selectedJoborderFromTable);
        isUpdating.value = joborder.value.JONO != null;
    }
);
</script>
