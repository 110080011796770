<template>
    <DataTable :loading="isLoading"
        lazy
        :value="styles"
        :total-records="totalRecords"
        paginator
        :rows="10"
        @page="
            (e) => {
                onPage(e);
                fetchStyles();
            }
        "
        filter-display="row"
        v-model:filters="filters"
        @filter="tableOnFilter"
        sort-mode="multiple"
        @sort="
            (e) => {
                onSort(e);
                fetchStyles();
            }
        "
        v-model:selection="style"
        selection-mode="single"
        :pt="{ table: { class: 'table table-bordered table-hover' } }">
        <Column field="STYLE"
            header="Style"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search by Style"
                />
            </template>
        </Column>
        <template #empty>
            <div class="text-center py-3">
                <span v-if="!location">
                    <i class="icon ph-bold ph-map-pin me-2"></i>Please choose a location.
                </span>
                <span v-else>
                    <i class="icon ph-bold ph-database me-2"></i>No data.
                </span>
            </div>
        </template>
    </DataTable>
    <hr class="my-3" />
    <div class="text-end">
        <button type="button"
            class="btn btn-primary px-1 me-1 mb-1 btn-sm"
            :disabled="!location"
            @click="
                (_) => {
                    generateCurrentInventoryReport(
                        'current-inventory-detail-report'
                    );
                }
            ">
            <i class="icon ph-bold ph-file-text me-2"></i>Current Inventory (Detail)
        </button>
        <button type="button"
            class="btn btn-primary px-1 me-1 mb-1 btn-sm"
            :disabled="!location"
            @click="
                (_) => {
                    generateCurrentInventoryReport(
                        'current-inventory-summary-report'
                    );
                }
            ">
            <i class="icon ph-bold ph-note me-2"></i>Current Inventory (Summary)
        </button>
        <button type="button"
            class="btn btn-primary px-1 me-1 mb-1 btn-sm"
            :disabled="!location"
            @click="
                (_) => {
                    generateCurrentInventoryReport(
                        'latest-prices-summary-report'
                    );
                }
            ">
            <i class="icon ph-bold ph-currency-dollar me-2"></i> Latest Prices (Summary)
        </button>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useStyles } from '@/composables/data/styles';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    location: String
});

const emit = defineEmits(['error']);

const { params, filters, onPage, onFilter, onSort } = useDataTableParams(['STYLE']);

const { styles, totalRecords, getStylesInLocation } = useStyles();

const style = ref(null);

const isLoading = ref(false);

const fetchTimeout = ref(null);

async function fetchStyles() {
    styles.value = null;
    if (!props.location) {
        return;
    }

    isLoading.value = true;
    try {
        await getStylesInLocation(props.location, params.value);
    } catch(e) {
        emit('error', e.message);
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    style.value = null;
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchStyles, 300);
}

watch(() => props.location, fetchStyles);

function generateCurrentInventoryReport(reportResource) {
    window.open(route(`api.locations.${reportResource}.show`, {
            location: props.location,
            STYLE: style.value?.STYLE
        }));
}
</script>
