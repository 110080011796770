<style scoped>
.table th,
td {
    padding: 0.2em;
}

.hours-date-field,
.hours-type-field,
.hours-time-field,
.hours-total-field,
.hours-ot-field,
.hours-late-field,
.hours-absent-field,
.hours-irreg-time-field {
    min-width: 4.5em;
}

.form-label,
.hours-date-field {
    font-size: 0.7em;
}

.hours-time-field input,
.hours-type-field select,
.hours-total-field input,
.hours-ot-field input,
.hours-late-field input,
.hours-absent-field input,
.hours-irreg-lunch-field input,
.hours-am-field input,
.hours-lunch-field input,
.hours-noon-field input,
.hours-exp-field input,
.hours-excess-field input {
    font-size: 0.8em;
}

.hours-irreg-lunch-field,
.hours-am-field,
.hours-lunch-field,
.hours-noon-field,
.hours-exp-field,
.hours-excess-field {
    min-width: 4em;
}

.issues-messages-alerts,
.alerts-to-resolve {
    max-height: 300px;
    overflow-y: scroll;
}
</style>

<template>
    <div class="modal fade" :id="id" tabindex="-1">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalSearchLabel">
                        Enter Employee Times
                    </h5>
                    <button type="button" class="btn-close" ref="closeButton"
                        data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row justify-content-center mb-3">
                        <div class="col-md-6 col-lg-4 col-xl-3">
                            <div class="row g-1">
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-sm-6 text-end">
                                            <label class="form-label">Employee ID</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" :value="employee?.EMPID" class="form-control form-control-sm"
                                                disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 text-center">
                                    <span>{{ employee?.FNAME }} {{ employee?.LNAME }}</span>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-sm-6 text-end">
                                            <label class="form-label">Time Period</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" :value="timePeriod?.TIME_PERIOD" class="form-control form-control-sm"
                                                disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-sm-6 text-end">
                                            <label class="form-label">Regular Leave</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" :value="totals.LEAVE" class="form-control form-control-sm"
                                                disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-sm-6 text-end">
                                            <label class="form-label">Regular Day</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" :value="totals.REGULAR" class="form-control form-control-sm"
                                                disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-sm-6 text-end">
                                            <label class="form-label">Regular OT</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" :value="totals.OVERTIME" class="form-control form-control-sm"
                                                disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-sm-6 text-end">
                                            <label class="form-label">Total Absent/Late</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" :value="totals.LATE_TOTAL" class="form-control form-control-sm"
                                                disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-sm-6 text-end">
                                            <label class="form-label">SP Holiday OT</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" :value="totals.OVERTIME_SPECIAL" class="form-control form-control-sm"
                                                disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-sm-6 text-end">
                                            <label class="form-label">Special Holiday</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" :value="totals.SPECIAL" class="form-control form-control-sm"
                                                disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-sm-6 text-end">
                                            <label class="form-label">RG Holiday OT</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" :value="totals.OVERTIME_HOLIDAY" class="form-control form-control-sm"
                                                disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-sm-6 text-end">
                                            <label class="form-label">Regular Holiday</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" :value="totals.HOLIDAY" class="form-control form-control-sm"
                                                disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-sm-6 text-end">
                                            <label class="form-label">Total Absent</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" :value="totals.ABSENT" class="form-control form-control-sm"
                                                disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-sm-6 text-end">
                                            <label class="form-label">Holiday Holiday</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" :value="totals.HOLIDAY_HOLIDAY" class="form-control form-control-sm"
                                                disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-sm-6 text-end">
                                            <label class="form-label">Total Late</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" :value="totals.LATE" class="form-control form-control-sm"
                                                disabled>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-xl-3">
                            <table class="table table-sm table-bordered mb-3">
                                <thead>
                                    <tr>
                                        <th colspan="4" class="text-center">Legend</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colspan="2"></td>
                                        <td class="text-center">P</td>
                                        <td>Present</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">R</td>
                                        <td>Regular</td>
                                        <td class="text-center">A</td>
                                        <td>Absent</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">H</td>
                                        <td>Regular Holiday</td>
                                        <td class="text-center">D</td>
                                        <td>Day-Off</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">S</td>
                                        <td>Special Holiday</td>
                                        <td class="text-center">LW</td>
                                        <td>Leave w/ Pay</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">HH</td>
                                        <td>Holiday Holiday</td>
                                        <td class="text-center">LO</td>
                                        <td>Leave w/o Pay</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table table-sm table-bordered">
                                <thead>
                                    <tr>
                                        <th colspan="4" class="text-center">Regular Shift</th>
                                    </tr>
                                    <tr>
                                        <th class="text-center">Start Time</th>
                                        <th class="text-center">End Time</th>
                                        <th class="text-center">Lunch Break (mins.)</th>
                                        <th class="text-center">Total Minutes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input type="text" id="regularShiftStartInput"
                                                v-model="regularShiftStart"
                                                class="form-control form-control-sm"
                                                @focus="e => onTimeFocus(e, null, 'REGULAR_SHIFT_START')"
                                                @blur="e => onTimeBlur(e, null, 'REGULAR_SHIFT_START')"
                                                @keydown.enter.prevent="focusNextInput('regularShiftEndInput')">
                                        </td>
                                        <td>
                                            <input type="text" id="regularShiftEndInput"
                                                v-model="regularShiftEnd"
                                                class="form-control form-control-sm"
                                                @focus="e => onTimeFocus(e, null, 'REGULAR_SHIFT_END')"
                                                @blur="e => onTimeBlur(e, null, 'REGULAR_SHIFT_END')"
                                                @keydown.enter.prevent="focusNextInput('lunchBreakMinutesInput')">
                                        </td>
                                        <td>
                                            <input type="number" id="lunchBreakMinutesInput"
                                                v-model="lunchBreakMinutes"
                                                class="form-control form-control-sm"
                                                placeholder="Enter in mins."
                                                @keydown.enter.prevent="focusNextInput('in1_0')">
                                        </td>
                                        <td>
                                            <input type="number" v-model="regularShiftTotalMinutes"
                                                class="form-control form-control-sm" disabled>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="text-center mb-3">
                                <button class="btn btn-sm btn-primary me-2 mb-2"
                                    :disabled="isPopulateDisabled"
                                    @click="populate">
                                    Populate
                                </button>
                                <button class="btn btn-sm btn-primary me-2 mb-2"
                                    @click="resetButtonOnClick">
                                    Reset
                                </button>
                                <button class="btn btn-sm btn-primary me-2 mb-2"
                                    id="estimateButton"
                                    @click="estimateButtonOnClick">
                                    Estimate
                                </button>
                                <button class="btn btn-sm btn-primary me-2 mb-2"
                                    @click="noCoffeeBreakButtonOnClick">
                                    No Coffee Break
                                </button>
                                <button type="button" class="btn btn-sm btn-outline-secondary me-2 mb-2"
                                    data-bs-dismiss="modal">
                                    Close Form
                                </button>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-xl-6">
                            <FlashMessageAlerts :messages="errorMessages" :dismissible="true"
                                class="error-message-alerts mb-3"/>
                            <h3>Issues to Solve</h3>
                            <div class="form-text mb-4">
                                Issues that need to be addressed will
                                show up in this section.
                            </div>
                            <FlashMessageAlerts :messages="issuesMessages" class="issues-messages-alerts mb-3" />
                            <h3>Prompts for Fixing</h3>
                            <div class="form-text">
                                When there are problems that can easily be resolved,
                                confirmation prompts will show up here.
                            </div>
                            <FlashMessageAlerts :messages="alertsToResolve" class="alerts-to-resolve"/>
                        </div>
                    </div>
                    <div class="hours-table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th class="text-center">Date</th>
                                    <th></th>
                                    <th></th>
                                    <th class="text-center">In</th>
                                    <th class="text-center">Out</th>
                                    <th class="text-center">In</th>
                                    <th class="text-center">Out</th>
                                    <th class="text-center">In</th>
                                    <th class="text-center">Out</th>
                                    <th class="text-center">Total</th>
                                    <th class="text-center">OT</th>
                                    <th></th>
                                    <th class="text-center">Late</th>
                                    <th class="text-center">Absent</th>
                                    <th colspan="3" class="text-center">Irregular Shift</th>
                                    <th class="text-center">AM</th>
                                    <th class="text-center">Lunch</th>
                                    <th class="text-center">Noon</th>
                                    <th class="text-center">EXP</th>
                                    <th class="text-center">AM</th>
                                    <th class="text-center">Lunch</th>
                                    <th class="text-center">Noon</th>
                                    <th class="text-center">Excess</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="employee !== null" v-for="date, index in datesInTimePeriod" :key="date">
                                    <template v-if="date in employeeTimes">
                                        <td class="hours-date-field">
                                            {{ moment(date).format("MMM DD | ddd") }}
                                        </td>
                                        <td class="hours-type-field">
                                            <select class="form-select form-select-sm" v-model="employeeTimes[date].DAY_TYPE"
                                                tabindex="-1">
                                                <option v-for="choice in dayTypeChoices" :value="choice.abbr">
                                                    {{ choice.abbr }} | {{ choice.verbose }}
                                                </option>
                                            </select>
                                        </td>
                                        <td class="hours-type-field">
                                            <select class="form-select form-select-sm" v-model="employeeTimes[date].EMPLOYEE_STATUS"
                                                tabindex="-1" @change="employeeStatusSelectOnChange(date)">
                                                <option v-for="choice in employeeDayStatusChoices" :value="choice.abbr">
                                                    {{ choice.abbr }} | {{ choice.verbose }}
                                                </option>
                                            </select>
                                        </td>
                                        <td class="hours-time-field">
                                            <input type="text" :id="'in1_' + index"
                                                class="form-control form-control-sm"
                                                v-model="employeeTimes[date].IN1"
                                                :ref="'timeInput' + index"
                                                :disabled="timeInputsDisabledFlags[date]"
                                                @focus="e => onTimeFocus(e, employeeTimes[date], 'IN1')"
                                                @blur="e => onTimeBlur(e, employeeTimes[date], 'IN1')"
                                                @keydown.enter.prevent="focusNextInput('out1_' + index)"
                                                :tabindex="1 + (index * 6)">
                                        </td>
                                        <td class="hours-time-field">
                                            <input type="text" :id="'out1_' + index"
                                                class="form-control form-control-sm"
                                                v-model="employeeTimes[date].OUT1"
                                                :disabled="timeInputsDisabledFlags[date]"
                                                @focus="e => onTimeFocus(e, employeeTimes[date], 'OUT1')"
                                                @blur="e => onTimeBlur(e, employeeTimes[date], 'OUT1')"
                                                @keydown.enter.prevent="focusNextInput('in2_' + index)"
                                                :tabindex="2 + (index * 6)">
                                        </td>
                                        <td class="hours-time-field">
                                            <input type="text" :id="'in2_' + index"
                                                class="form-control form-control-sm"
                                                v-model="employeeTimes[date].IN2"
                                                :disabled="timeInputsDisabledFlags[date]"
                                                @focus="e => onTimeFocus(e, employeeTimes[date], 'IN2')"
                                                @blur="e => onTimeBlur(e, employeeTimes[date], 'IN2')"
                                                @keydown.enter.prevent="_ => {
                                                    if(coffeeBreakInputsDisabledFlags[date])
                                                        focusNextInput('out3_' + index);
                                                    else
                                                        focusNextInput('out2_' + index);
                                                }"
                                                :tabindex="3 + (index * 6)">
                                        </td>
                                        <td class="hours-time-field">
                                            <input type="text" :id="'out2_' + index"
                                                class="form-control form-control-sm"
                                                v-model="employeeTimes[date].OUT2"
                                                :disabled="timeInputsDisabledFlags[date] || coffeeBreakInputsDisabledFlags[date]"
                                                @focus="e => onTimeFocus(e, employeeTimes[date], 'OUT2')"
                                                @blur="e => onTimeBlur(e, employeeTimes[date], 'OUT2')"
                                                @keydown.enter.prevent="focusNextInput('in3_' + index)"
                                                :tabindex="4 + (index * 6)">
                                        </td>
                                        <td class="hours-time-field">
                                            <input type="text" :id="'in3_' + index"
                                                class="form-control form-control-sm"
                                                v-model="employeeTimes[date].IN3"
                                                :disabled="timeInputsDisabledFlags[date] || coffeeBreakInputsDisabledFlags[date]"
                                                @focus="e => onTimeFocus(e, employeeTimes[date], 'IN3')"
                                                @blur="e => onTimeBlur(e, employeeTimes[date], 'IN3')"
                                                @keydown.enter.prevent="focusNextInput('out3_' + index)"
                                                :tabindex="5 + (index * 6)">
                                        </td>
                                        <td class="hours-time-field">
                                            <input type="text" :id="'out3_' + index"
                                                class="form-control form-control-sm"
                                                v-model="employeeTimes[date].OUT3"
                                                :disabled="timeInputsDisabledFlags[date]"
                                                @focus="e => onTimeFocus(e, employeeTimes[date], 'OUT3')"
                                                @blur="e => onTimeBlur(e, employeeTimes[date], 'OUT3')"
                                                @keydown.enter.prevent="e => {
                                                    if(index == datesInTimePeriod.length - 1)
                                                        focusNextInput('irregIn_' + 0);
                                                    else
                                                        focusNextInput('in1_' + (index + 1));
                                                }"
                                                :tabindex="6 + (index * 6)">
                                        </td>
                                        <td class="hours-total-field">
                                            <input type="text" class="form-control form-control-sm"
                                                v-model="employeeTimes[date].TOTAL"
                                                placeholder="Total" disabled>
                                        </td>
                                        <td class="hours-ot-field">
                                            <input type="text" class="form-control form-control-sm"
                                                v-model="employeeTimes[date].OT"
                                                placeholder="Overtime" disabled>
                                        </td>
                                        <td>
                                            <input type="checkbox" class="form-check-input"
                                                v-model="employeeTimes[date].CH"
                                                tabindex="-1">
                                        </td>
                                        <td class="hours-late-field">
                                            <input type="text" class="form-control form-control-sm"
                                                v-model="employeeTimes[date].LATE"
                                                placeholder="Late" disabled>
                                        </td>
                                        <td class="hours-absent-field">
                                            <input type="text" class="form-control form-control-sm"
                                                v-model="employeeTimes[date].ABSENT"
                                                placeholder="Absent" disabled>
                                        </td>
                                        <td class="hours-irreg-time-field">
                                            <input type="text" :id="'irregIn_' + index" class="form-control form-control-sm"
                                                v-model="employeeTimes[date].START_IRREG_SHIFT"
                                                @focus="e => onTimeFocus(e, employeeTimes[date], 'START_IRREG_SHIFT')"
                                                @blur="e => onTimeBlur(e, employeeTimes[date], 'START_IRREG_SHIFT')"
                                                @keydown.enter.prevent="focusNextInput('irregOut_' + index)"
                                                :tabindex="(6 * datesInTimePeriod.length) + (1 + index * 2)">
                                        </td>
                                        <td class="hours-irreg-time-field">
                                            <input type="text" :id="'irregOut_' + index" class="form-control form-control-sm"
                                                v-model="employeeTimes[date].END_IRREG_SHIFT"
                                                @focus="e => onTimeFocus(e, employeeTimes[date], 'END_IRREG_SHIFT')"
                                                @blur="e => onTimeBlur(e, employeeTimes[date], 'END_IRREG_SHIFT')"
                                                @keydown.enter.prevent="focusNextInput('irregLunch_' + index)"
                                                :tabindex="(6 * datesInTimePeriod.length) + (2 + index * 2)">
                                        </td>
                                        <td class="hours-irreg-lunch-field">
                                            <input type="number" :id="'irregLunch_' + index" class="form-control form-control-sm"
                                                v-model="employeeTimes[date].LUNCH_IRREG_SHIFT"
                                                @focus="event => onTimeFocus(event)"
                                                @blur="event => onTimeBlur(event)"
                                                @keydown.enter.prevent="e => {
                                                    if(index == datesInTimePeriod.length - 1)
                                                        focusNextInput('estimateButton');
                                                    else
                                                        focusNextInput('irregIn_' + (index + 1));
                                                }"
                                                tabindex="-1"
                                                placeholder="Irreg. Lunch Break">
                                        </td>
                                        <td class="hours-am-field">
                                            <input type="text" class="form-control form-control-sm"
                                                v-model="employeeTimes[date].AM1"
                                                placeholder="AM" disabled>
                                        </td>
                                        <td class="hours-lunch-field">
                                            <input type="text" class="form-control form-control-sm"
                                                v-model="employeeTimes[date].LUNCH1"
                                                placeholder="Lunch" disabled>
                                        </td>
                                        <td class="hours-noon-field">
                                            <input type="text" class="form-control form-control-sm"
                                                v-model="employeeTimes[date].NOON1"
                                                placeholder="Noon" disabled>
                                        </td>
                                        <td class="hours-exp-field">
                                            <input type="text" class="form-control form-control-sm"
                                                v-model="employeeTimes[date].EXP"
                                                placeholder="EXP" disabled>
                                        </td>
                                        <td class="hours-am-field">
                                            <input type="text" class="form-control form-control-sm"
                                                v-model="employeeTimes[date].AM2"
                                                placeholder="AM" disabled>
                                        </td>
                                        <td class="hours-lunch-field">
                                            <input type="text" class="form-control form-control-sm"
                                                v-model="employeeTimes[date].LUNCH2"
                                                placeholder="Lunch" disabled>
                                        </td>
                                        <td class="hours-noon-field">
                                            <input type="text" class="form-control form-control-sm"
                                                v-model="employeeTimes[date].NOON2"
                                                placeholder="Noon" disabled>
                                        </td>
                                        <td class="hours-excess-field">
                                            <input type="text" class="form-control form-control-sm"
                                                :class="{ 'bg-warning': employeeTimes[date].EXCESS > 0 }"
                                                v-model="employeeTimes[date].EXCESS"
                                                placeholder="Excess" disabled>
                                        </td>
                                        <td>
                                            <input type="checkbox" class="form-check-input"
                                                v-model="employeeTimes[date].CH_EXCESS"
                                                tabindex="-1">
                                        </td>
                                    </template>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';

import { useSalesLeaves } from '@/composables/data/salesLeaves';

import axios from 'axios';
import moment from 'moment';

import FlashMessageAlerts from '@/components/utils/FlashMessageAlerts.vue';

import focusNextInput from '@/utils/focusnext';

const props = defineProps({
    employee: Object,
    timePeriod: Object,
    datesInTimePeriod: Array,
    id: String,
    resetCoffeeBreakFlag: null,
    class: String
});

const emit = defineEmits(['populate']);

const closeButton = ref(null);

const loadingFlags = useLoadingFlagsStore();

const errorMessages = ref([]);
const issuesMessages = ref([]);
const alertsToResolve = ref([]);

const dayTypeChoices = [
    { abbr: "R", verbose: "Regular" },
    { abbr: "H", verbose: "Holiday" },
    { abbr: "S", verbose: "Special Holiday" },
    { abbr: "HH", verbose: "Holiday Holiday" }
];
const employeeDayStatusChoices = [
    { abbr: "P", verbose: "Present" },
    { abbr: "A", verbose: "Absent" },
    { abbr: "D", verbose: "Day-Off" },
    { abbr: "LW", verbose: "Leave w/ Pay" },
    { abbr: "LO", verbose: "Leave w/o Pay" },
    { abbr: "OD", verbose: "Office Day" }
];

const employeeStat = ref("");

const UNDERTIME = 420;
const REGULAR = 480;

const regularShiftId = ref(null);
const regularShiftStart = ref("");
const regularShiftEnd = ref("");
const lunchBreakMinutes = ref("60");
const regularShiftTotalMinutes = computed(() => {
    if (regularShiftStart.value === "" || regularShiftEnd.value === "" ||
        lunchBreakMinutes.value === "")
        return "";

    const startShiftTime = moment(regularShiftStart.value, 'hh:mm A');
    const endShiftTime = moment(regularShiftEnd.value, 'hh:mm A');
    return parseInt(moment.duration(endShiftTime.diff(startShiftTime)).asMinutes()) -
        parseInt(lunchBreakMinutes.value);
});

const employeeTimes = ref({});
const totals = ref({
    REGULAR: 0,
    OVERTIME: 0,
    HOLIDAY: 0,
    HOLIDAY_HOLIDAY: 0,
    OVERTIME_HOLIDAY: 0,
    SPECIAL: 0,
    OVERTIME_SPECIAL: 0,
    LEAVE: 0,
    LATE: 0,
    ABSENT: 0,
    LATE_TOTAL: 0
});

const timeInputsDisabledFlags = ref({});
const coffeeBreakInputsDisabledFlags = ref({});

const hasEstimated = ref(false);

const isPopulateDisabled = computed(() => {
    return !hasEstimated.value || issuesMessages.value.length > 0 ||
        alertsToResolve.value.length > 0;
});

const { postSalesLeavesMultiple } = useSalesLeaves();

watch(() => props.employee, async () => {
    if(!props.employee)
        return;

    hasEstimated.value = false;
    issuesMessages.value = [];
    alertsToResolve.value = [];

    totals.value.REGULAR = 0;
    totals.value.OVERTIME = 0;
    totals.value.HOLIDAY = 0;
    totals.value.HOLIDAY_HOLIDAY = 0;
    totals.value.OVERTIME_HOLIDAY = 0;
    totals.value.SPECIAL = 0;
    totals.value.OVERTIME_SPECIAL = 0;
    totals.value.LEAVE = 0;
    totals.value.LATE = 0;
    totals.value.ABSENT = 0;
    totals.value.LATE_TOTAL = 0;

    loadingFlags.add('fetchEmployeeShiftAndSavedTimes');

    try {
        const currentRegularShiftResponse = await axios.get(
            route('api.employees.hours.time-period.regular-shift.index',
                [props.employee.EMPID, props.timePeriod.TIME_PERIOD]));
        const currentRegularShift = currentRegularShiftResponse.data.data;
        regularShiftId.value = currentRegularShift.id;
        regularShiftStart.value = moment(currentRegularShift.START_SHIFT, "HH:mm:ss.SSSSSS").format('HH:mm');
        regularShiftEnd.value = moment(currentRegularShift.END_SHIFT, "HH:mm:ss.SSSSSS").format('HH:mm');
        lunchBreakMinutes.value = currentRegularShift.LUNCH_BREAK;
        employeeStat.value = currentRegularShift.STATUS;
    } catch(e) {
        if(e.response) {
            if(e.response.status === 404) {
                regularShiftId.value = null;
                regularShiftStart.value = "";
                regularShiftEnd.value = "";
                lunchBreakMinutes.value = 60;
                employeeStat.value = props.employee.STATUS;
                if(employeeStat.value === "F")
                    employeeStat.value = "R";
            } else {
                console.log(e);
                errorMessages.value.push({
                    type: "ERROR",
                    content: `An error occurred while fetching regular shifts.
                        Please contact your administrator.<br/>
                        Details: ${e}`
                });
            }
        }
    }

    try {
        const currentEmployeeTimesResponse = await axios.get(
            route('api.employees.hours.time-period.hours-details.index',
                [props.employee.EMPID, props.timePeriod.TIME_PERIOD]));
        const currentEmployeeTimes = currentEmployeeTimesResponse.data.data;

        if(currentEmployeeTimes.length > 0) {
            employeeTimes.value = currentEmployeeTimes.reduce((employeeTimes, employeeDateHours, index) => {
                return {
                    ...employeeTimes,
                    [props.datesInTimePeriod[index]]: {
                        id: employeeDateHours.id,
                        DAY_TYPE: employeeDateHours.TYPE_HOLIDAY,
                        EMPLOYEE_STATUS: employeeDateHours.TYPE_ABSENT,
                        IN1: employeeDateHours.IN_1 ?
                            moment(employeeDateHours.IN_1, "HH:mm:ss.SSSSSS").format('HH:mm') :
                            "",
                        OUT1: employeeDateHours.OUT_1 ?
                            moment(employeeDateHours.OUT_1, "HH:mm:ss.SSSSSS").format('HH:mm') :
                            "",
                        IN2: employeeDateHours.IN_2 ?
                            moment(employeeDateHours.IN_2, "HH:mm:ss.SSSSSS").format('HH:mm') :
                            "",
                        OUT2: employeeDateHours.OUT_2 ?
                            moment(employeeDateHours.OUT_2, "HH:mm:ss.SSSSSS").format('HH:mm') :
                            "",
                        IN3: employeeDateHours.IN_3 ?
                            moment(employeeDateHours.IN_3, "HH:mm:ss.SSSSSS").format('HH:mm') :
                            "",
                        OUT3: employeeDateHours.OUT_3 ?
                            moment(employeeDateHours.OUT_3, "HH:mm:ss.SSSSSS").format('HH:mm') :
                            "",
                        CH: Boolean(employeeDateHours.WITH_OT),
                        START_IRREG_SHIFT: employeeDateHours.START_IRREG_SHIFT ?
                            moment(employeeDateHours.START_IRREG_SHIFT, "HH:mm:ss.SSSSSS").format('HH:mm') :
                            "",
                        END_IRREG_SHIFT: employeeDateHours.END_IRREG_SHIFT ?
                            moment(employeeDateHours.END_IRREG_SHIFT, "HH:mm:ss.SSSSSS").format('HH:mm') :
                            "",
                        LUNCH_IRREG_SHIFT: employeeDateHours.IRREG_LUNCH ?
                            moment(employeeDateHours.IRREG_LUNCH, "HH:mm:ss.SSSSSS").format('HH:mm') :
                            null,
                        TREG: 0,
                        TOVR: 0,
                        TOTAL: 0,
                        OT: 0,
                        OT_ROUNDED: 0,
                        LATE: 0,
                        ABSENT: 0,
                        AM1: 0,
                        LUNCH1: 0,
                        NOON1: 0,
                        EXP: 0,
                        AM2: 0,
                        LUNCH2: 0,
                        NOON2: 0,
                        EXCESS: 0
                    }
                }
            }, {});
        } else {
            resetButtonOnClick();
            // Attempt to fetch imported hours from Excel (in Import Hours Form)
            // when there are no actual saved time.
            const mapping = await fetchSMMapping();
            if(mapping) {
                const importedHours = await fetchSMITime(mapping.NIDEKA_EMPID);
                importedHours.forEach(hoursOnDate => {
                    props.datesInTimePeriod.forEach(date => {
                        if(new Date(hoursOnDate.DATE).getTime() == date.getTime()) {
                            const employeeDateHours = employeeTimes.value[date];
                            employeeDateHours.IN1 = hoursOnDate.IN1 ?? "";
                            employeeDateHours.OUT1 = hoursOnDate.OUT1 ?? "";
                            employeeDateHours.IN2 = hoursOnDate.IN2 ?? "";
                            employeeDateHours.OUT2 = hoursOnDate.OUT2 ?? "";
                            employeeDateHours.IN3 = hoursOnDate.IN3 ?? "";
                            employeeDateHours.OUT3 = hoursOnDate.OUT3 ?? "";
                        }
                    });
                });
            }
        }
    } catch(e) {
        if(e.response) {
            console.log(e);
            errorMessages.value.push({
                type: "ERROR",
                content: `An error occurred while fetching employee time information.
                    Please contact your administrator.<br/>
                    Details: ${e}`
            });
        }
    }

    onTimeBlur(null, null, "REGULAR_SHIFT_START");
    onTimeBlur(null, null, "REGULAR_SHIFT_END");
    props.datesInTimePeriod.forEach(date => {
        const employeeDateHours = employeeTimes.value[date];
        onTimeBlur(null, employeeDateHours, "IN1");
        onTimeBlur(null, employeeDateHours, "OUT1");
        onTimeBlur(null, employeeDateHours, "IN2");
        onTimeBlur(null, employeeDateHours, "OUT2");
        onTimeBlur(null, employeeDateHours, "IN3");
        onTimeBlur(null, employeeDateHours, "OUT3");
        onTimeBlur(null, employeeDateHours, "START_IRREG_SHIFT");
        onTimeBlur(null, employeeDateHours, "END_IRREG_SHIFT");
    });

    timeInputsDisabledFlags.value = Object.keys(employeeTimes.value).reduce((inputFlags, date) => {
        const employeeDateHours = employeeTimes.value[date];
        return { ...inputFlags, [date]: ["A", "D", "LW", "LO", "OD"].includes(employeeDateHours.EMPLOYEE_STATUS) };
    }, {});

    loadingFlags.delete('fetchEmployeeShiftAndSavedTimes');
});

async function fetchSMMapping() {
    loadingFlags.add('fetchSMMapping');

    let mapping = null;

    try {
        const response = await axios.get(route('api.employee-sm-mappings.index', {
            KIMDB_EMPID: props.employee.EMPID
        }));
        const mappings = response.data.data;
        if(mappings.length > 0)
            mapping = mappings[0];
    } catch(e) {
        console.log(e);
        addFlashMessage('ERROR', `An error occurred while attempting to fetch
            SM Mapping. Please contact your administrator.`);
    }

    loadingFlags.delete('fetchSMMapping');

    return mapping;
}

async function fetchSMITime(NIDEKA_EMPID) {
    loadingFlags.add('fetchSMITime');

    let data = [];

    try {
        const response = await axios.get(route('api.sm-itime.index',
            { EMPLOYEE_NUMBER: NIDEKA_EMPID, WITHIN_TIMEPERIOD: props.timePeriod.TIME_PERIOD }));
        data = response.data.data;
    } catch(e) {
        console.log(e);
        addFlashMessage('ERROR', `An error occurred while attempting to fetch SM ITime.
            Please contact your administrator.`);
    }

    loadingFlags.delete('fetchSMITime');

    return data;
}

function resetButtonOnClick() {
    regularShiftId.value = null;
    regularShiftStart.value = "";
    regularShiftEnd.value = "";
    lunchBreakMinutes.value = "60";
    employeeTimes.value = props.datesInTimePeriod.reduce((newEmployeeTimes, dateInTimePeriod) => ({
        ...newEmployeeTimes,
        [dateInTimePeriod]: {
            id: null,
            DAY_TYPE: "R",
            EMPLOYEE_STATUS: "P",
            IN1: "",
            OUT1: "",
            IN2: "",
            OUT2: "",
            IN3: "",
            OUT3: "",
            TREG: 0,
            TOVR: 0,
            TOTAL: 0,
            OT: 0,
            OT_ROUNDED: 0,
            CH: true,
            LATE: 0,
            ABSENT: 0,
            START_IRREG_SHIFT: "",
            END_IRREG_SHIFT: "",
            LUNCH_IRREG_SHIFT: "",
            AM1: 0,
            LUNCH1: 0,
            NOON1: 0,
            EXP: 0,
            AM2: 0,
            LUNCH2: 0,
            NOON2: 0,
            EXCESS: 0,
            CH_EXCESS: false
        }
    }), {});
    
    props.datesInTimePeriod.forEach(dateInTimePeriod => {
        employeeStatusSelectOnChange(dateInTimePeriod);
        coffeeBreakInputsDisabledFlags.value[dateInTimePeriod] = false;
    });
}

function estimateButtonOnClick() {
    issuesMessages.value = [];
    alertsToResolve.value = [];

    Object.keys(totals.value).forEach(TOTAL_TYPE => totals.value[TOTAL_TYPE] = 0);

    let startTime = null;
    let endTime = null;
    let lunchBreakShiftMinutes = null;
    if(regularShiftStart.value && regularShiftEnd.value && lunchBreakMinutes.value) {
        startTime = moment(regularShiftStart.value, "hh:mm A");
        endTime = moment(regularShiftEnd.value, "hh:mm A");
        lunchBreakShiftMinutes = lunchBreakMinutes.value;
    } else {
        issuesMessages.value.push({
            type: "ERROR",
            content: "Please specify regular shift values."
        });
        return;
    }

    props.datesInTimePeriod.forEach((date) => {
        const employeeDateHours = employeeTimes.value[date];

        employeeDateHours.TREG = 0;
        employeeDateHours.TOVR = 0;
        employeeDateHours.TOTAL = 0;
        employeeDateHours.OT = 0;
        employeeDateHours.OT_ROUNDED = 0;
        employeeDateHours.LATE = 0;
        employeeDateHours.ABSENT = 0;
        employeeDateHours.AM1 = 0;
        employeeDateHours.LUNCH1 = 0;
        employeeDateHours.NOON1 = 0;
        employeeDateHours.AM2 = 0;
        employeeDateHours.LUNCH2 = 0;
        employeeDateHours.NOON2 = 0;
        employeeDateHours.EXCESS = 0;

        let dateStartTime = startTime;
        let dateEndTime = endTime;
        let dateLunchBreakMinutes = lunchBreakShiftMinutes;

        if(employeeDateHours.START_IRREG_SHIFT && employeeDateHours.END_IRREG_SHIFT) {
            dateStartTime = moment(employeeDateHours.START_IRREG_SHIFT, "hh:mm A");
            dateEndTime = moment(employeeDateHours.END_IRREG_SHIFT, "hh:mm A");
        }

        if(employeeDateHours.LUNCH_IRREG_SHIFT)
            dateLunchBreakMinutes = employeeDateHours.LUNCH_IRREG_SHIFT;

        const dayType = employeeDateHours.DAY_TYPE;
        const employeeStatus = employeeDateHours.EMPLOYEE_STATUS;

        calculateShift(employeeDateHours, dateStartTime, dateEndTime, dateLunchBreakMinutes);

        // Validation for "P" and "OD"
        function validatePresentOrOfficeDayInputs() {
            if(employeeDateHours.EMPLOYEE_STATUS == "P" &&
                (employeeDateHours.IN1 == "" || employeeDateHours.OUT3 == "")) {
                employeeDateHours.EMPLOYEE_STATUS = "D";
                employeeStatusSelectOnChange(date);
                employeeDateHours.TOTAL = 0;
                employeeDateHours.OT = 0;
                employeeDateHours.LATE = 0;
                employeeDateHours.ABSENT = 0;
                return false;
            }

            return true;
        }

        if(dayType === "R") {
            if(employeeStatus === "P") {
                if(!validatePresentOrOfficeDayInputs()) return;
                calculateTime(employeeDateHours, dateStartTime);
                totals.value.REGULAR += employeeDateHours.TREG;
                if(employeeDateHours.CH)
                    totals.value.OVERTIME += employeeDateHours.TOVR;
                employeeDateHours.ABSENT = 0;
                totals.value.LATE_TOTAL += employeeDateHours.LATE;
            }

            else if(employeeStatus === "A") {
                employeeDateHours.TOTAL = 0;
                employeeDateHours.OT = 0;
                employeeDateHours.LATE = 0;
                employeeDateHours.ABSENT = REGULAR;
                totals.value.ABSENT += employeeDateHours.ABSENT;
                totals.value.LATE_TOTAL += employeeDateHours.LATE + employeeDateHours.ABSENT;
            }

            else if(employeeStatus === "D" || employeeStatus === "LO") {
                employeeDateHours.TOTAL = 0;
                employeeDateHours.OT = 0;
                employeeDateHours.LATE = 0;
                employeeDateHours.ABSENT = 0;
                employeeDateHours.AM1 = 0;
                employeeDateHours.LUNCH1 = 0;
                employeeDateHours.NOON1 = 0;
                employeeDateHours.AM2 = 0;
                employeeDateHours.LUNCH2 = 0;
                employeeDateHours.NOON2 = 0;
                employeeDateHours.EXCESS = 0;
            }

            else if(employeeStatus === "LW") {
                totals.value.LEAVE += REGULAR;
                employeeDateHours.TOTAL = REGULAR / 60;
                employeeDateHours.OT = 0;
                employeeDateHours.LATE = 0;
                employeeDateHours.ABSENT = 0;
            }

            else if(employeeStatus === "OD") {
                if(!employeeDateHours.IN1 || !employeeDateHours.OUT3) {
                    employeeDateHours.IN1 = null;
                    employeeDateHours.IN2 = null;
                    employeeDateHours.IN3 = null;
                    employeeDateHours.OUT1 = null;
                    employeeDateHours.OUT2 = null;
                    employeeDateHours.OUT3 = null;

                    employeeDateHours.TOTAL = REGULAR / 60;
                    totals.value.REGULAR += REGULAR;
                    employeeDateHours.OT = 0;
                    employeeDateHours.LATE = 0;
                    employeeDateHours.ABSENT = 0;
                }

                else if(!validatePresentOrOfficeDayInputs()) {
                    calculateTime(employeeDateHours, dateStartTime);
                    if(employeeDateHours.TREG > 0) {
                        if(employeeDateHours.TOVR >= 60) {
                            employeeDateHours.OT = (employeeDateHours.TOVR - 60) / 60;
                            if(employeeDateHours.CH);
                                totals.value.OVERTIME += employeeDateHours.TOVR - 60;
                        } else {
                            employeeDateHours.OT = 0;
                        }
                    }
                    employeeDateHours.TOTAL = REGULAR / 60;
                    totals.value.REGULAR += REGULAR;
                    employeeDateHours.ABSENT = 0;
                }
            }
        }

        else if(dayType === "H") {
            if(employeeStatus === "OD") {
                issuesMessages.value.push({
                    type: "WARNING",
                    content: `<b>${moment(date).format("MMM D")}</b>: No office day on a holiday.`
                });
                return;
            }

            else if(employeeStatus === "P") {
                if(!validatePresentOrOfficeDayInputs()) return;
                calculateTime(employeeDateHours, dateStartTime, false);
                if(employeeDateHours.TREG != 0) {
                    totals.value.HOLIDAY += employeeDateHours.TREG;
                    totals.value.HOLIDAY_HOLIDAY += REGULAR;
                    totals.value.OVERTIME_HOLIDAY += employeeDateHours.TOVR;
                    employeeDateHours.ABSENT = 0;
                }
            }

            else if(employeeStatus === "LW") {
                issuesMessages.value.push({
                    type: "WARNING",
                    content: `<b>${moment(date).format("MMM D")}</b>: Can't be on PAID LEAVE during a holiday.`
                });
            }

            else {
                issuesMessages.value.push({
                    type: "WARNING",
                    content: `<b>${moment(date).format("MMM D")}</b>: Either ABSENT, DAY-OFF, or ON-LEAVE. Please set to Holiday Holiday.`
                });
            }
        }

        else if(dayType === "S") {
            if(employeeStatus === "LW") {
                issuesMessages.value.push({
                    type: "WARNING",
                    content: `<b>${moment(date).format("MMM D")}</b>: Can't be on PAID LEAVE during a holiday.`
                });
                return;
            }

            else if(["D", "A", "LO"].includes(employeeStatus)) {
                employeeDateHours.ABSENT = 0;
            }

            else if(employeeStatus === "OD") {
                if(!validatePresentOrOfficeDayInputs()) return;
                calculateTime(employeeDateHours, dateStartTime);
                if(employeeDateHours.TREG != 0) {
                    if(employeeDateHours.TOVR >= 60) {
                        employeeDateHours.OT = (employeeDateHours.TOVR - 60) / 60;
                        if(employeeDateHours.CH)
                            totals.value.OVERTIME_SPECIAL += employeeDateHours.TOVR - 60;
                    }

                    else
                        employeeDateHours.OT = 0;
                }

                totals.value.SPECIAL += REGULAR;
                employeeDateHours.TOTAL = REGULAR / 60;
                employeeDateHours.ABSENT = 0;
            }

            else {
                calculateTime(employeeDateHours, dateStartTime);
                totals.value.SPECIAL += employeeDateHours.TREG;
                if(employeeDateHours.CH)
                    totals.value.OVERTIME_SPECIAL += employeeDateHours.TOVR;
                employeeDateHours.ABSENT = 0;
                totals.value.LATE_TOTAL += employeeDateHours.LATE + employeeDateHours.ABSENT;
            }
        }

        if(employeeDateHours.EXCESS <= employeeDateHours.OT)
            employeeDateHours.EXCESS = 0;

        // Late checker if too much
        if(employeeDateHours.LATE >= 15) {
            alertsToResolve.value.push({
                type: "WARNING",
                content: `On ${moment(date).format("MMM D")}, too much late.
                    Possible typographical error. Proceed anyway?`,
                onNo: () => {
                    issuesMessages.value.push({
                        type: "WARNING",
                        content: `On ${moment(date).format("MMM D")},
                            fix times since there are too much late.`,
                    });
                }
            });
        }
    });

    // Now calculate HOLIDAY_HOLIDAY
    props.datesInTimePeriod.forEach((date, index) => {
        const employeeDateHours = employeeTimes.value[date];

        const dayType = employeeDateHours.DAY_TYPE;
        const employeeStatus = employeeDateHours.EMPLOYEE_STATUS;

        /**
         * HOLIDAY_HOLIDAY.
         * TLDR, Different cases to give credit by checking the surrounding days if present or not.
         * This is directly translated from the MS Access form.
         * TODO: Maybe refactor this to be better/shorter. Did not do it for now.
         */
        if(dayType === "HH") {
            if(employeeStatus === "OD") {
                issuesMessages.value.push({
                    type: "WARNING",
                    content: `<b>${moment(date).format("MMM D")}</b>: No office day on a holiday.`
                });
                return;
            }

            if(["R", "Regular", "E", "Extra"].includes(employeeStat.value)) {
                // TODO: Refactor checking to be shorter
                // Checks if present the day before and after
                if(index > 0 && index < props.datesInTimePeriod.length - 1) {
                    const employeePreviousDayHours = employeeTimes.value[props.datesInTimePeriod[index - 1]];
                    const employeeNextDayHours = employeeTimes.value[props.datesInTimePeriod[index + 1]];

                    // Present before, Present after
                    if(["P", "LW"].includes(employeePreviousDayHours.EMPLOYEE_STATUS) &&
                        ["P", "LW"].includes(employeeNextDayHours.EMPLOYEE_STATUS) &&
                        employeePreviousDayHours.TOTAL >= 6 &&
                        employeeNextDayHours.TOTAL >= 6) {

                        employeeDateHours.TOTAL = REGULAR / 60;
                        totals.value.HOLIDAY_HOLIDAY += REGULAR;
                    }

                    // Dayoff before, Present after
                    else if(employeePreviousDayHours.EMPLOYEE_STATUS === "D" &&
                        ["P", "LW"].includes(employeeNextDayHours.EMPLOYEE_STATUS) &&
                        employeeNextDayHours.TOTAL >= 6) {
                        if(index == 1) {
                            alertsToResolve.value.push({
                                type: "WARNING",
                                content:
                                    `For HOLIDAY_HOLIDAY on ${moment(date).format("MMM D")},
                                    check the day before: ${moment(props.datesInTimePeriod[index - 1])}.
                                    Is the employee present?`,
                                onYes: function() {
                                    employeeDateHours.TOTAL = REGULAR / 60;
                                    totals.value.HOLIDAY_HOLIDAY += REGULAR;
                                },
                                onNo: function() {
                                    employeeDateHours.TOTAL = 0;
                                    totals.value.HOLIDAY_HOLIDAY += 0;
                                }
                            });
                        }

                        else {
                            const employeeLastTwoDaysHours = employeeTimes.value[props.datesInTimePeriod[index - 2]];
                            if(["P", "LW"].includes(employeeLastTwoDaysHours.EMPLOYEE_STATUS)) {
                                employeeDateHours.TOTAL = REGULAR / 60;
                                totals.value.HOLIDAY_HOLIDAY += REGULAR;
                            }
                        }
                    }

                    // Present before, Dayoff after
                    else if(["P", "LW"].includes(employeePreviousDayHours.EMPLOYEE_STATUS) &&
                        employeeNextDayHours.EMPLOYEE_STATUS === "D" &&
                        employeePreviousDayHours.TOTAL >= 6) {
                        if(index === props.datesInTimePeriod.length - 3) {
                            alertsToResolve.value.push({
                                type: "WARNING",
                                content:
                                    `For HOLIDAY_HOLIDAY on ${moment(date).format("MMM D")},
                                    check the day before: ${moment(props.datesInTimePeriod[index + 1])}.
                                    Is the employee present?`,
                                onYes: function() {
                                    employeeDateHours.TOTAL = REGULAR / 60;
                                    totals.value.HOLIDAY_HOLIDAY += REGULAR;
                                },
                                onNo: function() {
                                    employeeDateHours.TOTAL = 0;
                                    totals.value.HOLIDAY_HOLIDAY += 0;
                                }
                            });
                        }

                        else {
                            const employeeNextTwoDaysHours = employeeTimes.value[props.datesInTimePeriod[index + 2]];
                            if(["P", "LW"].includes(employeeNextTwoDaysHours.EMPLOYEE_STATUS)) {
                                employeeDateHours.TOTAL = REGULAR / 60;
                                totals.value.HOLIDAY_HOLIDAY += REGULAR;
                            }
                        }
                    }

                    // Dayoff before, Dayoff after
                    else if(employeePreviousDayHours.EMPLOYEE_STATUS === "D" &&
                        employeeNextDayHours.EMPLOYEE_STATUS === "D") {

                        if(index == 1) {
                            alertsToResolve.value.push({
                                type: "WARNING",
                                content:
                                    `For HOLIDAY_HOLIDAY on ${moment(date).format("MMM D")},
                                    check the day before: ${moment(props.datesInTimePeriod[index - 1])}.
                                    Is the employee present?`,
                                onYes: function() {
                                    employeeDateHours.TOTAL = REGULAR / 60;
                                    totals.value.HOLIDAY_HOLIDAY += REGULAR;
                                },
                                onNo: function() {
                                    employeeDateHours.TOTAL = 0;
                                    totals.value.HOLIDAY_HOLIDAY += 0;
                                }
                            });
                        }

                        else if(index === props.datesInTimePeriod.length - 3) {
                            alertsToResolve.value.push({
                                type: "WARNING",
                                content:
                                    `For HOLIDAY_HOLIDAY on ${moment(date).format("MMM D")},
                                    check the day before: ${moment(props.datesInTimePeriod[index + 1])}.
                                    Is the employee present?`,
                                onYes: function() {
                                    employeeDateHours.TOTAL = REGULAR / 60;
                                    totals.value.HOLIDAY_HOLIDAY += REGULAR;
                                },
                                onNo: function() {
                                    employeeDateHours.TOTAL = 0;
                                    totals.value.HOLIDAY_HOLIDAY += 0;
                                }
                            });
                        }

                        else {
                            const employeeLastTwoDaysHours = employeeTimes.value[props.datesInTimePeriod[index - 2]];
                            const employeeNextTwoDaysHours = employeeTimes.value[props.datesInTimePeriod[index + 2]];
                            if(["P", "LW"].includes(employeeLastTwoDaysHours.EMPLOYEE_STATUS) &&
                                ["P", "LW"].includes(employeeNextTwoDaysHours.EMPLOYEE_STATUS)) {
                                employeeDateHours.TOTAL = REGULAR / 60;
                                totals.value.HOLIDAY_HOLIDAY += REGULAR;
                            }
                        }
                    }
                }

                else if(index === 0) {
                    const employeeNextDayHours = employeeTimes.value[props.datesInTimePeriod[index + 1]];
                    if(["P", "LW"].includes(employeeNextDayHours.EMPLOYEE_STATUS) &&
                        employeeNextDayHours.TOTAL >= 6) {
                        alertsToResolve.value.push({
                            type: "WARNING",
                            content:
                                `For HOLIDAY_HOLIDAY on ${moment(date).format("MMM D")},
                                check the day before: ${moment(date).format("MMM D")}.
                                Is the employee present?`,
                            onYes: function() {
                                employeeDateHours.TOTAL = REGULAR / 60;
                                totals.value.HOLIDAY_HOLIDAY += REGULAR;
                            },
                            onNo: function() {
                                employeeDateHours.TOTAL = 0;
                                totals.value.HOLIDAY_HOLIDAY += 0;
                            }
                        });
                    }

                    else if(employeeNextDayHours.EMPLOYEE_STATUS === "D") {
                        const employeeNextTwoDaysHours = employeeTimes.value[props.datesInTimePeriod[index + 2]];
                        if(["P", "LW"].includes(employeeNextTwoDaysHours.EMPLOYEE_STATUS) &&
                            employeeNextTwoDaysHours.TOTAL >= 6) {
                            alertsToResolve.value.push({
                                type: "WARNING",
                                content:
                                    `For HOLIDAY_HOLIDAY on ${moment(date).format("MMM D")},
                                    check the day before: ${moment(date).format("MMM D")}.
                                    Is the employee present?`,
                                onYes: function() {
                                    employeeDateHours.TOTAL = REGULAR / 60;
                                    totals.value.HOLIDAY_HOLIDAY += REGULAR;
                                },
                                onNo: function() {
                                    employeeDateHours.TOTAL = 0;
                                    totals.value.HOLIDAY_HOLIDAY += 0;
                                }
                            });
                        }
                    }
                }

                else if(index === props.datesInTimePeriod.length - 1) {
                    const employeePreviousDayHours = employeeTimes.value[props.datesInTimePeriod[index - 1]];
                    if(["P", "LW"].includes(employeePreviousDayHours.EMPLOYEE_STATUS) &&
                        employeePreviousDayHours.TOTAL >= 6) {
                        alertsToResolve.value.push({
                            type: "WARNING",
                            content:
                                `For HOLIDAY_HOLIDAY on ${moment(date).format("MMM D")},
                                check the day after: ${moment(date).format("MMM D")}.
                                Is the employee present?`,
                            onYes: function() {
                                employeeDateHours.TOTAL = REGULAR / 60;
                                totals.value.HOLIDAY_HOLIDAY += REGULAR;
                            },
                            onNo: function() {
                                employeeDateHours.TOTAL = 0;
                                totals.value.HOLIDAY_HOLIDAY += 0;
                            }
                        });
                    }

                    else if(employeePreviousDayHours.EMPLOYEE_STATUS === "D") {
                        const employeeLastTwoDaysHours = employeeTimes.value[props.datesInTimePeriod[index - 2]];
                        if(["P", "LW"].includes(employeeLastTwoDaysHours.EMPLOYEE_STATUS) &&
                            employeeLastTwoDaysHours.TOTAL >= 6) {
                            alertsToResolve.value.push({
                                type: "WARNING",
                                content:
                                    `For HOLIDAY_HOLIDAY on ${moment(date).format("MMM D")},
                                    check the day after: ${moment(date).format("MMM D")}.
                                    Is the employee present?`,
                                onYes: function() {
                                    employeeDateHours.TOTAL = REGULAR / 60;
                                    totals.value.HOLIDAY_HOLIDAY += REGULAR;
                                },
                                onNo: function() {
                                    employeeDateHours.TOTAL = 0;
                                    totals.value.HOLIDAY_HOLIDAY += 0;
                                }
                            });
                        }
                    }
                }

                if(employeeStatus === "P") {
                    issuesMessages.value.push({
                        type: "WARNING",
                        content: `<b>${moment(date).format("MMM D")}</b>: Ooops! Either present or on leave. Please set to 'H (Holiday)' and try again.`
                    });
                }

                else if(employeeStatus === "LW") {
                    issuesMessages.value.push({
                        type: "WARNING",
                        content: `<b>${moment(date).format("MMM D")}</b>: Can't be on paid leave during a holiday. Please fix.`
                    });
                }
            }

            employeeDateHours.OT = 0;
            employeeDateHours.LATE = 0;
            employeeDateHours.ABSENT = 0;
        }
    }); 

    totals.value.REGULAR = totals.value.REGULAR / 60;
    totals.value.LEAVE = totals.value.LEAVE / 60;
    totals.value.OVERTIME = totals.value.OVERTIME / 60;
    totals.value.HOLIDAY = totals.value.HOLIDAY / 60;
    totals.value.HOLIDAY_HOLIDAY = totals.value.HOLIDAY_HOLIDAY / 60;
    totals.value.OVERTIME_HOLIDAY = totals.value.OVERTIME_HOLIDAY / 60;
    totals.value.SPECIAL = totals.value.SPECIAL / 60;
    totals.value.OVERTIME_SPECIAL = totals.value.OVERTIME_SPECIAL / 60;
    totals.value.ABSENT = totals.value.ABSENT / 480;
    totals.value.LATE_TOTAL = totals.value.LATE_TOTAL / 60;

    // If total late is less than 5 minutes, let it go.
    if(totals.value.LATE > 0 && totals.value.LATE <= 5) {
        window.setTimeout(() => {
            // NOTE: This is wrapped in a timeout because they wanted to see the totals before ignoring 5 mins late.
            window.alert("Total late is < 5 mins. Let it go.");
            props.datesInTimePeriod.forEach(date => {
                const employeeDateHours = employeeTimes.value[date];

                if(employeeDateHours.LATE > 0) {
                    const late = employeeDateHours.LATE;

                    employeeDateHours.TREG += late;
                    employeeDateHours.TOTAL = employeeDateHours.TREG / 60;
                    employeeDateHours.LATE = 0;

                    if(employeeDateHours.DAY_TYPE === 'R') {
                        totals.value.REGULAR += late / 60;
                    }
                    else if(employeeDateHours.DAY_TYPE === 'S') {
                        totals.value.SPECIAL += late / 60;
                    }
                    else if(employeeDateHours.DAY_TYPE === 'H') {
                        totals.value.HOLIDAY += late / 60;
                    }
                }
            });

            totals.value.LATE = 0;
            totals.value.LATE_TOTAL = totals.value.ABSENT / 60;
        }, 0);
    }

    hasEstimated.value = true;
}

function calculateShift(employeeDateHours, startTime, endTime, lunchBreakShiftMinutes) {
    employeeDateHours.EXP = moment.duration(endTime.diff(startTime)).asMinutes() - lunchBreakShiftMinutes;
}

function calculateTime(employeeDateHours, startTime, ignoreLate=false) {
    let in1 = moment(employeeDateHours.IN1, "hh:mm A");
    let out1 = moment(employeeDateHours.OUT1, "hh:mm A");
    let in2 = moment(employeeDateHours.IN2, "hh:mm A");
    let out2 = moment(employeeDateHours.OUT2, "hh:mm A");
    let in3 = moment(employeeDateHours.IN3, "hh:mm A");
    let out3 = moment(employeeDateHours.OUT3, "hh:mm A");
    // Case when out by next day (e.g. 12AM, 1AM, etc.)
    if(out3.isBefore(in3)) {
        out3 = out3.add(1, 'days');
    }

    // Check late start
    employeeDateHours.AM2 = 0;
    let startAndInDifference = moment.duration(in1.diff(startTime)).asMinutes();
    if(startAndInDifference > 0)
        employeeDateHours.AM2 = startAndInDifference;

    // Check needed excess (required unpaid OT)
    employeeDateHours.EXCESS = (Math.floor((moment.duration(out3.diff(in1)).asMinutes() - (REGULAR + 60)) / 30) * 30) / 60;

    /**
     * Time Calculations!
     * - AM1 = Total Time-In minutes for in until lunch break
     * - LUNCH1 = Total Time-In minutes after lunch break until coffee break
     * - NOON1 = Total Time-In minutes after coffee break until out
     */
    if(startTime.isValid() && out1.isValid())
        employeeDateHours.AM1 = moment.duration(out1.diff(startTime)).asMinutes();
    else
        employeeDateHours.AM1 = 0;

    if(in2.isValid() && out2.isValid())
        employeeDateHours.LUNCH1 = moment.duration(out2.diff(in2)).asMinutes();
    else
        employeeDateHours.LUNCH1 = 0;

    if(in3.isValid() && out3.isValid())
        employeeDateHours.NOON1 = moment.duration(out3.diff(in3)).asMinutes();
    else
        employeeDateHours.NOON1 = 0;


    // TODO: For some reason, lunch break is hardcoded to 60 instead of using the input value.

    /**
     * This chain handles the cases when there are gaps on the DTR, due to ff. reasons:
     * - No Lunch Break (out1 and in2 is not inputted),
     * - No Coffee Break (out2 and in3 is not inputted)
     * Calculate the total time gracefully even if this is not inputted.
     */
    // No Lunch break
    if(!out1.isValid() && !in2.isValid()) {
        // If there is coffee break, then calculate AM1 and LUNCH1 to LUNCH1
        if(out2.isValid())
            employeeDateHours.LUNCH1 = moment.duration(out2.diff(in1)).asMinutes();
        // There is also no coffee break, then calculate AM1, LUNCH1, NOON1 altogether to NOON1
        else
            employeeDateHours.NOON1 = moment.duration(out3.diff(in1)).asMinutes();
    }

    // No Coffee break
    let paidCoffeeBreak = 0;
    if(!out2.isValid() && !in3.isValid()) {
        // If there is lunch break, then calculate LUNCH1 and NOON1 together to NOON1
        if(out1.isValid())
            employeeDateHours.NOON1 = moment.duration(out3.diff(in2)).asMinutes();
        // If there is no lunch break as well, then it's handled by the previous case.

        // In all cases when there is no coffee break, give paid coffee break of 30 minutes.
        paidCoffeeBreak = 30;
    }

    // Calculate total regular for this day
    employeeDateHours.TREG = employeeDateHours.AM1 + employeeDateHours.LUNCH1 +
        employeeDateHours.NOON1;

    if(employeeDateHours.TREG != 0) {
        // Include paid coffee break if they are not inputted.
        employeeDateHours.TREG += paidCoffeeBreak;

        // If there is inputted coffee break, include in total regular hours
        if(out2.isValid() && in3.isValid())
            employeeDateHours.TREG += moment.duration(in3.diff(out2)).asMinutes();

        // Check overtime lunch
        if(out1.isValid() && in2.isValid()) {
            const lunchTimeMinutes = moment.duration(in2.diff(out1)).asMinutes();
            employeeDateHours.TREG += lunchTimeMinutes - 60;
            if(lunchTimeMinutes > 60)
                employeeDateHours.LUNCH2 = lunchTimeMinutes - 60;
            else if(lunchTimeMinutes <= 30)
                employeeDateHours.TREG += 30;
        }

        // Check overtime coffee break
        if(out2.isValid() && in3.isValid()) {
            const coffeeBreakMinutes = moment.duration(in3.diff(out2)).asMinutes();
            if(coffeeBreakMinutes > 30)
                employeeDateHours.NOON2 = coffeeBreakMinutes - 30;
        }
    }

    // No paid coffee break if undertime
    if(employeeDateHours.TREG < UNDERTIME && employeeDateHours.TREG != 0)
        employeeDateHours.TREG -= 30;

    // Check overtime
    if(employeeDateHours.TREG > REGULAR) {
        employeeDateHours.TOVR = employeeDateHours.TREG - REGULAR;
        employeeDateHours.TREG = REGULAR - employeeDateHours.AM2 - employeeDateHours.LUNCH2 -
            employeeDateHours.NOON2;
    } else {
        employeeDateHours.TOVR = 0;
        employeeDateHours.TREG -= (employeeDateHours.AM2 + employeeDateHours.LUNCH2 +
            employeeDateHours.NOON2);
    }

    // Display late
    if(employeeDateHours.TREG != 0) {
        employeeDateHours.LATE = REGULAR - employeeDateHours.TREG;
    } else {
        employeeDateHours.LATE = 0;
    }

    if(!ignoreLate) {
        totals.value.LATE += employeeDateHours.LATE;
        // 30 mins offset only
        if(employeeDateHours.LATE > 0 && employeeDateHours.LATE <= 30 && employeeDateHours.TOVR >= 30) {
            employeeDateHours.TREG = REGULAR;
            employeeDateHours.TOVR -= 0.5;
            employeeDateHours.LATE = 0;
        }
    } else {
        employeeDateHours.TREG += employeeDateHours.LATE;
    }

    employeeDateHours.TOVR = parseInt(employeeDateHours.TOVR / 30) * 30;
    employeeDateHours.OT = parseInt(employeeDateHours.TOVR / 30) * 30 / 60;
    employeeDateHours.TOTAL = employeeDateHours.TREG / 60;
}

function employeeStatusSelectOnChange(date) {
    const employeeDateHours = employeeTimes.value[date];
    if(["A", "D", "LW", "LO", "OD"].includes(employeeDateHours.EMPLOYEE_STATUS)) {
        employeeDateHours.IN1 = "";
        employeeDateHours.OUT1 = "";
        employeeDateHours.IN2 = "";
        employeeDateHours.OUT2 = "";
        employeeDateHours.IN3 = "";
        employeeDateHours.OUT3 = "";
        timeInputsDisabledFlags.value[date] = true;
    }

    else {
        timeInputsDisabledFlags.value[date] = false;
    }
}

function onTimeFocus(event, employeeDateHours, field) {
    let inputTime;

    if(employeeDateHours == null) {
        switch(field) {
            case 'REGULAR_SHIFT_START':
                inputTime = moment(regularShiftStart.value, 'hh:mm A')
                if(inputTime.isValid())
                    regularShiftStart.value = inputTime.format('HH:mm');
                break;
            case 'REGULAR_SHIFT_END':
                inputTime = moment(regularShiftEnd.value, 'hh:mm A')
                if(inputTime.isValid())
                    regularShiftEnd.value = inputTime.format('HH:mm');
                break;
        }
    }

    else {
        inputTime = moment(employeeDateHours[field], 'hh:mm A');
        if(inputTime.isValid())
            employeeDateHours[field] = inputTime.format('HH:mm');
    }
}

function onTimeBlur(event, employeeDateHours, field) {
    let inputTime;
    if(employeeDateHours == null) {
        switch(field) {
            case 'REGULAR_SHIFT_START':
                inputTime = moment(regularShiftStart.value, 'HH:mm')
                if(inputTime.isValid())
                    regularShiftStart.value = inputTime.format('hh:mm A');
                else if(regularShiftStart.value != "")
                    if(event)
                        event.target.style.backgroundColor = '#fba';
                break;
            case 'REGULAR_SHIFT_END':
                inputTime = moment(regularShiftEnd.value, 'HH:mm')
                if(inputTime.isValid())
                    regularShiftEnd.value = inputTime.format('hh:mm A');
                else if(regularShiftEnd.value != "")
                    if(event)
                        event.target.style.backgroundColor = '#fba';
                break;
        }
    }

    else {
        inputTime = moment(employeeDateHours[field], 'HH:mm');
        if(inputTime.isValid()) {
            employeeDateHours[field] = inputTime.format('hh:mm A');
            if(event)
                event.target.style.backgroundColor = null;
        } else if(employeeDateHours[field] != "") {
            if(event)
                event.target.style.backgroundColor = '#fba';
        }
    }
}

function noCoffeeBreakButtonOnClick() {
    props.datesInTimePeriod.forEach(date => {
        const employeeDateHours = employeeTimes.value[date];
        coffeeBreakInputsDisabledFlags.value[date] = true;
        if(employeeDateHours.IN2) {
            const in2 = moment(employeeDateHours.IN2, "hh:mm A");
            employeeDateHours.OUT2 = in2.add(1, 'minutes').format("hh:mm A");
            employeeDateHours.IN3 = in2.add(1, 'minutes').format("hh:mm A");
        }
    });
}

async function populate() {
    loadingFlags.add('populate');

    try {
        const regularShift = {
            START_SHIFT: moment(regularShiftStart.value, 'hh:mm A').format('HH:mm'),
            END_SHIFT: moment(regularShiftEnd.value, 'hh:mm A').format('HH:mm'),
            LUNCH_BREAK: lunchBreakMinutes.value,
            STATUS: employeeStat.value
        };
        if(regularShiftId.value !== null)
            regularShift.id = regularShiftId.value;

        const hoursDetails = props.datesInTimePeriod.map((date, index) => {
            const employeeDateHours = employeeTimes.value[date];
            if(employeeDateHours.IN_1 === "") return;
            const dayHoursDetails = {
                TYPE_HOLIDAY: employeeDateHours.DAY_TYPE,
                TYPE_ABSENT: employeeDateHours.EMPLOYEE_STATUS,
                SEQ_NO: index + 1,
                IN_1: employeeDateHours.IN1 ?
                    moment(employeeDateHours.IN1, 'hh:mm A').format('HH:mm') : null,
                OUT_1: employeeDateHours.OUT1 ?
                    moment(employeeDateHours.OUT1, 'hh:mm A').format('HH:mm') : null,
                IN_2: employeeDateHours.IN2 ?
                    moment(employeeDateHours.IN2, 'hh:mm A').format('HH:mm') : null,
                OUT_2: employeeDateHours.OUT2 ?
                    moment(employeeDateHours.OUT2, 'hh:mm A').format('HH:mm') : null,
                IN_3: employeeDateHours.IN3 ?
                    moment(employeeDateHours.IN3, 'hh:mm A').format('HH:mm') : null,
                OUT_3: employeeDateHours.OUT3 ?
                    moment(employeeDateHours.OUT3, 'hh:mm A').format('HH:mm') : null,
                WITH_OT: employeeDateHours.CH,
                START_IRREG_SHIFT:employeeDateHours.START_IRREG_SHIFT ?
                    moment(employeeDateHours.START_IRREG_SHIFT, 'hh:mm A').format('HH:mm') : null,
                END_IRREG_SHIFT:employeeDateHours.END_IRREG_SHIFT ?
                    moment(employeeDateHours.END_IRREG_SHIFT, 'hh:mm A').format('HH:mm') : null,
                IRREG_LUNCH: employeeDateHours.LUNCH_IRREG_SHIFT
            };
            if(employeeDateHours.id !== null) {
                dayHoursDetails.id = employeeDateHours.id;
            }
            return dayHoursDetails;
        });

        const EMPID = props.employee.EMPID;
        const TIME_PERIOD = props.timePeriod.TIME_PERIOD;

        const updateRegularShiftResponse = await axios.put(
            route('api.employees.hours.time-period.regular-shift.update',
                [EMPID, TIME_PERIOD]),
            regularShift);

        regularShiftId.value = updateRegularShiftResponse.data.data.id;

        const hoursDetailsResponse = await axios.put(
            route('api.employees.hours.time-period.hours-details.update',
                [EMPID, TIME_PERIOD]), {
            hoursDetails
        });

        props.datesInTimePeriod.forEach((date, index) => {
            employeeTimes.value[date].id = hoursDetailsResponse.data.data[index].id
        });

        await postSalesLeavesMultiple(props.employee.EMPID, props.timePeriod.TIME_PERIOD, props.datesInTimePeriod.map((date) => {
            const employeeDateHours = employeeTimes.value[date];
            return {
                DATE: moment(date).format('MMM D ddd'),
                AM: employeeDateHours.AM2,
                LUNCH: employeeDateHours.LUNCH2,
                NOON: employeeDateHours.NOON2,
            };
        }));

        emit('populate', employeeTimes.value);
        closeButton.value.click();
    } catch(e) {
        console.log(e);
        errorMessages.value.push({
            type: "ERROR",
            content: `An error occurred while populating times.
                Details: ${e.response?.data.message ?? e.message}`
        });
    }

    loadingFlags.delete('populate');
}

watch(() => props.resetCoffeeBreakFlag, () => {
    coffeeBreakInputsDisabledFlags.value = {};
});
</script>
