<template>
    <Card>
        <template #header>
            Select Employee
        </template>
        <FormSelect v-model="department" label="Department" id-prefix="department"
            :options="departments"
            :option-to-string="department => department.DEPT"
            class="mb-3" />
        <DataTable
            :loading="loadingFlags.has('fetchEmployees')"
            :value="employees"
            paginator :rows="10"
            filter-display="row"
            v-model:filters="filters"
            selection-mode="single"
            v-model:selection="selectedEmployee"
            :pt="{ table: { class: ' table table-bordered table-hover' }, style: { 'pointer-events': 'none' }}">
            <Column field="EMPID" header="ID" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="FNAME" header="First Name" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="LNAME" header="Last Name" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="TOTAL_HOURS" header="Hours" sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <Column field="DEPT" header="Dept." sortable :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                        class="form-control" placeholder="Search" />
                </template>
            </Column>
            <template #empty>
                <div class="text-center">
                    No data.
                </div>
            </template>
        </DataTable>
    </Card>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useDepartments } from '@/composables/data/departments';
import { useEmployees } from '@/composables/data/employees';

import Card from '@/components/utils/Card';
import FormSelect from '@/components/utils/FormSelect';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const loadingFlags = useLoadingFlagsStore();

const props = defineProps({
    timePeriod: Number,
    refreshFlag: Boolean,
});

const emit = defineEmits(['error']);

const { filters } = useDataTableParams(['EMPID', 'FNAME', 'LNAME', 'TOTAL_HOURS', 'DEPT']);

const { departments, getDepartments } = useDepartments();
const department = ref(null);

onMounted(getDepartments);

const { employees, getEmployeesWithHoursWithoutPayslipOnTimePeriod } = useEmployees();
const selectedEmployee = defineModel();

async function fetchEmployees() {
    employees.value = null;

    if (!props.timePeriod)
        return;

    loadingFlags.add('fetchEmployees');
    try {
        await getEmployeesWithHoursWithoutPayslipOnTimePeriod(props.timePeriod, department.value?.DEPT);
    } catch (e) {
        emit('error', e.message);
    }
    loadingFlags.delete('fetchEmployees');
}

watch(department, fetchEmployees);
watch(() => props.timePeriod, fetchEmployees);
watch(() => props.refreshFlag, fetchEmployees);
</script>
